
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import CommonCases from "../../Cases/CommonCases";
import AuthService from "../../AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";

const ForgotPassword = () => {

    const { handleChangeInput, handleForgotPassword, formData } = CommonCases();
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordCnf, setShowPasswordCnf] = useState(false);
    const [seconds, setSeconds] = useState(0);

    const handleGetOtpForgot = async (resend) => {
        if (!formData?.signId) {
            alertErrorMessage('Please Enter Email or MobileNumber');
        } else {
            LoaderHelper.loaderStatus(true);
            await AuthService.getOtpForgot(formData?.signId, resend).then(async result => {
                if (result?.success) {
                    LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result?.message);
                    timeRemainingStart();
                    setSeconds(60)
                } else {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result?.message);
                }
            });
        }
    }

    const timeRemainingStart = () => {
        const countdown = setInterval(() => {
            setSeconds((prevSeconds) => Math.max(prevSeconds - 1, 0));
        }, 1000);
        return () => clearInterval(countdown);
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper login_wrapper login_bg_2">
                    <div className="container">
                        <div className="login_page ">
                            <img src="assets/images/logo_login.svg" className="logo_img" />
                            <div className="login_row login_scroll">
                                <h2>Forgot Password!</h2>
                                <p> Enter your mail, we will send you a confirmation
                                    code </p>
                                <form action="#">
                                    <div className="row">
                                        <div className="col-12 mb-4">
                                            <div className="field-box">
                                                <label htmlFor="Code" className="form-label">Email or Mobile Number</label>
                                                <div className="vew_pss otp_btn">
                                                    <input className="form-control" name="signId" id="email" type="text" placeholder="Enter Email" onChange={handleChangeInput} />
                                                    <button
                                                        type="button"
                                                        className={`btn_view btn-icon ${seconds === 0 ? '' : 'disable'}`}
                                                        onClick={() => handleGetOtpForgot(seconds === 0)}
                                                        disabled={seconds !== 0}
                                                    >
                                                        {seconds === 0 ? 'GET OTP' : `0:${seconds}`}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-4">
                                            <div className="field-box">
                                                <label htmlFor="Code" className="form-label">Enter OTP</label>
                                                <input className="form-control" id="otp" type="text" placeholder="Enter OTP" name="otp" onChange={handleChangeInput} />
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-4">
                                            <div className="field-box">
                                                <label htmlFor="password" className="form-label">New Password</label>
                                                <div className="vew_pss">

                                                    <input className="form-control" type={showPassword ? "text" : "password"} name="newPassword" placeholder="Password" onChange={handleChangeInput} />
                                                    <button className="btn_view btn-icon" id="cNewPassword" type="button" onClick={() => setShowPassword(!showPassword)}>
                                                        <i className={`ri-${showPassword ? 'eye' : 'eye-close'}-line`}></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-4">
                                            <div className="field-box">
                                                <label htmlFor="password" className="form-label">Confirm Password</label>
                                                <div className="vew_pss">
                                                    <input className="form-control" type={showPasswordCnf ? "text" : "password"} name="cNewPassword" placeholder="Password" onChange={handleChangeInput} />

                                                    <button className="btn_view btn-icon" id="newPassword" type="button" onClick={() => setShowPasswordCnf(!showPasswordCnf)}>
                                                        <i className={`ri-${showPasswordCnf ? 'eye' : 'eye-close'}-line`}></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-4">
                                            <div className="field-box">
                                                <button className="custom-btn btn-border-gradient btn-gradient btn-block w-100 mb-3" type="button" onClick={handleForgotPassword}>
                                                    <span>FORGOT PASSWORD</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="row justify-content-center text-center text-white">
                            <div className="col-lg-12  have_referal">Already have an account?
                                <Link className="color-primary" to="/login">Login</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default ForgotPassword

