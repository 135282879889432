import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";

const PaymentScreen = React.memo(({ props }) => {

  useEffect(() => {
    if (!window.onMetaWidget) return;

    const createWidget = new window.onMetaWidget({
      elementId: "widget",
      apiKey: "ba89d6da-4505-40e9-873f-c69590eeb575",
      userEmail: `${props?.userDetails?.email_or_phone}`,
      wallet_address: `${props?.userDetails?.wallet_address}`,
      isAndroid: "enabled",
    });

    createWidget.init();
    createWidget.on("ACTION_EVENTS", (data) => {
      window.ReactNativeWebView?.postMessage(JSON.stringify(data));
    });
  }, []);

  return (

    <div className="main_view hight_full">
      <div className="left_view hight_full border-gradient">
        <div className="main_wrapper bg-gradient preview_wrapper edit_profile">
          <div className="header_wrapper">
            <div className="row align-items-center">
              <div className="col-3 text-start">
                <Link to="/my_balance" className="hh_icon"><i className="ri-arrow-left-s-line ri-xl  "></i> </Link>
              </div>
              <div className="col-6  text-center">
                <h4 className="">Add Money</h4>
              </div>
              <div className="col-3  text-end">
              </div>
            </div>
          </div>
          <div className="main_panel px-0 home_scroll">
            <div className="container">
            <div id="widget"></div>
            </div>
          </div>
        </div>

      </div>
      <RightViewContent />
    </div>
  );
});

export default PaymentScreen;