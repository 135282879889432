
import React, { useState } from "react";
import { Link } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import CommonCases from "../../Cases/CommonCases";

const Login = () => {
    const { handleChangeInput, handleLogin } = CommonCases();
    const [showEye, setShowEye] = useState(false);

    const showPassword = () => {
        let pass = document.querySelector('input[name="password"]');
        let icon = document.querySelector("#showpassword");
        if (pass.type === "password") {
            icon.classList.add("show");
            pass.type = "text";
            setShowEye(true)
        } else {
            icon.classList.remove("show");
            pass.type = "password";
            setShowEye(false)
        }
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper login_wrapper login_bg_2">
                    <div className="container">
                        <div className="login_page ">
                            <Link to="/" className="btn-back hh_icon"><i className="ri-arrow-left-s-line"></i></Link>
                            <img src="assets/images/logo_login.svg" className="logo_img" />
                            <div className="login_row login_scroll">
                                <h2>Log In</h2>
                                <p>Enter username and password to continue</p>
                                <form action="#" className="login_form">
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            <div className="field-box">
                                                <label htmlFor="Code" className="form-label">Email or Mobile Number</label>
                                                <input className="form-control" id="text" name="signId" type="text" placeholder="Enter Email or Mobile Number" onChange={handleChangeInput} />
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <div className="field-box">
                                                <label htmlFor="password" className="form-label">Password</label>
                                                <div className="vew_pss">
                                                    <input className="form-control" name="password" type="password" placeholder="Password" onChange={handleChangeInput} />
                                                    <button className="btn_view btn-icon" type="button" id="showpassword" onClick={showPassword}>
                                                        {
                                                            showEye === true ?
                                                                <i className="ri-eye-close-line"></i>
                                                                :
                                                                <i className="ri-eye-line"></i>
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-5">
                                            <div className="field-box text-end">
                                                <Link className="text-white" to="/forgotpassword"> Forgot Password? </Link>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <div className="field-box">
                                                <button className="custom-btn btn-border-gradient btn-gradient btn-block w-100 mb-3" type="button" onClick={handleLogin}>
                                                    <span>LOGIN</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="row justify-content-center text-center text-white">
                                <div className="col-lg-12 have_referal">Don't have an account?
                                    <Link className="color-primary " to="/register">Register</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default Login

