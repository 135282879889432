
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import AuthService from "../../AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import Countdown from "../../customComponent/CountDown";

const PreviewTeam = (props) => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [myTeamLength, setMyTeamLength] = useState();

    const totalRatingCount = state?.selectedPlayers.reduce((acc, curr) => acc + curr.fantasy_player_rating, 0);
    const selectedPlayerPids = state?.selectedPlayers?.map(player => player.pid);

    useEffect(() => {
        handleMyTeam();
    }, [])

    const countRoles = state?.selectedPlayers.reduce((acc, cur) => {
        acc[cur.playing_role] = (acc[cur.playing_role] || 0) + 1;
        return acc;
    }, {});

    const handleBack = () => {
        navigate('/cc_vc', { state: state });
    }

    const handleMyTeam = async () => {
        await AuthService.myTeamDetails(state?.contest_details?.[0]?.match_id).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    // alertSuccessMessage(result.message);
                    setMyTeamLength(result?.data)
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }

    const handleCreateTeam = async () => {
        await AuthService.createTeam(state?.contest_details?.[0]?.match_id, state?.captionName[0]?.pid, state?.viceCaptionName[0]?.pid, selectedPlayerPids, state?.MatchId, `T${myTeamLength?.length + 1}`).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(result.message);
                    navigate("/home");
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }


    const handleEditTeam = async () => {
        await AuthService.editTeam(state?.teamDetails?.[0]?._id, state?.contest_details?.[0]?.match_id, state?.captionName[0]?.pid, state?.viceCaptionName[0]?.pid, selectedPlayerPids, state?.MatchId, state?.teamDetails?.[0]?.name).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(result.message);
                    navigate("/home");
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg_img preview_wrapper">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span className="hh_icon cursor-pointer" onClick={handleBack} ><i className="ri-arrow-left-s-line ri-xl"></i> </span>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className=""><Countdown targetTime={state?.StartDateTime} /></h4>
                            </div>
                        </div>
                    </div>
                    <div className="main_panel match_details px-0">
                        <div className="play_card wbab_card mb-0">
                            <div className="wbab_title"> <small>{state?.SeriesName}</small> </div>
                            <span className="row g-0 team_row">
                                <div className="col-6">
                                    <div className="team_card team_left team_left_mi">
                                        <h4>{state?.TeamsShortNames[0]}</h4>
                                        <div className="team_tcd "><span>{state?.TeamA}</span>
                                            <img src={state?.TeamAlogo} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="team_card team_right team_right_SRH">
                                        <h4>{state?.TeamsShortNames[1]}</h4>
                                        <div className="team_tcd "><span>{state?.TeamB}</span>
                                            <img src={state?.TeamBlogo} />
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <div className="play_footer wbab_footer">
                                <span className="text-start">
                                    <em>{state?.selectedPlayers?.length}/11</em>
                                    <small>Selection</small>
                                </span>
                                <div className="team_badgs text-center">
                                    {[...Array(11)].map((_, index) => (
                                        <span key={index}>
                                            {state?.selectedPlayers.length > index && (
                                                <>
                                                    {state?.selectedPlayers[index].playing_role === "bowl" && (
                                                        <img className="active" src="assets/images/bowl.png" alt="" />
                                                    )}
                                                    {state?.selectedPlayers[index].playing_role === "wk" && (
                                                        <img className="active" src="assets/images/wk.png" alt="" />
                                                    )}
                                                    {state?.selectedPlayers[index].playing_role === "bat" && (
                                                        <img className="active" src="assets/images/bat.png" alt="" />
                                                    )}
                                                    {state?.selectedPlayers[index].playing_role === "all" && (
                                                        <img className="active" src="assets/images/AR.png" alt="" />
                                                    )}
                                                </>
                                            )}
                                        </span>
                                    ))}

                                </div>
                                <span className="text-end">
                                    <em>{totalRatingCount == '0' ? '100' : 100 - totalRatingCount}</em>
                                    <small>Credit</small>
                                </span>
                            </div>
                        </div>
                        <div className="team_preview ">
                            <div className="players_row">
                                <h5>WICKET KEEPERS ({countRoles?.wk})</h5>
                                <div className="team_bar">
                                    {state?.selectedPlayers.length > 0 &&
                                        state?.selectedPlayers.map((item, index) => {
                                            return item.playing_role === "wk" ? (
                                                <div className="player_ico" key={index}>
                                                    {
                                                        state?.captionName[0]?.pid === item.pid ?
                                                            <span className="player_badge">C</span>
                                                            : state?.viceCaptionName[0]?.pid === item.pid ?
                                                                <span className="player_badge">VC</span> : ''
                                                    }
                                                    <img src="assets/images/player_1.png" width="55" height="67" />
                                                    <div className="player_name">{item?.first_name}</div>
                                                </div>
                                            ) : null;
                                        })
                                    }
                                </div>
                            </div>
                            <div className="players_row">
                                <h5>BATSMEN ({countRoles?.bat})</h5>
                                <div className="team_bar">
                                    {state?.selectedPlayers.length > 0 &&
                                        state?.selectedPlayers.map((item, index) => {
                                            return item.playing_role === "bat" ? (
                                                <div className="player_ico" key={index}>
                                                    {
                                                        state?.captionName[0]?.pid === item.pid ?
                                                            <span className="player_badge">C</span>
                                                            : state?.viceCaptionName[0]?.pid === item.pid ?
                                                                <span className="player_badge">VC</span> : ''
                                                    }
                                                    <img src="assets/images/player_1.png" width="55" height="67" />
                                                    <div className="player_name">{item?.first_name}</div>
                                                </div>
                                            ) : null;
                                        })
                                    }
                                </div>
                            </div>
                            <div className="players_row">
                                <h5>ALL ROUNDERS ({countRoles?.all})</h5>
                                <div className="team_bar">
                                    {state?.selectedPlayers.length > 0 &&
                                        state?.selectedPlayers.map((item, index) => {
                                            return item.playing_role === "all" ? (
                                                <div className="player_ico" key={index}>
                                                    {
                                                        state?.captionName[0]?.pid === item.pid ?
                                                            <span className="player_badge">C</span>
                                                            : state?.viceCaptionName[0]?.pid === item.pid ?
                                                                <span className="player_badge">VC</span> : ''
                                                    }
                                                    <img src="assets/images/player_1.png" width="55" height="67" />
                                                    <div className="player_name">{item?.first_name}</div>
                                                </div>
                                            ) : null;
                                        })
                                    }
                                </div>
                            </div>
                            <div className="players_row">
                                <h5>BOWLERS ({countRoles?.bowl})</h5>
                                <div className="team_bar">
                                    {state?.selectedPlayers.length > 0 &&
                                        state?.selectedPlayers.map((item, index) => {
                                            return item.playing_role === "bowl" ? (
                                                <div className="player_ico" key={index}>
                                                    {
                                                        state?.captionName[0]?.pid === item.pid ?
                                                            <span className="player_badge">C</span>
                                                            : state?.viceCaptionName[0]?.pid === item.pid ?
                                                                <span className="player_badge">VC</span> : ''
                                                    }
                                                    <img src="assets/images/player_1.png" width="55" height="67" />
                                                    <div className="player_name">{item?.first_name}</div>
                                                </div>
                                            ) : null;
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="nav_footer nav_footer_transperant">
                    <div className="container">
                        <div className="row gx-2 justify-content-center">
                            <div className="col-6">
                                <button type="button" className="custom-btn btn-border-gradient  btn-block w-100 text-uppercase" onClick={() => handleBack()}>
                                    <span> Close</span>
                                </button>
                            </div>
                            <div className="col-6">
                                {
                                    state?.teamDetails && state?.editCreatedTeam === true ?
                                        <button type='button' className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase"
                                            onClick={handleEditTeam}>
                                            <span> Edit</span>
                                        </button>
                                        :
                                        <button type='button' className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase"
                                            onClick={handleCreateTeam}>
                                            <span> Continue</span>
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default PreviewTeam

