import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import AuthService from "../../AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import { $ } from "react-jquery-plugin";

const CreateMultipuleTeam = (prop) => {

    const { state } = useLocation();
    const navigate = useNavigate();
    const [myTeamsDetails, setMyTeamsDetails] = useState([]);
    const [activeItemIndex, setActiveItemIndex] = useState(null);
    const [joinData, setJoinData] = useState(null);

    const handleBack = () => {
        window.history.back()
    }
    console.log(state, 'state');

    useEffect(() => {
        handleMyTeams();
    }, []);

    const handleMyTeams = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getMyTeamsDetails(state?.state?._id).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setMyTeamsDetails(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }

    const handleJoinContest = (item, index) => {
        setActiveItemIndex(index);
        setJoinData(item)
    }

    const openModal = () => {
        if (activeItemIndex === null) {
            alertErrorMessage('Please Select Team Before Join Contest');
        } else {
            $("#confirmationModal").modal('show');
        }
    };


    const handleJoinMultipuleTeam = async () => {
        
        console.log(joinData?.match_id,'joinData?.match_id');
        console.log(joinData?.matchid,'joinData?.matchid');
        console.log(state?.state?.contest_category_id,'contest_category_id');
        console.log(joinData?._id,'oinData?._id');
        console.log(state?.state?.inner_data_id,'state?.state?.inner_data_id');
        console.log(state?.state?.EnteryFee,'state?.state?.EnteryFee');

        LoaderHelper.loaderStatus(true);        
        await AuthService.joinContest(joinData?.match_id, joinData?.matchid, state?.winingItem?.contest_category_id, joinData?._id, state?.winingItem?.inner_data_id, state?.winingItem?.EnteryFee).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage(result.message);
                $("#confirmationModal").modal('hide');
                handleBack();
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
                $("#confirmationModal").modal('hide');
                handleBack();
            }
        });
    }

    const handleEditTeam = (item) => {
        const combinedData = {
            ...state,
            teamDetails: [item],
            editCreatedTeam: true,
        };
        navigate('/create_team', { state: combinedData });
    }


    const handleEditAndCreateTeam = (item) => {
        const combinedData = {
            ...state,
            teamDetails: [item],
            editCreatedTeam: false,
        };
        navigate('/create_team', { state: combinedData });
    }


    function handleShareWhatsApp() {
        const shareText = `Check out my code: ${prop?.userDetails?.refercode}!`;
        const shareUrl = `https://api.ibatfantasysports.com/share?code=${encodeURIComponent(prop?.userDetails?.refercode)}`;

        const encodedShareText = encodeURIComponent(shareText + ' ' + shareUrl);
        const shareLink = `https://web.whatsapp.com/send?text=${encodedShareText}`;

        const shareWindow = window.open(shareLink, '_blank');
        if (!shareWindow) {
            alert('Please disable your popup blocker to share the page.');
        }
    }

    const handleShowTeam = (item) => {
        const combinedData = {
            ...state,
            teamDetails: [item],
        };
        navigate('/view_created_team', { state: combinedData });
    }


    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg-gradient preview_wrapper">
                    <div className="header_wrapper">
                        <div className="row align-items-center" >
                            <div className="col-3 text-start" >
                                <span className="hh_icon cursor-pointer" onClick={handleBack}>
                                    <i className="ri-close-line ri-xl "></i>
                                </span>
                            </div>
                            <div className="col-6 text-center" >
                                <h4 className="">Select Youy Team</h4>
                            </div>
                            <div className="col-3  text-end">
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0 home_scroll">
                        <div className="container" >
                            {Object.keys(myTeamsDetails).length > 0 ? (
                                myTeamsDetails.map((item, index) => {
                                    const TeamA = item.players.filter((player) => `${player?.primary_team?.abbr} ` === state?.state?.TeamsShortNames?.[0]);
                                    const TeamB = item.players.filter((player) => player?.primary_team?.abbr === state?.state?.TeamsShortNames?.[1]);
                                    return (
                                        <div className={`mc_card team_box cursor-pointer ${activeItemIndex === index ? 'active' : ''}`} key={index} onClick={() => handleJoinContest(item, index)}>
                                            <div className="mc_body row align-items-center">
                                                <div className="mc_left col-6">
                                                    <span className="text-white">{item?.name}</span>
                                                </div>
                                                <div className="mc_right  col-6 text-end">
                                                    <div className="team_edit">
                                                        <button className="mc_icon hh_icon" onClick={() => handleShowTeam(item)}>
                                                            <i className="ri-eye-line"></i>
                                                        </button>
                                                        <button className="mc_icon hh_icon" type="button" onClick={() => handleEditTeam(item)}>
                                                            <i className="ri-edit-2-line"></i>
                                                        </button>
                                                        <button className="mc_icon hh_icon" type="button" onClick={() => handleEditAndCreateTeam(item)}>
                                                            <i className="ri-file-copy-line"></i>
                                                        </button>
                                                        <button className="mc_icon hh_icon" onClick={handleShareWhatsApp}>
                                                            <i className="ri-share-line"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mc_team">
                                                <div className="d-inline-flex">
                                                    <span> <h4>{TeamA?.length}</h4> {state?.state?.TeamsShortNames?.[0]} </span>
                                                    <span> <h4>{TeamB?.length}</h4>{state?.state?.TeamsShortNames?.[1]}</span>
                                                </div>
                                                <div className="team_bar">
                                                    {item?.players
                                                        ? item.players
                                                            .filter((contest) => contest.caption)
                                                            .map((contest, index) => (
                                                                <div className="player_ico" key={index}>
                                                                    <span className="player_badge">C</span>
                                                                    <img src="assets/images/player_1.png" width="80" height="98" />
                                                                    <div className="player_name">{contest?.first_name}</div>
                                                                </div>
                                                            ))
                                                        : null
                                                    }
                                                    {item?.players
                                                        ? item.players
                                                            .filter((contest) => contest.vice_caption)
                                                            .map((contest, index) => (
                                                                <div className="player_ico" key={index}>
                                                                    <span className="player_badge">VC</span>
                                                                    <img src="assets/images/player_1.png" width="80" height="98" />
                                                                    <div className="player_name">{contest?.first_name}</div>
                                                                </div>
                                                            ))
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="mc_footer">
                                                {item?.players
                                                    ? (() => {
                                                        const filteredArray = item.players.filter((player) => player?.playing_role === "wk");
                                                        const length = filteredArray.length;
                                                        return (
                                                            <span className="ms_badges text-white">
                                                                <small className="me-2">WK</small>({length})
                                                            </span>

                                                        );
                                                    })()
                                                    : null}

                                                {item?.players
                                                    ? (() => {
                                                        const filteredArray = item.players.filter((player) => player?.playing_role === "bat");
                                                        const length = filteredArray.length;
                                                        return (
                                                            <span className="ms_badges text-white">
                                                                <small className="me-2">BAT</small>({length})
                                                            </span>

                                                        );
                                                    })()
                                                    : null}

                                                {item?.players
                                                    ? (() => {
                                                        const filteredArray = item.players.filter((player) => player?.playing_role === "all");
                                                        const length = filteredArray.length;
                                                        return (
                                                            <span className="ms_badges text-white">
                                                                <small className="me-2">AR</small>({length})
                                                            </span>

                                                        );
                                                    })()
                                                    : null}

                                                {item?.players
                                                    ? (() => {
                                                        const filteredArray = item.players.filter((player) => player?.playing_role === "bowl");
                                                        const length = filteredArray.length;
                                                        return (
                                                            <span className="ms_badges text-white">
                                                                <small className="me-2">BOWL</small>({length})
                                                            </span>

                                                        );
                                                    })()
                                                    : null}
                                            </div>
                                        </div>
                                    );
                                })
                            ) : <div className="no_data"><span>No Team Found</span></div>}
                        </div>
                    </div>
                </div>
                <div className="nav_footer nav_footer_transperant" >
                    <div className="container" >
                        <div className="row gx-2 justify-content-center" >
                            <div className="col-12" >
                                <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase" onClick={openModal}>
                                    <span> Join Contest</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/*confirmationModal Model */}
                <div className="modal filter_modal" id="confirmationModal" tabIndex="-1" aria-labelledby="notificationsModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center" id="notificationsModalLabel"> Confirmation</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <ul className="pay_list">
                                    <li className="main_pay">
                                        <strong>Entry</strong>
                                        <span>{state?.EnteryFee}</span>
                                    </li>
                                    <li className="">
                                        <p>Current IBAT Balance</p>
                                        <span>{prop?.userDetails?.ibat_balance}</span>
                                    </li>
                                    <li className="">
                                        <p>Usable Cash Bonus</p>
                                        <span>{prop?.userDetails?.cash_bonus}</span>
                                    </li>
                                    <li className="total_pay">
                                        <p>To Pay IBAT</p>
                                        <p>{state?.winingItem?.EnteryFee}</p>
                                    </li>
                                </ul>

                            </div>
                            <div className="modal-footer d-block">
                                <div className="row gx-2 justify-content-center">
                                    <div className="col-6">
                                        <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-block w-100" onClick={handleJoinMultipuleTeam}>Join</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <RightViewContent />
        </div>
    )
}

export default CreateMultipuleTeam

