import React, { useState, useEffect } from 'react';
import moment from 'moment';

function Countdown(props) {
  const { targetTime } = props;
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetTime]);

  function calculateTimeRemaining() {
    const duration = moment.duration(moment(targetTime).diff(moment()));
    const totalSeconds = duration.asSeconds();
    const days = Math.floor(totalSeconds / (24 * 60 * 60));
    const hours = Math.floor(totalSeconds / (60 * 60)) % 24;
    const minutes = Math.floor(totalSeconds / 60) % 60;
    const seconds = Math.floor(totalSeconds) % 60;

    if (days > 1 || (days === 1 && hours === 0 && minutes === 0 && seconds === 0)) {
      // show days only
      return { days, hours: 0, minutes: 0, seconds: 0 };
    } else {
      // show hours, minutes, and seconds
      return { days: 0, hours, minutes, seconds };
    }
  }

  function formatTime(num, isDay = false) {
    if (isDay) {
      return parseInt(num, 10).toString();
    }
    return num < 10 ? `0${num}` : num.toString();
  }

  return (
    <div>
      {timeRemaining.days > 0 && (
        <div>
          <span>{formatTime(timeRemaining.days, true)}</span>
          <span> Day's {timeRemaining.days > 1 ? '' : ''}</span>
        </div>
      )}
      {timeRemaining.days <= 0 && (
        <div>
          {timeRemaining.hours > 0 && (
            <span>{formatTime(timeRemaining.hours, true)}h </span>
          )}
          <span>{formatTime(timeRemaining.minutes)}m </span>
          <span>{formatTime(timeRemaining.seconds)}s</span>
        </div>
      )}
    </div>
  );
}

export default Countdown;
