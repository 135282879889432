import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import { alertErrorMessage, alertSuccessMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import AuthService from "../../AuthService";
import { $ } from "react-jquery-plugin";

const MyContestDetails = () => {

    const [myTeamsDetails, setMyTeamsDetails] = useState([]);
    const [myContestDetails, setMyContestDetails] = useState([]);
    const [checkOne, setCheckOne] = useState(false);
    const [checkTwo, setCheckTwo] = useState(false);

    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        handleMycontest();
        handleMyTeams();
    }, []);


    const handleMycontest = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getMyContestDetails(state?._id).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setMyContestDetails(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }


    const handleMyTeams = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getMyTeamsDetails(state?._id).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setMyTeamsDetails(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }

    const handleMatchReminder = async (checkOne, checkTwo) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.matchReminder(state?._id, state?.MatchId, state?.SeriesId, checkOne, checkTwo).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(result?.message)
                    $("#notificationsModal").modal('hide');
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }

    const handleWinningLeaderBoard = (item) => {
        const combinedData = {
            ...state,
            winingItem: item
        };
        navigate('/leaderboard_complete', { state: combinedData });
    }

    const handleBack = () => {
        navigate('/home', { state: state });
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg_img">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span className="hh_icon cursor-pointer" onClick={handleBack}><i className="ri-arrow-left-s-line ri-xl"></i> </span>
                            </div>
                            <div className="col-6  text-center"></div>
                            <div className="col-3  text-end">
                                <div className="hh_row">
                                    <Link to="#" data-bs-toggle="modal" data-bs-target="#notificationsModal" className="hh_icon"> <i
                                        className="ri-notification-4-fill"></i> </Link>
                                    <Link to="/my_balance" className="hh_icon"> <i className="ri-wallet-3-fill"></i> </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main_panel match_details px-0">
                        <div className="play_card">
                            <div className="play_card_center"><span className="matcs_status">VS</span> </div>
                            <span className="row g-0 team_row">
                                <div className="col-6">
                                    <div className="team_card team_left team_left_mi">
                                        <div className="team_tcd "><span>{state?.TeamA}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="team_card team_right team_right_SRH">
                                        <div className="team_tcd "><span>{state?.TeamB}</span>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <div className="detals_wrapper">
                            <div className="container">
                                <ul className="details_tab btn-gradient border-gradient nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item nav-item-half" role="presentation">
                                        <button className="btn nav-link active" id="mycontest-tab" data-bs-toggle="tab" data-bs-target="#mycontest"
                                            type="button" role="tab" aria-controls="mycontest" aria-selected="false"> <span>My Contest({myContestDetails.length})</span>
                                        </button>
                                    </li>
                                    <li className="nav-item nav-item-half" role="presentation">
                                        <button className="btn nav-link" id="Team-tab" data-bs-toggle="tab" data-bs-target="#Team" type="button"
                                            role="tab" aria-controls="Team" aria-selected="false"> <span>My Team({myTeamsDetails.length})</span> </button>
                                    </li>
                                </ul>
                                <div className="tab-content ms_scroll" id="myTabContent">
                                    <div className="tab-pane fade show active" id="mycontest" role="tabpanel" aria-labelledby="mycontest-tab">
                                        {myContestDetails?.length > 0 ? (
                                            myContestDetails.map((item, index) => {
                                                return (
                                                    <div className="mc_card cursor-pointer" key={index} onClick={() => handleWinningLeaderBoard(item)}>
                                                        <div className="mc_body row">
                                                            <div className="mc_left col-8">
                                                                <span>PRIZE POOL</span>
                                                                <div className="d-flex mt-2 align-items-center">
                                                                    <h3 className="mb-0">${item?.contest_details?.winning_amount} </h3>
                                                                </div>
                                                            </div>

                                                            <div className="mc_right  col-4 text-end">
                                                                <span className="mc_rate btn btn-sm btn-success ms-auto cursor-pointer cursor-pointer mt-4">
                                                                    ${item?.data?.EnteryFee}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="mc_footer">
                                                            <div className="d-flex align-items">
                                                                <span className="ms_badges"> JOINED WITH {item?.contest_details?.joined} TEAM</span>
                                                            </div>
                                                            <div className="d-flex align-items">
                                                                <span className="ms_badges ms_badges_jwt mx-1">T{item?.contest_details?.joined}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="no_data">
                                                <span>No Team Found</span>
                                            </div>
                                        )}
                                    </div>

                                    <div className="tab-pane fade" id="Team" role="tabpanel" aria-labelledby="Team-tab">
                                        {Object.keys(myTeamsDetails).length > 0 ? (
                                            myTeamsDetails.map((item, index) => {
                                                return (
                                                    <div className="mc_card team_box" key={index}>
                                                        <div className="mc_body row align-items-center">
                                                            <div className="mc_left col-6">
                                                                <span className="text-white">{item?.name}</span>
                                                            </div>
                                                            <div className="mc_right  col-6 text-end">
                                                                <div className="team_edit">
                                                                    <button className="mc_icon hh_icon">
                                                                        <i className="ri-share-line"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mc_team">
                                                            <div className="d-inline-flex">
                                                                {item?.players
                                                                    ? (() => {
                                                                        const filteredArray = item.players.filter((player) => player?.primary_team?.alt_name === state?.TeamA);
                                                                        const length = filteredArray.length;
                                                                        return (
                                                                            <span>
                                                                                <h4>{length}</h4>
                                                                                {state?.TeamA}
                                                                            </span>
                                                                        );
                                                                    })()
                                                                    : null}

                                                                {item?.players ? (() => {
                                                                    const filteredArray = item.players.filter((player) => player?.primary_team?.alt_name === state?.TeamB);
                                                                    const length = filteredArray.length;
                                                                    return (
                                                                        <span>
                                                                            <h4>{length}</h4>
                                                                            {state?.TeamB}
                                                                        </span>
                                                                    );
                                                                })() : null}

                                                            </div>
                                                            <div className="team_bar d-flex">
                                                                {item?.players
                                                                    ? item.players
                                                                        .filter((contest) => contest.caption)
                                                                        .map((contest, index) => (
                                                                            <div className="player_ico" key={index}>
                                                                                <span className="player_badge">C</span>
                                                                                <img src="assets/images/player_1.png" width="80" height="98" />
                                                                                <div className="player_name">{contest?.first_name}</div>
                                                                            </div>
                                                                        ))
                                                                    : null
                                                                }
                                                                {item?.players
                                                                    ? item.players
                                                                        .filter((contest) => contest.vice_caption)
                                                                        .map((contest, index) => (
                                                                            <div className="player_ico" key={index}>
                                                                                <span className="player_badge">VC</span>
                                                                                <img src="assets/images/player_1.png" width="80" height="98" />
                                                                                <div className="player_name">{contest?.first_name}</div>
                                                                            </div>
                                                                        ))
                                                                    : null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="mc_footer">
                                                            {item?.players
                                                                ? (() => {
                                                                    const filteredArray = item.players.filter((player) => player?.playing_role === "wk");
                                                                    const length = filteredArray.length;
                                                                    return (
                                                                        <span className="ms_badges text-white">
                                                                            <small className="me-2">WK</small>({length})
                                                                        </span>

                                                                    );
                                                                })()
                                                                : null}

                                                            {item?.players
                                                                ? (() => {
                                                                    const filteredArray = item.players.filter((player) => player?.playing_role === "bat");
                                                                    const length = filteredArray.length;
                                                                    return (
                                                                        <span className="ms_badges text-white">
                                                                            <small className="me-2">BAT</small>({length})
                                                                        </span>

                                                                    );
                                                                })()
                                                                : null}

                                                            {item?.players
                                                                ? (() => {
                                                                    const filteredArray = item.players.filter((player) => player?.playing_role === "all");
                                                                    const length = filteredArray.length;
                                                                    return (
                                                                        <span className="ms_badges text-white">
                                                                            <small className="me-2">AR</small>({length})
                                                                        </span>

                                                                    );
                                                                })()
                                                                : null}

                                                            {item?.players
                                                                ? (() => {
                                                                    const filteredArray = item.players.filter((player) => player?.playing_role === "bowl");
                                                                    const length = filteredArray.length;
                                                                    return (
                                                                        <span className="ms_badges text-white">
                                                                            <small className="me-2">BOWL</small>({length})
                                                                        </span>

                                                                    );
                                                                })()
                                                                : null}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : <div className="no_data"><span>No Team Found</span></div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*  <!-- modal notifications --> */}
                <div className="modal filter_modal" id="notificationsModal" tabIndex="-1" aria-labelledby="notificationsModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="notificationsModalLabel">
                                    <i className="ri-notification-4-fill me-2"></i>
                                    Set Match Reminder
                                </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body p-0">
                                <div className="filter_row d-flex justify-content-between align-items-center">
                                    <div className="pe-2">
                                        <em>Match - {state?.TeamsShortNames[0]} vs {state?.TeamsShortNames[1]}</em>
                                        <p>will send reminder when lineup announced</p>
                                    </div>
                                    <label className="switch" htmlFor="check_1">
                                        <input type="checkbox" id="check_1" checked={checkOne} onChange={(e) => setCheckOne(e.target.checked)} />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                                <div className="filter_row d-flex justify-content-between align-items-center">
                                    <div className="pe-2">
                                        <em>{state?.SeriesName}</em>
                                        <p>will send reminder when lineup announced in every match</p>
                                    </div>
                                    <label className="switch" htmlFor="check_2">
                                        <input type="checkbox" id="check_2" checked={checkTwo} onChange={(e) => setCheckTwo(e.target.checked)} />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="modal-footer d-block">
                                <div className="row gx-2">
                                    <div className="col-6">
                                        <button type="button" className="custom-btn btn-border-gradient  btn-block w-100"
                                            data-bs-dismiss="modal">Clear</button>
                                    </div>
                                    <div className="col-6">
                                        <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-block w-100" onClick={() => handleMatchReminder(checkOne, checkTwo)}>
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <RightViewContent />
        </div>
    )
}

export default MyContestDetails

