
const notEqualsZero = value => {
  if (value) {
    if (value.length !== 0) return true;
    else return false;
  } else return false;
};

const validatePanCard = value =>
  value && !/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/i.test(value)
    ? 'Please Enter Valid Pan Card Number'
    : null;

const validIfscCode = value =>
  value && !/^[A-Z]{4}0[A-Z0-9]{6}$/i.test(value)
    ? 'Please enter valid Ifsc Code Number'
    : undefined;

const validAccountnumber = value =>
  value && !/^[0-9]{7,16}$/.test(value)
    ? 'Please enter valid Account Number.'
    : undefined;

export {
  notEqualsZero,
  validatePanCard,
  validIfscCode,
  validAccountnumber,
};