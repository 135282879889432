
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import { alertErrorMessage, alertSuccessMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import AuthService from "../../AuthService";
import { validIfscCode, validAccountnumber } from "../../utils/Validation";
import { StateList } from "../../utils/StateList";

const VerifyBankAccount = () => {

   const userId = localStorage.getItem('_id');
    const navigate = useNavigate();
    const [accountNumber, setAccountNumber] = useState("");
    const [ifscCCode, setIfscCCode] = useState("");
    const [bankName, setBankName] = useState("");
    const [branchName, setBranchName] = useState("");
    const [selectState, setSelectState] = useState("");

    const handleBackKycDetails = () => {
        navigate("/kyc_details");
    }

    const handleVerifyBankAccount = async (accountNumber, ifscCCode, bankName, branchName, selectState) => {
        if (!accountNumber) {
            alertErrorMessage('Please Enter Your Account Number')
        } else if (validAccountnumber(accountNumber)) {
            alertErrorMessage('Please Enter Valid Account Number')
        } else if (!ifscCCode) {
            alertErrorMessage('Please Enter Your IFSC Code')
        } else if (validIfscCode(ifscCCode)) {
            alertErrorMessage('Please Enter Valid IFSC Number')
        } else if (!bankName) {
            alertErrorMessage('Please Enter Your Bank Name')
        } else if (!branchName) {
            alertErrorMessage('Please Enter Your Branch Name')
        } else if (!selectState) {
            alertErrorMessage('Please Select Your State')
        }
        else {
            LoaderHelper.loaderStatus(true);
            await AuthService.verifyBankAccount(accountNumber, ifscCCode, bankName, branchName, selectState, userId).then(async result => {
                if (result.success) {
                    LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result.message);
                    navigate("/kyc_details");
                } else {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            });
        }
    }


    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg-gradient preview_wrapper edit_profile">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span className="hh_icon cursor-pointer" onClick={handleBackKycDetails}><i className="ri-arrow-left-s-line ri-xl"></i> </span>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className="">Verify Bank Account</h4>
                            </div>
                            <div className="col-3  text-end">
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0 home_scroll ">
                        <div className="container">
                            <form className="">
                                <div className="pay_card mb-3 ">
                                    <div className="pay_header">
                                        <div className="h6">Enter Your Bank Account Details</div>
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>Account Number</label>
                                        <input type="number" className="form-control" value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>IFSC Code</label>
                                        <input type="text" className="form-control" value={ifscCCode} onChange={(e) => setIfscCCode(e.target.value)} />
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>Bank Name</label>
                                        <input type="text" className="form-control" value={bankName} onChange={(e) => setBankName(e.target.value)} />
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>Branch Name</label>
                                        <input type="text" className="form-control" value={branchName} onChange={(e) => setBranchName(e.target.value)} />
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>State</label>
                                        <select className="form-select" aria-label="Default select example" value={selectState} onChange={(e) => setSelectState(e.target.value)}>
                                            <option value="" disabled defaultValue>Please Choose Your State..</option>
                                            {StateList?.list?.map((item, index) => {
                                                return (
                                                    <option key={index}>{item}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="nav_footer nav_footer_transperant">
                    <div className="container">
                        <div className="row gx-2 justify-content-center">
                            <div className="col-12">
                                <button className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase" type="button" onClick={() => handleVerifyBankAccount(accountNumber, ifscCCode, bankName, branchName, selectState)}>
                                    <span> VERIFY</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <RightViewContent />

        </div>

    )
}

export default VerifyBankAccount

