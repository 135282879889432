
import React, { useEffect, useState } from "react";
import RightViewContent from "../../customComponent/RightViewContent";
import NavigationFooter from "../../customComponent/NavigationFooter";
import { Link, useNavigate } from "react-router-dom";
import { ApiConfig } from "../../utils/apiConfig/apiConfig";

const Profile = (props) => {

    const navigate = useNavigate();
    const [activity, setActivity] = useState("");
    useEffect(() => {
        const {userActivity} = props;
        if (userActivity) {
            setActivity(userActivity);
            // console.log('=========profile data=========', userActivity);
        }
    }, [props]);

    const handleMyBalance = () => {
        navigate('/my_balance');
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg_img profile_wrapper">
                    <div className="header_wrapper header_profile">
                        <div className="row align-items-center">
                            <div className="col-8">
                                <div className="d-flex align-items-center">
                                    <Link to="#" className="user_icon border-gradient">
                                        {props?.userDetails?.logo ?
                                            <img src={`${ApiConfig.baseUrl + props?.userDetails?.logo}`} />
                                            :
                                            <img src='assets/images/user.png' />
                                        }
                                    </Link>
                                    <div className="pro_data">
                                        <h5>{props?.userDetails?.username}</h5>
                                        <p>{props?.userDetails?.email_or_phone}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 text-end">
                                <div className="hh_row justify-content-end">
                                    <Link to="/edit_profile" className=" p-2 px-3  btn-sm btn-gradient text-white">Edit</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="scroll_pro">
                        <div className="container">
                            <div className="my_bal_card pay_card ">
                                <div className="t_bal">
                                    <div>
                                        <h5>${props?.userDetails?.total_balance ? props?.userDetails?.total_balance.toFixed(2): 0}</h5>
                                        <span>Total Balance</span>
                                    </div>
                                    <span className="hh_icon cursor-pointer" onClick={handleMyBalance}><i className="ri-arrow-right-line "></i></span>
                                </div>
                                <div className="row gx-2">
                                    <div className="col-6">
                                        <div className="card_mini border-gradient border-gradient-mini">
                                            <div>
                                                <h5>{props?.userDetails?.cash_bonus}</h5>
                                                <span>Cash Bonus</span>
                                            </div>
                                            <img src="assets/images/cash_bonus.png" alt="" width="30" height="30" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="card_mini border-gradient border-gradient-mini">
                                            <div>
                                                <h5>{props?.userDetails?.winning_amount}</h5>
                                                <span>Winning Amount</span>
                                            </div>
                                            <img src="assets/images/winnin_amount.png" alt="" width="30" height="30" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="my_activity mb-3">
                                <div className="sc_title  mb-2">
                                    <h4>Your Activity</h4>
                                </div>
                                <div className="row g-2">
                                    <div className="col-6">
                                        <div className="card_mini border-gradient border-gradient-dark">
                                            <div>
                                                <img src="assets/images/played_contest.png" className="mb-1" alt="" width="35" height="35" />
                                                <span>Played Contest</span>
                                            </div>
                                            <h5>{activity?.played_contest}</h5>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="card_mini border-gradient border-gradient-dark">
                                            <div>
                                                <img src="assets/images/match_played.png" className="mb-1" alt="" width="35" height="35" />
                                                <span>Match Played</span>
                                            </div>
                                            <h5>{activity?.match_played}</h5>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="card_mini border-gradient border-gradient-dark">
                                            <div>
                                                <img src="assets/images/total_series.png" className="mb-1" alt="" width="35" height="35" />
                                                <span>Total Series</span>
                                            </div>
                                            <h5>{activity?.total_series}</h5>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="card_mini border-gradient border-gradient-dark">
                                            <div>

                                                <img src="assets/images/total_sports.png" className="mb-1" alt="" width="35" height="35" />
                                                <span>Total Sports</span>
                                            </div>
                                            <h5>{activity?.total_sports}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="my_activity mb-3">
                                <div className="sc_title  mb-2">
                                    <h4>Level Reached</h4>
                                </div>
                                <div className="card_mini border-gradient border-gradient-dark">
                                    <div className="d-block w-100">
                                        <div className="d-flex align-items-center mb-2">
                                            <h5 className="rank-item rank_label me-2 mb-0">
                                                {props?.userDetails?.levels?.current_level}
                                            </h5>
                                            <h5 className="mb-0">{props?.userDetails?.levels?.current_level_name}</h5>
                                        </div>
                                        <div className="pro_progress">
                                            <div className="progress_card" style={{ width: '50%' }}></div>
                                            <div className="progress_txt">
                                                <span className="active">{props?.userDetails?.levels?.current_level}</span>
                                                <span>{props?.userDetails?.levels?.total_level}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <NavigationFooter />
            </div>
            <RightViewContent />
        </div>
    )
}

export default Profile

