
import React from "react";
import { Link } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import NavigationFooter from "../../customComponent/NavigationFooter";


const PrivacyPolicy = (props) => {



    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                {/* <!-- main screen --> */}
                <div className="main_wrapper bg-gradient preview_wrapper edit_profile">
                    {/* <!-- header part --> */}
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                {/* <!-- <Link to="/my_balance" className="hh_icon"><i className="ri-arrow-left-s-line ri-xl  "></i> </Link>--> */}
                            </div>
                            <div className="col-6  text-center">
                                <img src="assets/images/logo_white.svg" className="main_logo" />
                            </div>
                            <div className="col-3 text-end">
                                <div className="hh_row justify-content-end">
                                    {/* <Link to="#" className="hh_icon"> <i className="ri-notification-4-fill"></i> </Link> */}
                                    {/*  <!-- <Link to="#" className="hh_icon"> <i className="ri-wallet-3-fill"></i> </Link> --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0  home_scroll">
                        <div className="container">
                            <div class="page-header mb-2">
                                <h3>Privacy Policy</h3>
                            </div>

                            <div class="page-header mb-2">
                                Effective Date: 9/07/2023
                            </div> 

                            <div class="page-header mb-2">
                                <h4>Introduction</h4>
                            </div>

                            <div class="page-header mb-1">
                                Welcome to Battle Infinity Games LTD (“we,” “us,” or “our”). We are committed to protecting the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information in relation to your interactions with our games, websites, and services (collectively referred to as the “Services”).
                            </div> 

                            <div class="page-header mb-2">
                                By accessing or using our Services, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, disclosure, and retention of your information as described herein.
                            </div>

                            <div class="page-header mb-2">
                                <h4>Information We Collect</h4>
                            </div>

                            <div class="page-header mb-1">
                                We may collect the following types of information when you use our Services:
                            </div> 
                            <div class="page-header mb-1">
                                <b className="fn-900-cus">Personal Information</b>: When you create an account, or interact with our Services, you may provide personal information such as your name, email address, and username.                            
                            </div> 
                            <div class="page-header mb-1">
                                <b className="fn-900-cus">Gameplay Data</b>: We collect information related to your interactions with our games, including gameplay data, scores, achievements, and preferences.
                            </div> 
                            <div class="page-header mb-1">
                                <b className="fn-900-cus">Device and Usage Information</b>: We automatically collect information about the devices you use to access our Services, such as device type, operating system, browser type, IP address, and mobile device identifiers. We also gather usage data, such as pages visited, features used, and actions taken while using our Services.
                            </div> 
                            <div class="page-header mb-2">
                                <b className="fn-900-cus">Communication Data</b>: If you contact us for customer support or other inquiries, we may collect the information you provide during these communications.
                            </div> 

                            <div class="page-header mb-2">
                                <h4>How We Use Your Information</h4>
                            </div>

                            <div class="page-header mb-1">
                                We use your information for various purposes, including:
                            </div>
                            <div class="page-header mb-1">
                                Providing, maintaining, and improving our Services.
                            </div>
                            <div class="page-header mb-1">
                                Personalising your experience and tailoring in-game content.
                            </div>
                            <div class="page-header mb-1">
                                Sending you updates, and important notices.
                            </div>
                            <div class="page-header mb-1">
                                Analysing user behaviour and preferences to enhance our experience.
                            </div>
                            <div class="page-header mb-1">
                                Responding to your inquiries and providing customer support.
                            </div>
                            <div class="page-header mb-1">
                                Complying with legal obligations and enforcing our Terms of Service.
                            </div>
                            <div class="page-header mb-2">
                                Disclosure of Your Information
                            </div>

                            <div class="page-header mb-2">
                                <h4>We may share your information with:</h4>
                            </div>
                            <div class="page-header mb-1">
                                Service Providers: We may engage third-party service providers to perform functions on our behalf, such as data analysis, marketing, and customer support.
                            </div>
                            <div class="page-header mb-1">
                                Legal and Regulatory Authorities: We may disclose your information to comply with legal obligations, respond to legal requests, and protect our rights, privacy, safety, and property.
                            </div>
                            <div class="page-header mb-2">
                                Business Transfers: If we are involved in a merger, acquisition, sale of assets, or bankruptcy, your information may be transferred or sold as part of that transaction.
                            </div>

                            <div class="page-header mb-2">
                                <h4>Security</h4>
                            </div>
                            <div class="page-header mb-2">
                                We take reasonable measures to protect the information we collect. However, no data transmission over the internet or electronic storage system is completely secure. We cannot guarantee the security of your information.
                            </div>

                            <div class="page-header mb-2">
                                <h4>Your Choices</h4>
                            </div>
                            <div class="page-header mb-2">
                                You can manage your account information and privacy preferences through the settings in your account. You may also have the option to control cookies and other tracking technologies. Note that some features of our Services may be affected if you choose to disable cookies.
                            </div>

                            <div class="page-header mb-2">
                                <h4>Contact Us</h4>
                            </div>
                            <div class="page-header mb-2">
                                If you have questions or concerns about our Privacy Policy or practices, please contact us at ibatpremierleague@battleinfinity.io
                            </div>

                            <div class="page-header mb-2">
                                <h4>Changes to this Privacy Policy</h4>
                            </div>
                            <div class="page-header mb-2">
                                We may update this Privacy Policy from time to time. The “Effective Date” at the beginning of this policy indicates when the latest changes were made. Your continued use of our Services after changes are posted signifies your acceptance of the revised Privacy Policy.
                            </div>

                        </div>  
                                              
                    </div>
                    
                    
                </div>                
                {/* <NavigationFooter /> */}
            </div>
            <RightViewContent />
        </div>
    )
}

export default PrivacyPolicy

