
import React from "react";
import RightViewContent from "../../customComponent/RightViewContent";
import { useNavigate, useLocation } from "react-router-dom";


const KycDetails = (props) => {
    const { state } = useLocation();

    const navigate = useNavigate();

    const handleKycDetails = () => {
        navigate('/my_balance');
    }

    const verifyMobile = () => {
        navigate("/verify_mobile");
    }

    const verifyEmail = (emailAddress) => {
        navigate("/verify_email", { state: emailAddress });
    }

    const verifyPanCard = () => {
        navigate("/verify_pan");
    }

    const verifyBank = () => {
        navigate("/verify_bank");
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg-gradient">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span onClick={handleKycDetails} className="hh_icon cursor-pointer"><i className="ri-arrow-left-s-line ri-xl  "></i> </span>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className="">KYC Details</h4>
                            </div>
                            <div className="col-3  text-end">
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0 all_scroll ">
                        <div className="container">
                            <div className="po_row">
                                <div className="pay_card  mb-3 ">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items">
                                            <div className="po_icon">
                                                <i className="ri-phone-fill ri-xl"></i>
                                            </div>
                                            <div className="po_txt ps-3">
                                                <h5 className="text-white"> Mobile Number <br />
                                                    <small className="text-secondary d-block">
                                                        {
                                                            state?.phone ?
                                                                <small>{state?.phone}</small> :
                                                                <small> To get latest information</small>
                                                        }
                                                    </small>
                                                </h5>
                                            </div>
                                        </div>
                                        {props?.userDetails?.mobile_verified == 0 ?
                                            <button className="custom-btn btn-mini btn-border-gradient text-uppercase d-block" onClick={verifyMobile}>
                                                <span>Verify</span>
                                            </button>
                                            :
                                            <span className="badge-success badge">
                                                Verified
                                            </span>
                                        }
                                    </div>
                                </div>
                                <div className="pay_card  mb-3 ">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items">
                                            <div className="po_icon">
                                                <i className="ri-mail-send-line ri-xl"></i>
                                            </div>
                                            <div className="po_txt ps-3">
                                                <h5 className="text-white"> Email Address <br />
                                                    <small className="text-secondary">
                                                        {
                                                            state?.email ?
                                                                <small>{state?.email}</small> :
                                                                <small>To get latest information</small>
                                                        }
                                                    </small>
                                                </h5>
                                            </div>
                                        </div>
                                        {props?.userDetails?.email_verified == 0 ?
                                            <button className="custom-btn btn-mini btn-border-gradient text-uppercase d-block" onClick={() => verifyEmail(state?.email)}><span>Verify</span> </button>
                                            :
                                            <span className="badge-success badge">
                                                Verified
                                            </span>
                                        }
                                    </div>
                                </div>
                                <div className="pay_card  mb-3 ">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items">
                                            <div className="po_icon">
                                                <i className="ri-bank-card-line ri-xl"></i>
                                            </div>
                                            <div className="po_txt ps-3">
                                                <h5 className="text-white"> PAN Card <br /> <small className="text-secondary">
                                                    {
                                                        state?.pan_details?.PanNumber ?
                                                            <small>{state?.pan_details?.PanNumber}</small> :
                                                            <small>For safety ans security of all transactions.</small>
                                                    }
                                                </small>
                                                </h5>
                                            </div>
                                        </div>
                                        {props?.userDetails?.pan_verified == 0 ?
                                            <button className="custom-btn btn-mini btn-border-gradient text-uppercase d-block" onClick={verifyPanCard}><span>Verify</span> </button>
                                            : props?.userDetails?.pan_verified == 2 ?
                                                <span className="badge-warning badge">
                                                    Pan Card Kyc Pending
                                                </span> :
                                                <span className="badge-success badge">
                                                    Verified
                                                </span>
                                        }
                                    </div>
                                </div>
                                <div className="pay_card  mb-3 ">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items">
                                            <div className="po_icon">
                                                <i className="ri-bank-fill ri-xl"></i>
                                            </div>
                                            <div className="po_txt ps-3">
                                                <h5 className="text-white"> Bank Account<br />
                                                    <small className="text-secondary d-block">
                                                        {
                                                            state?.bank_details?.AccountNumber ?
                                                                <small>{state?.bank_details?.AccountNumber}</small> :
                                                                <small> For withdrawals to your bank account.</small>
                                                        }
                                                    </small> </h5>
                                            </div>
                                        </div>

                                        {props?.userDetails?.bank_verified == 0 ?
                                            <button className="custom-btn btn-mini btn-border-gradient text-uppercase d-block" onClick={verifyBank}>
                                                <span>Verify</span>
                                            </button>
                                            : props?.userDetails?.bank_verified == 2 ?
                                                <span className="badge-warning badge">
                                                    Kyc Pending
                                                </span>
                                                :
                                                <span className="badge-success badge">
                                                    Verified
                                                </span>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="nav_footer nav_footer_transperant">
                    <div className="container">
                        <div className="row gx-2 justify-content-center">
                            <div className="col-12">
                                <Link to="/home" className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase"  >
                                    <span> Continue</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
            <RightViewContent />
        </div>
    )
}

export default KycDetails

