import axios from "axios";
import { ApiCallPost, ApiCallGet, ApiCallPut } from "../utils/apiConfig/apiCall"
import { ApiConfig } from "../utils/apiConfig/apiConfig";

const AuthService = {

  Register: async (formData) => {
    const { baseUser, register } = ApiConfig;
    const url = baseUser + register;
    const params = {
      email_or_phone: formData?.signId,
      username: formData?.userName,
      first_name: formData?.firstName,
      last_name: formData?.lastName,
      password: formData?.password,
      otp: +formData?.otp,
      refercode: formData?.refercode,
      device: 'website'
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  LoginRegisterGoogle: async (formData) => {
    const { baseUser, registerGoogle } = ApiConfig;
    const url = baseUser + registerGoogle;
    const params = {
      email_or_phone: formData?.signId,
      username: formData?.userName,
      first_name: formData?.firstName,
      last_name: formData?.lastName,
      password: formData?.password,
      refercode: formData?.refercode,
      device: 'website-google'
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  LoginRegisterTelegram: async (formData) => {
    const { baseUser, loginRegisterTelegram } = ApiConfig;
    const url = baseUser + loginRegisterTelegram;
    const params = {
      email_or_phone: formData?.signId,
      username: formData?.userName,
      first_name: formData?.firstName,
      last_name: formData?.lastName,
      password: formData?.password,
      refercode: formData?.refercode,
      device: formData?.device,
      telegram_login: formData?.telegram_login
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  getverifyOtp: async (formData, authUserId) => {
    const { baseUser, getcodeSignup } = ApiConfig;
    const url = baseUser + getcodeSignup;
    const params = {
      otp: formData?.verifyOtp,
      _id: authUserId
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  sendOtp: async (email_or_phone, resend) => {
    const { baseUrl, sendOtpSignup } = ApiConfig;
    const url = baseUrl + sendOtpSignup;
    const params = {
      email_or_phone: email_or_phone,
      resend: resend
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  login: async (formData) => {
    const { baseUser, login } = ApiConfig;
    const url = baseUser + login;
    const params = {
      email: formData?.signId,
      password: formData?.password
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  refreshTokenData: async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUser, tokenRefresh } = ApiConfig;
    const url = baseUser + tokenRefresh;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallGet(url, headers);
  },

  getOtpForgot: async (email_or_phone, resend) => {
    const { baseUser, otpForgot } = ApiConfig;
    const url = baseUser + otpForgot;
    const params = {
      email_or_phone: email_or_phone,
      resend: resend
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  forgotPassword: async (formData) => {
    const { baseUser, forgotPassword } = ApiConfig;
    const url = baseUser + forgotPassword;
    const params = {
      email_or_phone: formData?.signId,
      otp: formData?.otp,
      new_password: formData?.newPassword,
      confirm_password: formData?.cNewPassword
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  updatePropfile: async (userName, firstName, lastName, emailId, phoneNumber, gender, birthDate, localLogoImage) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUser, updateProfile } = ApiConfig;
    const url = baseUser + updateProfile;
    const params = {
      username: userName,
      first_name: firstName,
      last_name: lastName,
      email_or_phone: emailId,
      phone: phoneNumber,
      gender: gender,
      birthDate: birthDate,
      logo: localLogoImage,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallPut(url, params, headers);
  },

  updateLogoImages: async (formData) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUrl, uploads } = ApiConfig;
    const url = baseUrl + uploads;
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': refreshToken
    };
    return ApiCallPost(url, formData, headers);
  },

  getUserDetails: async () => {
    const accessToken = localStorage.getItem("accessToken");
    const { baseUser, UserProfile } = ApiConfig;
    const url = baseUser + UserProfile;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': accessToken
    };
    return ApiCallGet(url, headers);
  },

  getContestDetails: async (matchId, sortby=null, sortDirection=null) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUrlMatch } = ApiConfig;
    const url = baseUrlMatch + `contests/${matchId}?sortby=${sortby}&sortDirection=${sortDirection}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallGet(url, headers);
  },

  getMyContestDetails: async (matchId, sortby=null, sortDirection=null) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUrlMatch } = ApiConfig;
    const url = baseUrlMatch + `my-contests/${matchId}?sortby=${sortby}&sortDirection=${sortDirection}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallGet(url, headers);
  },

  getMyTeamsDetails: async (matchId, sortby=null, sortDirection=null) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUrlMatch } = ApiConfig;
    const url = baseUrlMatch + `my-teams/${matchId}?sortby=${sortby}&sortDirection=${sortDirection}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallGet(url, headers);
  },


  getOtpVerifyEmail: async (emailId, userId) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUser } = ApiConfig;
    const url = baseUser + `send-kyp-otp?user=${userId}`;
    const params = {
      value: emailId,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallPost(url, params, headers);
  },


  verifyEmailKyc: async (emailId, otp, userId) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUser } = ApiConfig;
    const url = baseUser + `verify-kyc-otp?user=${userId}`;
    const params = {
      value: emailId,
      otp: otp,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallPost(url, params, headers);
  },


  verifyPanKyc: async (name, panNumber, birthDate, selectState, localPanImage, userId) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUser } = ApiConfig;
    const url = baseUser + `update-kyc?user=${userId}`;
    const params = {
      data: {
        name: name,
        pan_number: panNumber,
        dob: birthDate,
        state: selectState,
        file: localPanImage,
        id: userId,
      }
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallPost(url, params, headers);
  },

  verifyBankAccount: async (accountNumber, ifscCCode, bankName, branchName, selectState, userId) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUser } = ApiConfig;
    const url = baseUser + `update-kyc?user=${userId}`;
    const params = {
      data: {
        ac_number: accountNumber,
        ifsc: ifscCCode,
        name: bankName,
        branch: branchName,
        state: selectState,
        id: userId,
      }
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallPost(url, params, headers);
  },

  verifyMobileKyc: async (mobileNumber, otp, userId) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUser } = ApiConfig;
    const url = baseUser + `verify-kyc-otp?user=${userId}`;
    const params = {
      value: mobileNumber,
      otp: otp,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallPost(url, params, headers);
  },

  getTransactionHistory: async (buttonType) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUser } = ApiConfig;
    const url = baseUser + `transactions/${buttonType}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallGet(url, headers);
  },

  getWalletDetails: async () => {
    const accessToken = localStorage.getItem("accessToken");
    const { baseUrlWallet, walletDetails } = ApiConfig;
    const url = baseUrlWallet + walletDetails;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': accessToken
    };
    return ApiCallGet(url, headers);
  },

  withdrawalIbat: async (walletAddress, amount) => {
    const accessToken = localStorage.getItem("accessToken");
    const { baseUrlWallet, withdraw_ibat } = ApiConfig;
    const url = baseUrlWallet + withdraw_ibat;
    const params = {
      wallet_address: walletAddress,
      ibat_balance: amount,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': accessToken
    };
    return ApiCallPost(url, params, headers);
  },

  getNftList: async (Limit, status) => {
    const accessToken = localStorage.getItem("accessToken");
    const { baseUrl } = ApiConfig;
    let url;
    if (status) {
      url = baseUrl + `nft/collected?offset=0&sell_type=${status}&limit=${Limit}`;
    } else {
      url = baseUrl + `nft/collected?limit=${Limit}`;
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': accessToken
    };

    return ApiCallGet(url, headers);
  },

  getReferralsList: async () => {
    const accessToken = localStorage.getItem("accessToken");
    const { baseUser, referrals } = ApiConfig;
    const url = baseUser + referrals;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': accessToken
    };
    return ApiCallGet(url, headers);
  },


  getAllPlayrsList: async (matchId, SeriesId) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUrlMatch } = ApiConfig;
    const url = baseUrlMatch + `all-players/${matchId}`;
    const params = {
      cid: SeriesId
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken
    };
    return ApiCallPost(url, params, headers);
  },

  myTeamDetails: async (matchId) => {
    const accessToken = localStorage.getItem("accessToken");
    const { baseUrlMatch } = ApiConfig;
    const url = baseUrlMatch + `my-teams/${matchId}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': accessToken
    };
    return ApiCallGet(url, headers);
  },

  createTeam: async (matchId, captionId, viceCID, playersId, playerMatchid, teamName) => {
    const accessToken = localStorage.getItem("accessToken");
    const { baseUrlMatch, CreateTeam } = ApiConfig;
    const url = baseUrlMatch + CreateTeam;
    const params = {
      match_id: matchId,
      caption: captionId,
      vice_caption: viceCID,
      pid: playersId,
      matchid: String(playerMatchid),
      name: teamName
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': accessToken
    };
    return ApiCallPost(url, params, headers);
  },

  getWinningDetails: async (matchId) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUrlMatch } = ApiConfig;
    const url = baseUrlMatch + `winner-prizes/${matchId}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallGet(url, headers);
  },

  getUpComingDetailsDetails: async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUrlMatch } = ApiConfig;
    const url = baseUrlMatch + 'list?status=Scheduled&limit=100&skip=0';
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallGet(url, headers);
  },

  getCompleteMatchDetails: async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUrlMatch } = ApiConfig;
    const url = baseUrlMatch + 'list?status=Completed&limit=100&skip=0';
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallGet(url, headers);
  },

  getLiveMatchDetails: async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUrlMatch } = ApiConfig;
    const url = baseUrlMatch + 'list?status=Live&limit=100&skip=0';
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallGet(url, headers);
  },


  joinContest: async (match_id, matchid, contest_category_id, teams_id, match_contest_category_id, amount) => {
    const accessToken = localStorage.getItem("accessToken");
    const { baseUrlMatch, contestJoin } = ApiConfig;
    const url = baseUrlMatch + contestJoin;
    const params = {
      match_id: match_id,
      matchid: matchid,
      contest_category_id: contest_category_id,
      teams_id: [teams_id],
      match_contest_category_id: match_contest_category_id,
      amount: amount,
      method: "ibat",
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': accessToken
    };
    return ApiCallPost(url, params, headers);
  },

  getIBatBalanceProfile: async (address) => {
    const accessToken = localStorage.getItem("accessToken");
    const { baseUrlWallet, iBatBalance } = ApiConfig;
    const url = baseUrlWallet + iBatBalance;
    const params = {
      address
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': accessToken
    };
    return ApiCallPost(url, params, headers);
  },


  getBnbBalance: async (address) => {
    const accessToken = localStorage.getItem("accessToken");
    const { baseUrlWallet, bnbBalance } = ApiConfig;
    const url = baseUrlWallet + bnbBalance;
    const params = {
      address
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': accessToken
    };
    return ApiCallPost(url, params, headers);
  },

  getKycDetails: async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUser, kycDetail } = ApiConfig;
    const url = baseUser + kycDetail;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallGet(url, headers);
  },


  matchReminder: async (match_id, matchId, SeriesId, checkOne, checkTwo) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUser, matchReminder } = ApiConfig;
    const url = baseUser + matchReminder;
    const params = {
      match_id: match_id,
      matchid: matchId,
      cid: SeriesId,
      allowed: checkOne,
      allowed: checkTwo
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallPost(url, params, headers);
  },


  playerDetils: async (matchId, playerId) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUrlMatch, playerProfile } = ApiConfig;
    const url = baseUrlMatch + playerProfile;
    const params = {
      match_id: matchId,
      pid: playerId,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallPost(url, params, headers);
  },

  playerInfo: async (playerId, type) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUrlMatch, playerDetail } = ApiConfig;
    const url = baseUrlMatch + playerDetail + '/'+ playerId +'?type='+type;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken,
    };
    return ApiCallGet(url, headers);
  },


  editTeam: async (teamId, matchId, captionId, viceCID, playersId, playerMatchid, teamName) => {
    const accessToken = localStorage.getItem("accessToken");
    const { baseUrlMatch, EditTeam } = ApiConfig;
    const url = baseUrlMatch + EditTeam;
    const params = {
      team_id: teamId,
      match_id: matchId,
      caption: captionId,
      vice_caption: viceCID,
      pid: playersId,
      matchid: String(playerMatchid),
      name: teamName
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': accessToken
    };
    return ApiCallPut(url, params, headers);
  },

  sendWalletAddress: async (address) => {
    const accessToken = localStorage.getItem("accessToken");
    const { baseUser, updateAddress } = ApiConfig;
    const url = baseUser + updateAddress;
    const params = {
      address
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': accessToken,
    };
    return ApiCallPost(url, params, headers);
  },


  sendUpdatedPassword: async (password) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUrl, createWallet } = ApiConfig;
    const url = baseUrl + createWallet;
    const params = {
      password: password
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken
    };
    return ApiCallPost(url, params, headers);
  },

  sendUpdatedWallet: async (walletAddress, walletEncrypt) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { baseUser, updateAddress } = ApiConfig;
    const url = baseUser + updateAddress;
    const params = {
      address: walletAddress,
      wallet_data: walletEncrypt,
      type: "centralized"
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken
    };
    return ApiCallPost(url, params, headers);
  },

  delterAccount: async (payload) => {
    const { baseUser, deleteUser } = ApiConfig;
    const refreshToken = localStorage.getItem("refreshToken");
    const url = baseUser + deleteUser;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': refreshToken
    };
    return ApiCallPost(url, payload, headers);
  },


}

export default AuthService;