
import React, { useContext, useState } from "react";
import RightViewContent from "../../customComponent/RightViewContent";
import { useNavigate } from "react-router-dom";
import { alertErrorMessage } from "../../customComponent/CustomAlertMessage";
import AuthService from "../../AuthService";
import { ProfileContext } from "../../context/ProfileProvider";


const CreateWallet = (props) => {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const { handleUserProfile } = useContext(ProfileContext);

    const handleBackProfile = () => {
        navigate('/my_balance');
    }

    const handleUpdatePassword = async (password) => {
        if (!password) {
            alertErrorMessage('Please Enter Password')
        } else {
            await AuthService.sendUpdatedPassword(password).then(async result => {
                if (result.success) {
                    try {
                        if (result?.message === "Wallet Already Exists") {
                            alertErrorMessage(result.message);
                        } else {
                            handleupdatedAddres(result);
                        }
                    } catch (error) {
                        alertErrorMessage(result.message);
                    }
                } else {
                    alertErrorMessage(result.message);
                }
            });
        }
    }

    const handleupdatedAddres = async (walletData) => {
        await AuthService.sendUpdatedWallet(walletData?.data, walletData?.encrypt).then(async result => {
            if (result.success) {
                try {
                    handleUserProfile();
                    navigate("/my_balance");
                } catch (error) {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg-gradient preview_wrapper edit_profile">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span className="hh_icon cursor-pointer" onClick={handleBackProfile}><i className="ri-arrow-left-s-line ri-xl"></i> </span>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className="">Create Wallet</h4>
                            </div>
                            <div className="col-3  text-end">
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0 home_scroll ">
                        <div className="container">
                            <form className="">
                                <div className="pay_card mb-3 ">
                                    <div className="field-box mb-3">
                                        <label>Enter Your Password</label>
                                        <input type="Password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                        <small>Please provide the password to decrypt your private key.</small>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="nav_footer nav_footer_transperant">
                    <div className="container">
                        <div className="row gx-2 justify-content-center">
                            <div className="col-12">
                                <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase" onClick={() => handleUpdatePassword(password)}>
                                    <span> Create Wallet </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default CreateWallet

