
import React from "react";
import { Link } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
const ManagePayments = () => {
    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg-gradient preview_wrapper edit_profile">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <Link to="/my_balance" className="hh_icon"><i className="ri-arrow-left-s-line ri-xl  "></i> </Link>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className="">Manage Payment</h4>
                            </div>
                            <div className="col-3  text-end">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default ManagePayments

