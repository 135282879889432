
import { useEffect, useState, useContext } from "react";
import RightViewContent from "../../customComponent/RightViewContent";
import { Link, useNavigate } from "react-router-dom";
import { ApiConfig } from "../../utils/apiConfig/apiConfig";
import AuthService from "../../AuthService/index";
import { alertErrorMessage, alertSuccessMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import { ProfileContext } from "../../context/ProfileProvider";

const EditProfile = (props) => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailId, setEmailId] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [gender, setGender] = useState("");
    const [logoImage, setLogoImage] = useState('');
    const [birthDate, setBirthDate] = useState("");
    const [userName, setUserName] = useState('');
    const [localLogoImage, setLocalLogoImage] = useState('');
    const {   handleUserProfile } = useContext(ProfileContext);

    useEffect(() => {
        setFirstName(props?.userDetails?.first_name);
        setLastName(props?.userDetails?.last_name);
        setEmailId(props?.userDetails?.email_or_phone);
        setLogoImage(props?.userDetails?.logo);
        setLocalLogoImage(props?.userDetails?.logo);
        setUserName(props?.userDetails?.username);
        setGender(props?.userDetails?.gender ? props?.userDetails?.gender : '');
        setBirthDate(props?.userDetails?.birthDate ? props?.userDetails?.birthDate : '');
        setPhoneNumber(props?.userDetails?.phone ? props?.userDetails?.phone : '');
    }, [props]);

    const handleUpdateLogoImages = async (logoImage) => {
        LoaderHelper.loaderStatus(true);
        var formData = new FormData();
        formData.append('file', logoImage);
        await AuthService.updateLogoImages(formData).then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setLocalLogoImage(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage('Image Not Upload');
            }
        });
    }

    const handleChangeLogoImage = async (event) => {
        event.preventDefault();
        const fileUploaded = event.target.files[0];
        const imgata = URL.createObjectURL(fileUploaded);
        setLogoImage(imgata);
        handleUpdateLogoImages(fileUploaded);
    }

    const handleUpdateProfile = async (userName, firstName, lastName, emailId, phoneNumber, gender, birthDate) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.updatePropfile(userName, firstName, lastName, emailId, phoneNumber, gender, birthDate, localLogoImage).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage(result.message);
                handleUserProfile();
                navigate('/profile');
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg-gradient preview_wrapper edit_profile">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <Link to="/profile" className="hh_icon"><i className="ri-arrow-left-s-line ri-xl  "></i> </Link>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className="">Edit Profile</h4>
                            </div>
                            <div className="col-3  text-end">
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0 ">
                        <div className="container">
                            <form className="">
                                <div className="avatar-upload">
                                    <div className="avatar-edit">
                                        <input type="file" id="imageUpload" name="myfile" onChange={handleChangeLogoImage} />
                                        <label htmlFor="imageUpload"><i className="ri-camera-line"></i></label>
                                    </div>
                                    <div className="avatar-preview">

                                        {localLogoImage ?
                                            <img src={`${ApiConfig.baseUrl + localLogoImage}`} />
                                            : logoImage ?
                                                <img src={`${ApiConfig.baseUrl + logoImage}`} />
                                                :
                                                <img src='assets/images/user.png' />
                                        }

                                    </div>
                                </div>
                                <div className="pay_card mb-3 profile_scroll ">
                                    <div className="pay_header">
                                        <div className="h6">Your Details</div>
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>User Name</label>
                                        <input type="text" className="form-control" placeholder="Enter User Name" name="userName" value={userName} onChange={(e) => setUserName(e.target.value)} />
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>First Name</label>
                                        <input type="text" className="form-control" placeholder="Enter First Name" name="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>Last Name</label>
                                        <input type="text" className="form-control" placeholder="Enter Last Name" name="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>Email Address</label>
                                        <input type="Email" className="form-control" placeholder="Enter Email Address" name="email" value={emailId} onChange={(e) => setEmailId(e.target.value)} />
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>Phone Number</label>
                                        <input type="text" className="form-control" placeholder="Enter Phone Number" name="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                    </div>
                                    <div className="field-box mb-3">
                                        <label> Select Genter </label>
                                        <div className="d-flex align-items-center">
                                            <div className="form-check ps-0 me-5 d-flex align-items-center">
                                                <input className="form-check-input" type="radio" name="gender" value="male" id="male" onChange={(e) => setGender(e.target.value)} checked={gender === 'male'} />
                                                <label className="form-check-label  mb-0" htmlFor="Male">
                                                    Male
                                                </label>
                                            </div>
                                            <div className="form-check  ps-0 d-flex align-items-center">
                                                <input className="form-check-input" type="radio" name="gender" value="female" id="female" onChange={(e) => setGender(e.target.value)} checked={gender === 'female'} />
                                                <label className="form-check-label mb-0" htmlFor="Female">
                                                    Female
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field-box mb-2">
                                        <label>Date of Birth</label>
                                        <input type="date" className="form-control" name="birthDate" value={birthDate} onChange={(e) => setBirthDate(e.target.value)} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="nav_footer nav_footer_transperant">
                    <div className="container">
                        <div className="row gx-2 justify-content-center">
                            <div className="col-12">
                                <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase"
                                    onClick={() => handleUpdateProfile(userName, firstName, lastName, emailId, phoneNumber, gender, birthDate)}>
                                    <span> Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default EditProfile

