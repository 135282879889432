
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import AuthService from "../../AuthService";
import { alertErrorMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import Countdown from "../../customComponent/CountDown";
import useWebSocket from "react-use-websocket";
import { ApiConfig } from "../../utils/apiConfig/apiConfig";
import { $ } from "react-jquery-plugin";

const WinningLeaderBoard = (prop) => {
    const navigate = useNavigate();
    const { state } = useLocation();

    console.log(state, '-----------');
    const [winningDetails, setWinnignDetails] = useState([]);
    const [leaderBoardList, setLeaderBoardList] = useState([]);
    const [entryFee, setEntryFee] = useState([]);
    const [myTeamsDetails, setMyTeamsDetails] = useState([]);

    const handleBack = () => {
        navigate('/match_single', { state: state?.state });
    }

    useEffect(() => {
        handleWinningsDetails();
        handleMyTeams();
    }, []);

    const handleWinningsDetails = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getWinningDetails(state?.winingItem?.contest_category_id).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setWinnignDetails(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }

    const { lastJsonMessage } = useWebSocket(
        `wss://api.ibatfantasysports.com/leader-board?limit=10&skip=0&matchid=${state?.MatchId}&contest_category_id=${state?.winingItem?.contest_category_id}&user_id=${prop?.userDetails?._id}`,
        {
            shouldReconnect: (closeEvent) => true,
        }
    );


    useEffect(() => {
        if (lastJsonMessage) {
            LoaderHelper.loaderStatus(false);
            setLeaderBoardList(lastJsonMessage);
        } else {
            LoaderHelper.loaderStatus(true);
        }
    }, [lastJsonMessage]);

    const handleMyTeams = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getMyTeamsDetails(state?.state?._id).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setMyTeamsDetails(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }


    const handleCreateTeam = () => {
        setEntryFee(state?.EnteryFee)
        if (state?.EnteryFee > prop?.userDetails?.ibat_balance) {
            $("#confirmationModal").modal('show');
        } else {
            $("#confirmationModal").modal('hide');
            if (myTeamsDetails.length === 0) {
                navigate('/create_team', { state: state });
            } else if (myTeamsDetails.length > 0) {
                navigate('/create_multiple_team', { state: state?.state });
            } else {
                navigate('/winning_leaderboard', { state: state });
            }
        }
    }

    const handleAddBalance = () => {
        const combinedData = {
            ...state,
            ...entryFee
        };
        $("#confirmationModal").modal('hide');
        navigate('/add_money', { state: combinedData });
    }


    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg_img">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span className="hh_icon cursor-pointer" onClick={handleBack}><i className="ri-arrow-left-s-line ri-xl  "></i> </span>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className=""><Countdown targetTime={state?.state?.StartDateTime} /></h4>
                            </div>
                            <div className="col-3  text-end">
                                <div className="hh_row">
                                    <Link to="/my_balance" className="hh_icon"> <i className="ri-wallet-3-fill"></i> </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main_panel match_details winning_leaderboard px-0">
                        <div className="play_card">
                            <div className="leauge_name"> <span>VS</span> </div>
                            <span className="row g-0 team_row">
                                <div className="col-6">
                                    <div className="team_card team_left team_left_mi">
                                        <div className="team_tcd "><span>{state?.state?.TeamA}</span> </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="team_card team_right team_right_SRH">
                                        <div className="team_tcd "><span>{state?.state?.TeamB}</span></div>
                                    </div>
                                </div>
                            </span>
                            <div className="filter_footer p-0 play_footer">
                                <div className="mc_card w-100 mb-0">
                                    <div className="mc_body row">
                                        <div className="mc_left col-8">
                                            <span>PRIZE POOL</span>
                                            <div className="d-flex mt-2 align-items-center">
                                                <h3 className="mb-0">${state?.winingItem?.WinningAmount ? state?.winingItem?.WinningAmount : state?.winingItem?.data?.WinningAmount}</h3>
                                                {/* <span className="ms-3">60% Winners l 1st $1,100</span> */}
                                            </div>
                                        </div>
                                        <div className="mc_right  col-4 text-end">
                                            {state?.winingItem?.JoinWithMULT == true ? <span>Multiple Entries</span> : <span>Singal Entries</span>}
                                            <span className="mc_rate btn btn-sm btn-success ms-auto cursor-pointer" onClick={() => handleCreateTeam()}>
                                                ${state?.winingItem?.EnteryFee? state?.winingItem?.EnteryFee : state?.winingItem?.data?.EnteryFee}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mc_progressbar">
                                        <div className="bar" style={{ width: '70%' }}></div>
                                    </div>
                                    <div className="progress_data">
                                        <span>{state?.winingItem?.Contestsize ? state?.winingItem?.Contestsize :  state?.winingItem?.data?.Contestsize} spots</span>
                                        <span className="text-success">{state?.winingItem?.Contestsize - state?.winingItem?.joined ? state?.winingItem?.Contestsize - state?.winingItem?.joined  : state?.winingItem?.data?.Contestsize - state?.winingItem?.joined_with } spots left</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="detals_wrapper">
                            <div className="container">
                                <ul className="details_tab btn-gradient border-gradient nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item nav-item-half" role="presentation">
                                        <button className="btn nav-link active" id="Winnings-tab" data-bs-toggle="tab" data-bs-target="#Winnings"
                                            type="button" role="tab" aria-controls="Winnings" aria-selected="true"><span>Winnings</span>
                                        </button>
                                    </li>
                                    <li className="nav-item nav-item-half" role="presentation">
                                        <button className="btn nav-link" id="Leaderboard-tab" data-bs-toggle="tab" data-bs-target="#Leaderboard"
                                            type="button" role="tab" aria-controls="Leaderboard" aria-selected="false"> <span>Leaderboard</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>

                            <div className="tab-content" id="myTabContent">

                                <div className="tab-pane show active" id="Winnings" role="tabpanel" aria-labelledby="Winnings-tab">
                                    <div className="team_info">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-6 text-start">
                                                    <span>RANK</span>
                                                </div>
                                                <div className="col-6 text-end">
                                                    <span>WINNINGS</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="rank_list rank_scroll">
                                        {winningDetails.length > 0 ?
                                            winningDetails.map((item, index) => (
                                                <li key={index}>
                                                    <h5 className="rank-item">
                                                        {item?.StartRank}-{item?.EndRank}
                                                    </h5>
                                                    <span>${item?.Price}</span>
                                                </li>
                                            )) : <div className="no_data"><span>No Data Found</span></div>}
                                    </ul>
                                </div>

                                <div className="tab-pane" id="Leaderboard" role="tabpanel" aria-labelledby="Leaderboard-tab">
                                    <div className="team_info">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-6 text-start">
                                                    <span>ALL TEAMS <span className="text-white"> ({leaderBoardList.length})</span></span>
                                                </div>
                                                <div className="col-6 text-end">
                                                    <span>RANK</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rank_list rank_scroll">
                                        {leaderBoardList.length > 0 ?
                                            leaderBoardList.map((item, index) => (
                                                <div className="player_info" key={index}>
                                                    <div className="container" >
                                                        <div class="row align-items-center">
                                                            <div class="col-2">
                                                                <img src={`${ApiConfig.baseUrl + item?.cover_photo}`} onError={(e) => { e.target.src = '/assets/images/user.png'; }} width="50" height="50" class="player_img w-100 rounded_img" />
                                                            </div>
                                                            <div class="col-8">
                                                                <span class="player_in_title"> <h6 className="mb-0" >{item?.username}({item?.total_points})</h6>
                                                                    <em class="">{item?.team_details?.name}</em> </span>
                                                            </div>
                                                            <div class="col-2 text-center">
                                                                <h5 class="mb-0">{++index}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : <div className="no_data"><span>No Data Found</span></div>}

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/*confirmationModal Model */}
                <div className="modal filter_modal" id="confirmationModal" tabIndex="-1" aria-labelledby="notificationsModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center" id="notificationsModalLabel"> Confirmation</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <ul className="pay_list">
                                    <li className="main_pay">
                                        <strong>Entry</strong>
                                        <span>{entryFee?.EnteryFee}</span>
                                    </li>
                                    <li className="">
                                        <p>Current IBAT Balance</p>
                                        <span>{prop?.userDetails?.ibat_balance}</span>
                                    </li>
                                    <li className="">
                                        <p>Usable Cash Bonus</p>
                                        <span>{prop?.userDetails?.cash_bonus}</span>
                                    </li>
                                    <li className="total_pay">
                                        <p>To Pay IBAT</p>
                                        <p>{entryFee?.EnteryFee}</p>
                                    </li>
                                </ul>

                            </div>
                            <div className="modal-footer d-block">
                                <div className="row gx-2 justify-content-center">
                                    <div className="col-6">
                                        <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-block w-100" onClick={handleAddBalance}>Add Money</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <RightViewContent />
        </div>
    )
}

export default WinningLeaderBoard

