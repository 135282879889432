
import React from "react";
import RightViewContent from "../../customComponent/RightViewContent";
import { useNavigate, useLocation, Link } from "react-router-dom";


const Withdraw = (prop) => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const handleBackProfile = () => {
        navigate('/my_balance', { state: state });
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                {/* <!-- main screen --> */}
                <div className="main_wrapper bg-gradient preview_wrapper edit_profile">
                    {/* <!-- header part --> */}
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span className="hh_icon cursor-pointer" onClick={handleBackProfile}><i className="ri-arrow-left-s-line ri-xl"></i> </span>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className="">Withdrawal IBAT</h4>
                            </div>
                            <div className="col-3  text-end">
                            </div>
                        </div>
                    </div>
                    {/* <!-- header part --> */}
                    <div className="main_panel px-0 home_scroll ">
                        <div className="container">
                            <form className="">
                                <div className="po_row">
                                </div>
                                <div className="pay_card mb-3 ">
                                    <div className="pay_header d-flex align-items-center justify-content-between">
                                        <div className="h6">Your winnings</div>
                                        <div className="h6">${prop?.userDetails?.winning_amount}</div>
                                    </div>
                                    <label className="card-radio-btn mb-3">
                                        <input type="radio" name="bitcoin" className="card-input-element d-nonecard-input-element d-none" id="bank" />
                                        <div className="po_card pointer">
                                            <div className="d-flex align-items">
                                                <div className="po_icon">
                                                    <i className="ri-bank-line ri-xl"></i>
                                                </div>
                                                <div className="po_txt ps-3">
                                                    <h5>{state?.bank_details?.BankName}</h5>
                                                    <small>A/C {state?.bank_details?.AccountNumber}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                    <div className="field-box mb-3">
                                        <label>Amount</label>
                                        <input type="text" className="form-control" placeholder="EnterAmount" />
                                        <small>Min. $50 & Max. $1,00,000 allowed per day.</small>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="nav_footer nav_footer_transperant">
                    <div className="container">
                        <div className="row gx-2 justify-content-center">
                            <div className="col-12">
                                <button /* to="/my_balance" */ type="button" className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase" disabled>
                                    <span> withdraw</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- right view --> */}
            <RightViewContent />
            {/* <!-- right view end --> */}
        </div>
    )
}

export default Withdraw

