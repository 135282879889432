import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate,HashRouter } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import HomePage from "../pages/HomePage";
import SingleMatch from "../pages/SingleMatch";
import AllPracticeContest from "../pages/AllPracticeContest";
import CreateTeam from "../pages/CreateTeam";
import Contest from "../pages/Contest";
import Profile from "../pages/Profile";
import Settings from "../pages/Settings";
import MyBalance from "../pages/MyBalance";
import DepositIbat from "../pages/DepositIbat";
import EditProfile from "../pages/EditProfile";
import WithdrawIbat from "../pages/WithdrawIbat";
import Withdraw from "../pages/Withdraw";
import TransactionHistory from "../pages/TransactionHistory";
import KycDetails from "../pages/KycDetails";
import VerifyEmail from "../pages/VerifyEmail";
import VerifyPanCard from "../pages/VerifyPanCard";
import VerifyBankAccount from "../pages/VerifyBankAccount";
import ManagePayments from "../pages/ManagePayments";
import CaptionShipDtails from "../pages/CaptionShipDtails";
import PlayerProfile from "../pages/PlayerProfile";
import PreviewTeam from "../pages/PreviewTeam";
import AddMoney from "../pages/AddMoney";
import PaymentOptions from "../pages/PaymentOptions";
import PayWallet from "../pages/PayWallet";
import MyNfts from "../pages/MyNfts";
import Invite from "../pages/Invite";
import TermsCondition from "../pages/TermsCondition";
import WinningLeaderBoard from "../pages/WinningLeaderBoard";
import SelectPlayers from "../pages/SelectPlayers";
import Register from "../pages/Register";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import VerifyMobile from "../pages/VerifyMobile";
import { ProfileContext } from "../context/ProfileProvider";
import CreateContest from "../pages/CreateContest";
import CreateMultipuleTeam from "../pages/CreateMultipuleTeam";
import MyContestDetails from "../pages/MyContestDetails";
import CompleteLeaderBoard from "../pages/CompleteLeaderBoard";
import RefrelPage from "../pages/RefrelPage";
import ViewTeam from "../pages/ViewTeam";
import CreateWallet from "../pages/CreateWallet";
import PaymentScreen from "../pages/PaymentScreen";
import LeaderBoardTeam from "../pages/LeaderBoardTeam";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ResponsiblePlay from "../pages/ResponsiblePlay";

const Routing = () => {

  const refreshToken = localStorage.getItem("refreshToken");
  const accessToken = localStorage.getItem("accessToken");

  const { userDetails, userActivity } = useContext(ProfileContext);

  return (
    <>
      <HashRouter>
        <Routes>
          <Route exact path="/" element={accessToken ? (<Navigate to="/home" replace />) : <LandingPage />}></Route>
          <Route path="/login" element={accessToken ? (<Navigate to="/home" replace />) : (<Login />)} />
          <Route exact path="/register" element={<Register />}></Route>
          <Route exact path="/forgotpassword" element={<ForgotPassword />}></Route>

          <Route exact path="/home" element={accessToken ? (<HomePage userDetails={userDetails} />) : <Navigate to="/" replace />} ></Route>

          <Route exact path="/match_single" element={<SingleMatch userDetails={userDetails} />}></Route>
          <Route exact path="/all_practice_contest" element={<AllPracticeContest />}></Route>
          <Route exact path="/create_team" element={<CreateTeam />}></Route>
          <Route exact path="/contest" element={<Contest userDetails={userDetails} />}></Route>
          <Route exact path="/profile" element={<Profile userDetails={userDetails} userActivity={userActivity} />}></Route>
          <Route exact path="/settings" element={<Settings userDetails={userDetails} />}></Route>
          <Route exact path="/my_balance" element={<MyBalance userDetails={userDetails} />}></Route>
          <Route exact path="/deposit" element={<DepositIbat userDetails={userDetails} />}></Route>
          <Route exact path="/edit_profile" element={<EditProfile userDetails={userDetails} />}></Route>
          <Route exact path="/withdraw_ibat" element={<WithdrawIbat />}></Route>
          <Route exact path="/withdraw" element={<Withdraw userDetails={userDetails} />}></Route>
          <Route exact path="/transtion_history" element={<TransactionHistory />}></Route>
          <Route exact path="/kyc_details" element={<KycDetails userDetails={userDetails} />}></Route>
          <Route exact path="/verify_email" element={<VerifyEmail />}></Route>
          <Route exact path="/verify_mobile" element={<VerifyMobile />}></Route>
          <Route exact path="/verify_pan" element={<VerifyPanCard />}></Route>
          <Route exact path="/verify_bank" element={<VerifyBankAccount />}></Route>
          <Route exact path="/manage_payments" element={<ManagePayments />}></Route>
          <Route exact path="/cc_vc" element={<CaptionShipDtails />}></Route>
          <Route exact path="/player_profile" element={<PlayerProfile />}></Route>
          <Route exact path="/preview_team" element={<PreviewTeam userDetails={userDetails} />}></Route>
          <Route exact path="/add_money" element={<AddMoney userDetails={userDetails} />}></Route>
          <Route exact path="/payment_options" element={<PaymentOptions />}></Route>
          <Route exact path="/pay_wallet" element={<PayWallet userDetails={userDetails} />}></Route>
          <Route exact path="/my_nft" element={<MyNfts />}></Route>
          <Route exact path="/invite" element={<Invite />}></Route>
          <Route exact path="/terms_condition" element={<TermsCondition />}></Route>
          <Route exact path="/winning_leaderboard" element={<WinningLeaderBoard userDetails={userDetails} />}></Route>
          <Route exact path="/select_players" element={<SelectPlayers />}></Route>
          <Route exact path="/create_contest" element={<CreateContest userDetails={userDetails} />}></Route>
          <Route exact path="/create_multiple_team" element={<CreateMultipuleTeam userDetails={userDetails} />}></Route>
          <Route exact path="/contest_details" element={<MyContestDetails />}></Route>
          <Route exact path="/leaderboard_complete" element={<CompleteLeaderBoard userDetails={userDetails}/>}></Route>
          <Route exact path="/referral" element={<RefrelPage />}></Route>
          <Route exact path="/view_created_team" element={<ViewTeam />}></Route>
          <Route exact path="/create_wallet" element={<CreateWallet />}></Route>
          <Route exact path="/payment" element={<PaymentScreen userDetails={userDetails}/>}></Route>
          <Route exact path="/leaderboard_team" element={<LeaderBoardTeam userDetails={userDetails} />}></Route>
          <Route exact path="/privacy_policy" element={<PrivacyPolicy />}></Route>
          <Route exact path="/responsiblegameplay" element={<ResponsiblePlay />}></Route>
        {/*   <Route path="*" element={<LandingPage />} /> */}
        </Routes>
      </HashRouter>
    </>
  );
}

export default Routing;