import React, { useEffect, createContext, useState } from 'react';
import AuthService from '../../AuthService';

export const ProfileContext = createContext();

export function ProfileProvider(props) {
  const [userDetails, setUserDetails] = useState([]);
  const refreshToken = localStorage.getItem("refreshToken");
  const accessToken = localStorage.getItem("accessToken");
  const [loginDetails, setLoginDetails] = useState({});
  const [userActivity, setUserActivity] = useState({});

  useEffect(() => {
    if (accessToken && refreshToken) {
      handleUserProfile();
    }
  }, []);

  const handleUserProfile = async () => {
    try {
      const result = await AuthService.getUserDetails();
      if (result.success) {
        setUserDetails(result.data);
        if (result.activity) {
          setUserActivity(result.activity);
        }
      } else {
        if (result.message === 'jwt expired') {
          handleRefreshToken();
        } else {
          handleLogOut();
        }
      }
    } catch (error) {
      // Handle error here
    }
  };

  const handleRefreshToken = async () => {
    try {
      const result = await AuthService.refreshTokenData();
      if (result.success) {
        localStorage.setItem('accessToken', result.data.accessToken);
        localStorage.setItem('refreshToken', result.data.refreshToken);
        setLoginDetails(result.data);
        handleUserProfile();
      } else {
        handleLogOut();
      }
    } catch (error) {
      // Handle error here
    }
  };

  const handleLogOut = () => {
    localStorage.clear();
    window.location.reload();
    window.location.href = "/";

  };

  useEffect(() => {
    
    if (accessToken && refreshToken) {
      handleUserProfile();
    }
  }, [accessToken,refreshToken]);

  return (
    
      <ProfileContext.Provider value={{ userDetails, setUserDetails, handleUserProfile, setLoginDetails, loginDetails, userActivity}}>
        {props.children}
      </ProfileContext.Provider>
  );
}
