import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import { alertErrorMessage } from "../../customComponent/CustomAlertMessage";
import Countdown from "../../customComponent/CountDown";

const SelectPlayers = () => {

    const { state } = useLocation();
    const selectedPlayers = state?.selectedPlayers
    const navigate = useNavigate();
    const [selectedWkCount, setSelectedWkCount] = useState(0);
    const [selectedBOWLCount, setSelectedBOWLCount] = useState(0);
    const [selectedBATCount, setSelectedBATCount] = useState(0);
    const [selectedARCount, setSelectedARCount] = useState(0);

    const countRoles = selectedPlayers.reduce((acc, cur) => {
        acc[cur.playing_role] = (acc[cur.playing_role] || 0) + 1;
        return acc;
    }, {});

    useEffect(() => {
        if (countRoles['wk']) {
            setSelectedWkCount(countRoles['wk']);
        }
    }, [countRoles]);
    useEffect(() => {
        if (countRoles['all']) {
            setSelectedARCount(countRoles['all']);

        }
    }, [countRoles]);

    useEffect(() => {
        if (countRoles['bat']) {
            setSelectedBATCount(countRoles['bat']);

        }
    }, [countRoles]);

    useEffect(() => {
        if (countRoles['bowl']) {
            setSelectedBOWLCount(countRoles['bowl']);
        }
    }, [countRoles]);

    const handleBackCreateTeam = () => {
        navigate('/create_team', { state: state });
    }

    const handleCCc = () => {
        if (selectedPlayers.length < 11) {
            alertErrorMessage('Please Select Minimum 11th Player')
        } else {
            navigate('/cc_vc', { state: state });
        }
    }

    const totalRatingCount = selectedPlayers.reduce((acc, curr) => acc + curr.fantasy_player_rating, 0);

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg_img preview_wrapper">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span className="hh_icon cursor-pointer" onClick={() => handleBackCreateTeam()}><i className="ri-arrow-left-s-line ri-xl"></i> </span>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className=""><Countdown targetTime={state?.StartDateTime} /></h4>
                            </div>
                        </div>
                    </div>
                    <div className="main_panel match_details px-0">
                        <div className="play_card wbab_card mb-0">
                            <div className="leauge_name"> <span>{state?.SeriesName}</span> </div>
                            <div className="play_card_center"><span className="matcs_status">VS</span> </div>
                            <span className="row g-0 team_row">
                                <div className="col-6">
                                    <div className="team_card team_left team_left_DC">
                                        <h4>{state?.TeamsShortNames[0]}</h4>
                                        <div className="team_tcd"><span>{state?.TeamA}</span><img src={state?.TeamAlogo} /></div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="team_card team_right team_right_SRH">
                                        <h4>{state?.TeamsShortNames[1]}</h4>
                                        <div className="team_tcd "><span>{state?.TeamB}</span>
                                            <img src={state?.TeamBlogo} />
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <div className="play_footer wbab_footer">
                                <span className="text-start">
                                    <em>{selectedPlayers?.length}/11</em>
                                    <small>Selection</small>
                                </span>
                                <div className="team_badgs text-center">
                                    {[...Array(11)].map((_, index) => (
                                        <span key={index}>
                                            {selectedPlayers.length > index && (
                                                <>
                                                    {selectedPlayers[index].playing_role === "bowl" && (
                                                        <img className="active" src="assets/images/bowl.png" alt="" />
                                                    )}
                                                    {selectedPlayers[index].playing_role === "wk" && (
                                                        <img className="active" src="assets/images/wk.png" alt="" />
                                                    )}
                                                    {selectedPlayers[index].playing_role === "bat" && (
                                                        <img className="active" src="assets/images/bat.png" alt="" />
                                                    )}
                                                    {selectedPlayers[index].playing_role === "all" && (
                                                        <img className="active" src="assets/images/AR.png" alt="" />
                                                    )}
                                                </>
                                            )}
                                        </span>
                                    ))}
                                </div>
                                <span className="text-end">
                                    <em>{totalRatingCount == '0' ? '100' : 100 - totalRatingCount}</em>
                                    <small>Credit</small>
                                </span>
                            </div>
                        </div>
                        <div className="team_preview ">
                            <div className="players_row">
                                <h5>WICKET KEEPERS ({selectedWkCount})</h5>
                                {
                                    selectedWkCount == 0 ?
                                        <div className="team_bar">
                                            <div className="team_bar">
                                                <span className="player_ico cursor-pointer" onClick={handleBackCreateTeam}>
                                                    <img src="assets/images/player_mockup.svg" width="70" height="89" />
                                                </span>
                                            </div>
                                        </div>
                                        :
                                        <div className="team_bar">
                                            {selectedPlayers.length > 0 &&
                                                selectedPlayers.map((item, index) => {
                                                    return item.playing_role === "wk" ? (
                                                        <div className="player_ico" key={index}>

                                                            {
                                                                item?.vice_caption === true ?
                                                                    <span className="player_badge">VC</span>
                                                                    : item?.caption === true ?
                                                                        <span className="player_badge">C</span> : ''
                                                            }

                                                            <img src="assets/images/player_1.png" width="55" height="67" />
                                                            <div className="player_name">{item?.first_name}</div>
                                                        </div>
                                                    ) : null;
                                                })
                                            }
                                        </div>
                                }
                            </div>
                            <div className="players_row">
                                <h5>BATSMEN ({selectedBATCount})</h5>
                                {
                                    selectedBATCount == 0 ?
                                        <div className="team_bar">
                                            <div className="team_bar">
                                                <span className="player_ico cursor-pointer" onClick={handleBackCreateTeam}>
                                                    <img src="assets/images/player_mockup.svg" width="70" height="89" />
                                                </span>
                                            </div>
                                        </div>
                                        :
                                        <div className="team_bar">
                                            {selectedPlayers.length > 0 &&
                                                selectedPlayers.map((item, index) => {
                                                    return item.playing_role === "bat" ? (
                                                        <div className="player_ico" key={index}>
                                                            {
                                                                item?.vice_caption === true ?
                                                                    <span className="player_badge">VC</span>
                                                                    : item?.caption === true ?
                                                                        <span className="player_badge">C</span> : ''
                                                            }
                                                            <img src="assets/images/player_1.png" width="55" height="67" />
                                                            <div className="player_name">{item?.first_name}</div>
                                                        </div>
                                                    ) : null;
                                                })
                                            }
                                        </div>
                                }
                            </div>
                            <div className="players_row">
                                <h5>ALL ROUNDERS ({selectedARCount})</h5>
                                {
                                    selectedARCount == 0 ?
                                        <div className="team_bar">
                                            <div className="team_bar">
                                                <span className="player_ico cursor-pointer" onClick={handleBackCreateTeam}>
                                                    <img src="assets/images/player_mockup.svg" width="70" height="89" />
                                                </span>
                                            </div>
                                        </div>
                                        :
                                        <div className="team_bar">
                                            {selectedPlayers.length > 0 &&
                                                selectedPlayers.map((item, index) => {
                                                    return item.playing_role === "all" ? (
                                                        <div className="player_ico" key={index}>
                                                            {
                                                                item?.vice_caption === true ?
                                                                    <span className="player_badge">VC</span>
                                                                    : item?.caption === true ?
                                                                        <span className="player_badge">C</span> : ''
                                                            }
                                                            <img src="assets/images/player_1.png" width="55" height="67" />
                                                            <div className="player_name">{item?.first_name}</div>
                                                        </div>
                                                    ) : null;
                                                })
                                            }
                                        </div>
                                }
                            </div>
                            <div className="players_row">
                                <h5>BOWLERS ({selectedBOWLCount})</h5>
                                {
                                    selectedBOWLCount == 0 ?
                                        <div className="team_bar">
                                            <div className="team_bar">
                                                <span className="player_ico cursor-pointer" onClick={handleBackCreateTeam}>
                                                    <img src="assets/images/player_mockup.svg" width="70" height="89" />
                                                </span>
                                            </div>
                                        </div>
                                        :
                                        <div className="team_bar">
                                            {selectedPlayers.length > 0 &&
                                                selectedPlayers.map((item, index) => {
                                                    return item.playing_role === "bowl" ? (
                                                        <div className="player_ico" key={index}>
                                                            {
                                                                item?.vice_caption === true ?
                                                                    <span className="player_badge">VC</span>
                                                                    : item?.caption === true ?
                                                                        <span className="player_badge">C</span> : ''
                                                            }
                                                            <img src="assets/images/player_1.png" width="55" height="67" />
                                                            <div className="player_name">{item?.first_name}</div>
                                                        </div>
                                                    ) : null;
                                                })
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nav_footer nav_footer_transperant">
                    <div className="container">
                        <div className="row gx-2 justify-content-center">
                            <div className="col-6">
                                <button type="button" className="custom-btn btn-border-gradient  btn-block w-100 text-uppercase" onClick={() => handleBackCreateTeam()}>
                                    <span> Close</span>
                                </button>
                            </div>

                            <div className="col-6">
                                <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase" onClick={handleCCc}>
                                    <span> Continue</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default SelectPlayers

