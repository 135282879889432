
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import Countdown from "../../customComponent/CountDown";

const ViewTeam = (props) => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const teamDetails = state?.teamDetails[0]?.players

    const filteredData = teamDetails.filter(obj => obj.playing_role === 'wk');
    const wkLenght = filteredData.length;
    const filteredDatabat = teamDetails.filter(obj => obj.playing_role === 'bat');
    const batLenght = filteredDatabat.length;
    const filteredDataall = teamDetails.filter(obj => obj.playing_role === 'all');
    const allLenght = filteredDataall.length;
    const filteredDatabowl = teamDetails.filter(obj => obj.playing_role === 'bowl');
    const bowlLenght = filteredDatabowl.length;

    const totalRatingCount = teamDetails.reduce((acc, curr) => acc + curr.fantasy_player_rating, 0);

    const handleBack = () => {
        navigate('/create_multiple_team', { state: state });
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg_img preview_wrapper">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span className="hh_icon cursor-pointer" onClick={handleBack} ><i className="ri-close-line ri-xl"></i></span>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className=""><Countdown targetTime={state?.StartDateTime} /></h4>
                            </div>
                        </div>
                    </div>
                    <div className="main_panel match_details px-0">
                        <div className="play_card wbab_card mb-0">
                            <div className="wbab_title"> <small>{state?.SeriesName}</small> </div>
                            <span className="row g-0 team_row">
                                <div className="col-6">
                                    <div className="team_card team_left team_left_mi">
                                        <h4>{state?.TeamsShortNames[0]}</h4>
                                        <div className="team_tcd "><span>{state?.TeamA}</span>
                                            <img src={state?.TeamAlogo} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="team_card team_right team_right_SRH">
                                        <h4>{state?.TeamsShortNames[1]}</h4>
                                        <div className="team_tcd "><span>{state?.TeamB}</span>
                                            <img src={state?.TeamBlogo} />
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <div className="play_footer wbab_footer">
                                <span className="text-start">
                                    <em>{teamDetails.length}/11</em>
                                    <small>Selection</small>
                                </span>
                                <div className="team_badgs text-center">
                                    {[...Array(11)].map((_, index) => (
                                        <span key={index}>
                                            {teamDetails.length > index && (
                                                <>
                                                    {teamDetails[index].playing_role === "wk" && (
                                                        <img className="active" src="assets/images/wk.png" alt="" />
                                                    )}
                                                    {teamDetails[index].playing_role === "bat" && (
                                                        <img className="active" src="assets/images/bat.png" alt="" />
                                                    )}
                                                    {teamDetails[index].playing_role === "all" && (
                                                        <img className="active" src="assets/images/AR.png" alt="" />
                                                    )}
                                                    {teamDetails[index].playing_role === "bowl" && (
                                                        <img className="active" src="assets/images/bowl.png" alt="" />
                                                    )}
                                                </>
                                            )}
                                        </span>
                                    ))}

                                </div>
                                <span className="text-end">
                                    <em>{totalRatingCount}</em>
                                    <small>Credit</small>
                                </span>
                            </div>
                        </div>
                        <div className="team_preview ">
                            <div className="players_row">
                                <h5>WICKET KEEPERS ({wkLenght})</h5>
                                <div className="team_bar">
                                    {teamDetails.length > 0 &&
                                        teamDetails.map((item, index) => {
                                            return item.playing_role === "wk" ? (
                                                <div className="player_ico" key={index}>
                                                    {
                                                        item?.caption === true ?
                                                            <span className="player_badge">C</span>
                                                            : item?.vice_caption === true ?
                                                                <span className="player_badge">VC</span> : ''
                                                    }
                                                    <img src="assets/images/player_1.png" width="55" height="67" />
                                                    <div className="player_name">{item?.first_name}</div>
                                                </div>
                                            ) : null;
                                        })
                                    }
                                </div>
                            </div>
                            <div className="players_row">
                                <h5>BATSMEN ({batLenght})</h5>
                                <div className="team_bar">
                                    {teamDetails.length > 0 &&
                                        teamDetails.map((item, index) => {
                                            return item.playing_role === "bat" ? (
                                                <div className="player_ico" key={index}>
                                                    {
                                                        item?.caption === true ?
                                                            <span className="player_badge">C</span>
                                                            : item?.vice_caption === true ?
                                                                <span className="player_badge">VC</span> : ''
                                                    }
                                                    <img src="assets/images/player_1.png" width="55" height="67" />
                                                    <div className="player_name">{item?.first_name}</div>
                                                </div>
                                            ) : null;
                                        })
                                    }
                                </div>
                            </div>
                            <div className="players_row">
                                <h5>ALL ROUNDERS ({allLenght})</h5>
                                <div className="team_bar">
                                    {teamDetails.length > 0 &&
                                        teamDetails.map((item, index) => {
                                            return item.playing_role === "all" ? (
                                                <div className="player_ico" key={index}>
                                                    {
                                                        item?.caption === true ?
                                                            <span className="player_badge">C</span>
                                                            : item?.vice_caption === true ?
                                                                <span className="player_badge">VC</span> : ''
                                                    }
                                                    <img src="assets/images/player_1.png" width="55" height="67" />
                                                    <div className="player_name">{item?.first_name}</div>
                                                </div>
                                            ) : null;
                                        })
                                    }
                                </div>
                            </div>
                            <div className="players_row">
                                <h5>BOWLERS ({bowlLenght})</h5>
                                <div className="team_bar">
                                    {teamDetails.length > 0 &&
                                        teamDetails.map((item, index) => {
                                            return item.playing_role === "bowl" ? (
                                                <div className="player_ico" key={index}>
                                                    {
                                                        item?.caption === true ?
                                                            <span className="player_badge">C</span>
                                                            : item?.vice_caption === true ?
                                                                <span className="player_badge">VC</span> : ''
                                                    }
                                                    <img src="assets/images/player_1.png" width="55" height="67" />
                                                    <div className="player_name">{item?.first_name}</div>
                                                </div>
                                            ) : null;
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default ViewTeam

