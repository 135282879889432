import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import AuthService from "../../AuthService";
import { alertErrorMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import { ProfileContext } from "../../context/ProfileProvider";

const PayWallet = (props) => {

    const { state } = useLocation();
    const navigate = useNavigate();
    const [iBatBalance, setIBatBalance] = useState([]);
    const { handleUserProfile } = useContext(ProfileContext);
   let address = [] //metamaskAddress

    useEffect(() => {
        if (props?.userDetails?.wallet_address) {
            handleIBatBalance(props?.userDetails?.wallet_address);
        }
    }, [props?.userDetails?.wallet_address]);


    const handleBack = () => {
        navigate('/payment_options', { state: state });
    }

    const handleIBatBalance = async (walletAddress) => {
        await AuthService.getIBatBalanceProfile(walletAddress).then(async result => {
            if (result.success) {
                try {
                    setIBatBalance(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    }

    useEffect(() => {
        if (address) {
            handleWalletAddress();
        }
    }, [address, props?.userDetails?.wallet_address]);


    const handleWalletAddress = async () => {
        await AuthService.sendWalletAddress(address).then(async result => {
            if (result.success) {
                try {
                    handleUserProfile();
                } catch (error) {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    }


    const handleDepositIbat = (paywalletScreen) => {
        navigate('/deposit', { state: paywalletScreen });
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg-gradient preview_wrapper">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span className="hh_icon cursor-pointer" onClick={handleBack}><i className="ri-arrow-left-s-line ri-xl"></i></span>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className="">IBAT</h4>
                            </div>
                            <div className="col-3  text-end">
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0 home_scroll">
                        <div className="container">
                            <div className="po_row">
                                <div className="sc_title mb-2">
                                    <h5> Pay with IBAT </h5>
                                </div>
                                <label className="card-radio-btn mb-3">
                                    <input type="radio" name="bitcoin" className="card-input-element d-nonecard-input-element d-none" id="IBAT" checked />
                                    <div className="po_card pointer">
                                        <div className="d-flex align-items">
                                            <div className="po_icon">
                                                <img src="assets/images/referral_img.png" />
                                            </div>
                                            <div className="po_txt ps-3">
                                                <h5 className="text-success">{iBatBalance}</h5>
                                                <small>Pay with IBAT</small>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                                <div className="hr_max"></div>
                                {/* {props?.userDetails?.type === 'decentralized' ?
                                    <div className="po_card pointer p-3">
                                        <div className="w-100">
                                            <div className="sc_title ">
                                                <div>
                                                    <h4> Connect your wallet</h4>
                                                    <span>Connect your wallet to play game</span>
                                                </div>
                                            </div>
                                            <span className="custom-btn btn-white-smoke text-uppercase  btn-block w-100">
                                                <span><ConnectButton showBalance={false} label="CONNECT WALLET" /> </span>
                                            </span>
                                        </div>
                                    </div>
                                    : */}

                                <div className="po_card pointer p-3">
                                    <div className="w-100">
                                        <div className="sc_title ">
                                            <div>  <h4>Deposit IBAT & BNB</h4></div>
                                        </div>
                                        <button className="custom-btn btn-white-smoke text-uppercase  btn-block w-100"
                                            onClick={() => handleDepositIbat('paywalletScreen')}>
                                            <span>Deposit Wallet</span>
                                        </button>
                                    </div>
                                </div>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nav_footer nav_footer_transperant">
                    <div className="container">
                        <div className="row gx-2 justify-content-center">
                            <div className="col-12">
                                {
                                    iBatBalance === 0 ?
                                        <button className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase">
                                            <span>Insufficient Balance</span>
                                        </button>
                                        :
                                        <button className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase">
                                            <span> PAY</span>
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default PayWallet

