import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";

const PaymentOptions = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/add_money', { state: state });
    }

    const payWithWallet = () => {
        navigate('/pay_wallet', { state: state });
    }

    const payWithNft = (paymentscreen) => {
        navigate('/my_nft', { state: paymentscreen });
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg-gradient preview_wrapper">
                    {/* <!-- header part --> */}
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span className="hh_icon cursor-pointer" onClick={handleBack}><i className="ri-arrow-left-s-line ri-xl"></i> </span>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className="">Payment Options</h4>
                            </div>
                            <div className="col-3  text-end">
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0  home_scroll">
                        <div className="container">
                            <div className="pay_row">
                                <p className="mb-0">Add ${state?.EnteryFee} </p>                                
                            </div>
                            <div className="po_row">
                              
                                <div className="sc_title mb-2">
                                    <h5> Recommended </h5>
                                </div>
                                <span className="po_card pointer mb-2" onClick={payWithWallet}>
                                    <div className="d-flex align-items">
                                        <div className="po_icon">
                                            <img src="assets/images/ibat_img.png" />
                                        </div>
                                        <div className="po_txt ps-3">
                                            <h5 className="text-white">IBAT</h5>
                                        </div>
                                    </div>
                                </span>
                                <span className="po_card pointer" onClick={() => payWithNft('paymentscreen')}>
                                    <div className="d-flex align-items">
                                        <div className="po_icon">
                                            <img src="assets/images/nft_img.png" />
                                        </div>
                                        <div className="po_txt ps-3">
                                            <h5 className="text-white">NFT Passes</h5>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default PaymentOptions

