import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import { useGoogleLogin } from '@react-oauth/google';
import CommonCases from "../../Cases/CommonCases";
import axios from "axios";
import {deviceDetect, isAndroid } from "react-device-detect";


const LandingPage = () => {
    const { handleLoginRegisterWithGoogle, handleLoginRegisterWithTelegram } = CommonCases();
    const [telegramLoginCalled, setTelegramLoginCalled] = useState(true);
    const [isAndoirdDevice, setAndroifDevice] = useState(false);

    let locationHash = window.location.hash;
    locationHash = locationHash.replace('#/', '');
    const queryParams = new URLSearchParams(locationHash);
    // console.log(locationHash, '======queryParams======', queryParams);
    const id = queryParams.get("tele_grm_ID");
    const name = queryParams.get("f_name");
    // console.log(id,'======telegramid======', name);

    useEffect(() => {
        const device = deviceDetect();
        // const userAgent = device.userAgent+'';
        console.log(device, isAndroid);
        // const devicedata = getSelectorsByUserAgent(userAgent);
        // console.log("devicedata browserName", devicedata);

        if (isAndroid) {
            setAndroifDevice(true);
        }
    
    },[])
    
    useEffect(() => {
        if (id && name && telegramLoginCalled) {
            // console.log('telegram login=========', id, name, telegramLoginCalled);
            const payload = {
                signId: id,
                userName: id,
                firstName: name,
                lastName: ' ',
                password: ' ',
                checkButton: true,
                telegram_login: 1,
                device: 'telegram'
            };
            setTelegramLoginCalled(false)
            handleLoginRegisterWithTelegram(payload);

    
        }
    }, []);

    const registerWithGoogle = useGoogleLogin({
        onSuccess: async tokenResponse => {
            console.log('===log1==',tokenResponse);
            const config = {
                headers: { Authorization: `Bearer ${tokenResponse.access_token}` }
            };
            try {
                const response = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", config);
                const data = response.data;
                console.log(data);
                const names = data.name.split(" ");
                const payload = {
                    signId: data.email,
                    userName: data.email,
                    firstName: names[0],
                    lastName: names.length > 1 ? names[1] : '',
                    password: "1234567890",
                    checkButton: true,
                };
                handleLoginRegisterWithGoogle(payload);
            } catch(err) {
                console.log(err);
            }
            
            
        },
        onError: error => {
            console.log('Login Failed', error);
        }
    });
    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper login_wrapper">
                    <div className="container">
                        <div className="login_page">
                            <img src="assets/images/logo_login.svg" className="logo_img" />
                            <div className="welcome_row">
                                <h4 className="mb-5">Welcome to Battle Infinity</h4>
                                <Link to="/register"
                                    className="custom-btn btn-border-gradient btn-gradient btn-block w-100 mb-3"><span>Register</span></Link>
                                <Link to="/login" className="custom-btn btn-border-gradient  btn-block w-100 mb-3">Login</Link>

                                {isAndoirdDevice && 
                                    <Link target="_blank" to="https://play.google.com/store/apps/details?id=battleinfinity.fantasy.ibat.sports&pli=1" className="display-hide-cus download-btn-cus btn-block w-100 mb-3 ">
                                        Download Android App
                                    </Link>
                                }

                                <button onClick={registerWithGoogle} className="custom-btn btn-border-gradient mb-3 btn-gradient btn-block w-100"> 
                                    Signin with Google
                                </button>

                                <div className="have_referal d-flex align-items-center justify-content-between mt-5">
                                    <span>Have a referral code?</span>
                                    <Link to='/referral' className="">Enter Code</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default LandingPage

