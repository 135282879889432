import React from "react";
import Routing from "./Routing";
import { ProfileProvider } from "./context/ProfileProvider";
import Loading from "./customComponent/Loading";
import LoaderHelper from "./customComponent/Loading/LoaderHelper";
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <ProfileProvider>
      <Routing />
      <Loading ref={ref => LoaderHelper.setLoader(ref)} />
      <ToastContainer />
    </ProfileProvider>
  );
}

export default App;
