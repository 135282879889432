
import React, { useEffect, useState } from "react";
import RightViewContent from "../../customComponent/RightViewContent";
import { Link } from "react-router-dom";
import AuthService from "../../AuthService";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import { alertErrorMessage } from "../../customComponent/CustomAlertMessage";

const Invite = () => {

    const [isCopied, setIsCopied] = useState(false);
    const [referralsList, setReferralsList] = useState([]);

    function copyToClipboard(copyText) {
        navigator.clipboard.writeText(copyText);
        setIsCopied(true);
        // setTimeout(() => setIsCopied(false), 5000);
    }

    function handleShareTwitter() {
        const shareText = `Check out my code: ${referralsList?.refercode}!`;
        const shareUrl = "https://ibatfantasysports.com"; // Replace with the URL you want to share

        if (navigator.share) {
            navigator.share({
                title: document.title,
                text: shareText,
                url: shareUrl,
            });
        } else {
            // Fallback for browsers that do not support the Web Share API
            const shareWindow = window.open(
                `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                    shareText
                )}&url=${encodeURIComponent(shareUrl)}`,
                "_blank"
            );
            if (!shareWindow) {
                alert("Please disable your popup blocker to share the page.");
            }
        }
    }

    function handleShareFacebook() {
        const shareText = `Check out my code: ${referralsList?.refercode}!`;
        const shareUrl = `https://ibatfantasysports.com/share?code=${encodeURIComponent(shareText)}`;

        const shareWindow = window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareText)}`,
            '_blank'
        );
        if (!shareWindow) {
            alert('Please disable your popup blocker to share the page.');
        }
    }

    function handleShareWhatsApp() {
        const shareText = `Check out my code: ${referralsList?.refercode}!`;
        const shareUrl = `https://ibatfantasysports.com/share?code=${encodeURIComponent(referralsList?.refercode)}`;
      
        const encodedShareText = encodeURIComponent(shareText + ' ' + shareUrl);
        const shareLink = `https://web.whatsapp.com/send?text=${encodedShareText}`;
      
        const shareWindow = window.open(shareLink, '_blank');
        if (!shareWindow) {
          alert('Please disable your popup blocker to share the page.');
        }
      }
      

      function handleShareTelegram() {
        const shareText = `Check out my code: ${referralsList?.refercode}!`;
        const shareUrl = `https://ibatfantasysports.com/share?code=${encodeURIComponent(referralsList?.refercode)}`;
      
        const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`;
        window.open(telegramUrl, '_blank');
      }
      


    useEffect(() => {
        handleReferAndEarn();
    }, [])

    const handleReferAndEarn = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getReferralsList().then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setReferralsList(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg-gradient edit_profile all_scroll">
                    <div className="invite_wrapper">
                        <div className="header_wrapper">
                            <div className="row align-items-center">
                                <div className="col-3 text-start">
                                    <Link to="/settings" className="hh_icon"><i className="ri-arrow-left-s-line ri-xl  "></i> </Link>
                                </div>
                                <div className="col-6  text-center">
                                    <h4 className="">Refer & Earn</h4>
                                </div>
                                <div className="col-3  text-end">
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="invite_contr">
                                <h3 className="text-center">Refer your Friends </h3>
                                <img src="assets/images/invite_box.png" className="invite_bx" />
                                <p>For every friend that plays, you both get $50 for free</p>
                                <div className="input-group inv_input  ">
                                    <input type="text" className="form-control" defaultValue={referralsList?.refercode} readOnly />
                                    <button className="btn btn-copy" type="button" onClick={() => copyToClipboard(referralsList?.refercode)}>
                                        {isCopied ? 'Copied!' : 'Copy'}
                                    </button>
                                </div>
                            </div>
                            <div className="share_bxd">
                                <p className="mb-0">Share your Referral Code via</p>
                                <div className="share">
                                    <span className="item-facebook" onClick={handleShareFacebook}> <i className="ri-facebook-circle-fill"></i> </span>
                                    <span className="item-whatsapp" onClick={handleShareWhatsApp}> <i className="ri-whatsapp-fill"></i> </span>
                                    <span className="item-telegram" onClick={handleShareTelegram}> <i className="ri-telegram-fill"></i> </span>
                                    <span className="item-twitter" onClick={handleShareTwitter}> <i className="ri-twitter-fill"></i> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0 invite_panel">
                        <div className="container">
                            <div className="po_card pointer mb-2">
                                TOTAL REFFERALS <br /> <strong>{referralsList?.total_reffered}</strong>
                            </div>
                            <div className="po_card pointer mb-2">
                                TOTAL REWARDS <br /> <strong>${referralsList?.total_earned}</strong>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <RightViewContent />
        </div>
    )
}

export default Invite

