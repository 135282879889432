
import React, { useState } from "react";
import RightViewContent from "../../customComponent/RightViewContent";
import { Link, useNavigate } from "react-router-dom";
import { alertErrorMessage, alertSuccessMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import AuthService from "../../AuthService";

const WithdrawIbat = () => {
    const [walletAddress, setWalletAddress] = useState("");
    const [amount, setAmount] = useState("");
    const navigate = useNavigate();

    const handleWithdrawalIbat = async (walletAddress, amount) => {
        if (!walletAddress) {
            alertErrorMessage('Please Enter Wallet Address')
        } else if (!amount) {
            alertErrorMessage('Please Enter Amount')
        } 
        else {
            LoaderHelper.loaderStatus(true);
            await AuthService.withdrawalIbat(walletAddress, amount).then(async result => {
                if (result?.success) {
                    LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result?.message);
                    navigate("/my_balance");
                } else {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result?.message);
                }
            });
        }
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg-gradient preview_wrapper edit_profile">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <Link to="/my_balance" className="hh_icon"><i className="ri-arrow-left-s-line ri-xl  "></i> </Link>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className="">Withdrawal IBAT</h4>
                            </div>
                            <div className="col-3  text-end">
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0 home_scroll">
                        <div className="container">
                            <form className="">
                                <div className="pay_card mb-3 ">
                                    <div className="pay_header">
                                        <div className="h6">Withdraw</div>
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>Wallet Address</label>
                                        <input type="text" className="form-control" placeholder="Enter Wallet Address" value={walletAddress} onChange={(e) => setWalletAddress(e.target.value)} />
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>Amount</label>
                                        <input type="number" className="form-control" placeholder="Enter Your Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                                    </div>                                   
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="nav_footer nav_footer_transperant">
                    <div className="container">
                        <div className="row gx-2 justify-content-center">
                            <div className="col-12">
                                <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase" onClick={() => handleWithdrawalIbat(walletAddress, amount)}>
                                    <span> withdraw</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default WithdrawIbat

