
import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavigationFooter = () => {
    const location = useLocation();

    return (
        <div className="nav_footer">
            <Link className={"naf_f_link " + (location.pathname === "/home" ? "active" : "")} to="/home">
                <i className="ri-home-5-line"></i>
                <span>Home</span>
            </Link>
            <Link className={"naf_f_link " + (location.pathname === "/contest" ? "active" : "")} to="/contest">
                <i className="ri-trophy-line"></i>
                <span>My Contest</span>
            </Link>
            <Link className={"naf_f_link " + (location.pathname === "/profile" ? "active" : "")} to="/profile">
                <i className="ri-user-line"></i>
                <span>Profile</span>
            </Link>
            <Link className={"naf_f_link " + (location.pathname === "/settings" ? "active" : "")} to="/settings">
                <i className="ri-more-2-fill"></i>
                <span>More</span>
            </Link>
        </div>
    )
    
}

export default NavigationFooter

