
import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import Countdown from "../../customComponent/CountDown";

const CreateContest = (prop) => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/match_single', { state: state });
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg_img">
                    <div className="header_wrapper">
                        <div className="row align-items-center" >
                            <div className="col-3 text-start" >
                                <span className="hh_icon cursor-pointer" onClick={handleBack}><i className="ri-arrow-left-s-line ri-xl"></i> </span>
                            </div>
                            <div className="col-6  text-center" >
                                <h4 className="" ><Countdown targetTime={state?.StartDateTime} /></h4>
                            </div>
                            <div className="col-3  text-end" >
                                <div className="hh_row">
                                    <Link to="/my_balance" className="hh_icon"> <i className="ri-wallet-3-fill"></i> </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main_panel match_details px-0">
                        <div className="play_card">
                            <div className="leauge_name"> <span>{state?.SeriesName}</span> </div>
                            <div className="play_card_center"><span className="matcs_status"> VS</span> </div>
                            <span className="row g-0 team_row cursor-pointer">
                                <div className="col-6">
                                    <div className="team_card team_left team_left_mi">
                                        <h4>{state?.TeamsShortNames[0]}</h4>
                                        <div className="team_tcd "><span>{state?.TeamA}</span>
                                            <img src={state?.TeamAlogo} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="team_card team_right team_right_SRH">
                                        <h4>{state?.TeamsShortNames[1]}</h4>
                                        <div className="team_tcd "><span>{state?.TeamB}</span>
                                            <img src={state?.TeamBlogo} />
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <div className="detals_wrapper bal_scroll" >
                            <div className="container" >
                                <div className="sc_title">
                                    <h4>Create Contest</h4>
                                </div>
                                <div className="pay_card mb-2" >
                                    <div className="row" >
                                        <div className="col-12" >
                                            <div className="field-box mb-2" >
                                                <label>Contest Name</label>
                                                <input type="text" className="form-control" placeholder="Enter Contest Name" />
                                            </div>
                                        </div>
                                        <div className="col-6" >
                                            <div className="field-box mb-2" >
                                                <label>Contest Size</label>
                                                <input type="text" className="form-control" placeholder="Contest Size" />
                                            </div>
                                        </div>
                                        <div className="col-6" >
                                            <div className="field-box mb-2" >
                                                <label>Entery</label>
                                                <input type="text" className="form-control" placeholder="Enter Price" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pay_card p-2 ">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items">
                                            <div className="po_icon">
                                                <img src="assets/images/ibat_img.png" />
                                            </div>
                                            <div className="po_txt ps-3">
                                                <h5 className="text-white">Price Pool</h5>
                                            </div>
                                        </div>
                                        <div className="h5 mb-0 pe-3">150</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nav_footer" >
                    <div className="container" >
                        <div className="row gx-2" >
                            <div className="col-12" >
                                <button className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase">
                                    <span>Create contest</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default CreateContest

