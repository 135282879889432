import { toast } from 'react-toastify';

function alertErrorMessage(message) {

  toast.error(message, {
    position: toast.POSITION.TOP_LEFT,
    className: 'custom-toast',
  });
}

function alertSuccessMessage(message) {
  toast.success(message, {
    position: toast.POSITION.TOP_LEFT
  });
}

function alertwWarningMessage(message) {
  toast.warning(message, {
    position: toast.POSITION.BOTTOM_LEFT
  });
}

export { alertErrorMessage, alertSuccessMessage, alertwWarningMessage };

