
import React from "react";
import { Link } from "react-router-dom";

const RightViewContent = () => {
    return (
        <div className="right_view hight_full">
            {/* <div className="right_inner">
                <span className="logo_main">
                    <img src="assets/images/battle_login_xl.svg" />
                </span>
                <h5>Download our mobile app:</h5>
                <div className="btn_bar d-flex"> */}
                    {/* <span className="pll_btn cursor-pointer">
                        <em> Coming Soon </em>
                        <img src="assets/images/app_store_btn.png" />
                    </span> */}
                    {/* <span className="pll_btn cursor-pointer"> */}
                        {/* <em> Coming Soon </em> */}
                        {/* <Link target="_blank" to="https://play.google.com/store/apps/details?id=battleinfinity.fantasy.ibat.sports&pli=1" className="">
                        <img src="assets/images/app_store_btn-1.png" />
                        </Link> */}

                        
                    {/* </span>
                </div>
            </div> */}
        </div>
    )
}

export default RightViewContent

