
import React from "react";
import RightViewContent from "../../customComponent/RightViewContent";


const ResponsiblePlay = (props) => {



    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                {/* <!-- main screen --> */}
                <div className="main_wrapper bg-gradient preview_wrapper edit_profile">
                    {/* <!-- header part --> */}
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                {/* <!-- <Link to="/my_balance" className="hh_icon"><i className="ri-arrow-left-s-line ri-xl  "></i> </Link>--> */}
                            </div>
                            <div className="col-6  text-center">
                                <img src="assets/images/logo_white.svg" className="main_logo" />
                            </div>
                            <div className="col-3 text-end">
                                <div className="hh_row justify-content-end">
                                    {/* <Link to="#" className="hh_icon"> <i className="ri-notification-4-fill"></i> </Link> */}
                                    {/*  <!-- <Link to="#" className="hh_icon"> <i className="ri-wallet-3-fill"></i> </Link> --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0  home_scroll">
                        <div className="container">

                            <div class="page-header mb-2">
                                <h4><u>Responsible Play at IBAT Fantasy Sports</u></h4>
                            </div>

                            <div class="page-header mb-2">
                                Welcome to IBAT Fantasy Sports, where your game experience and well-being are our top priorities. Our core beliefs revolve around responsible play, and we're dedicated to creating an atmosphere that is enjoyable and secure at the same time  for each and every one of our users. This page describes our Responsible Game Play  policy, which includes elements meant to encourage responsible gaming and provide a safe environment.
                            </div> 

                            <div class="page-header mb-2">
                                <h4><u>Features of Responsible Game Play Policy</u></h4>
                            </div>

                            <div class="page-header mb-1">
                                <b className="fn-900-cus">Timeout</b>: Participants may choose to pause their participation in Cash Contests for a maximum of 15 days.           
                            </div> 
                            <div class="page-header mb-1">
                                <b className="fn-900-cus">Age and Place Restrictions</b>: 
                            </div> 
                            <div class="page-header mb-1">
                                <b className="fn-900-cus">Age Verification</b>: To participate in Cash Contests, users must be at least 18 years old.
                            </div> 
                            <div class="page-header mb-2">
                                <b className="fn-900-cus">Restricted Regions</b>: State regulations prohibit residents of <b>Assam, Andhra Pradesh, Odisha, Telangana, Nagaland,</b> and <b>Sikkim</b> from participating in Cash Contests.
                            </div> 

                            <div class="page-header mb-2">
                                <h4><u>How to Engage in Responsible Game Play</u></h4>
                            </div>

                            <div class="page-header mb-1">
                                <b className="fn-900-cus">Self-Assessment</b>: Evaluate your spending and gaming patterns on a regular basis.           
                            </div> 
                            <div class="page-header mb-1">
                                <b className="fn-900-cus">Set Boundaries</b>: Decide how much time and money you want to spend on gaming.
                            </div> 
                            <div class="page-header mb-1">
                                <b className="fn-900-cus">Take Breaks</b>: When necessary, use the timeout option to take a rest.
                            </div> 
                            <div class="page-header mb-2">
                                <b className="fn-900-cus">Stay Updated</b>: Read and comprehend our Responsible Game Play Policy.
                            </div> 

                            <div class="page-header mb-2">
                                <h4><u>Looking for Help</u></h4>
                            </div>
                            <div class="page-header mb-2">
                                We are available to help if you ever feel like you need support or have questions about playing games responsibly.
                            </div>
                            

                            <div class="page-header mb-2">
                                <b className="fn-900-cus">Chat With Us</b>: Make use of our round-the-clock "Chat With Us" feature for solution-based FAQs and timely customer support.
                            </div> 
                            <div class="page-header mb-2">
                                <b className="fn-900-cus">Support Desk</b>: For further help, stop by our Support Desk.
                            </div>

                            <div class="page-header mb-2">
                                Our Responsible Game Play policy is our strict attempt to protect the wellbeing of our users, as we genuinely care about their safety. In order to provide users with a good and entertaining fantasy sports experience, IBAT Fantasy Sports promotes a culture of responsible gaming.
                            </div>

                            <div class="page-header mb-2">
                                Please use common sense when playing.
                            </div>

                            <div class="page-header mb-2">
                                For more help, please contact us at support@ibatfantasysports.com
                            </div>

                        </div>  
                                              
                    </div>
                    
                    
                </div>                
                {/* <NavigationFooter /> */}
            </div>
            <RightViewContent />
        </div>
    )
}

export default ResponsiblePlay

