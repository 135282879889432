import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import Countdown from "../../customComponent/CountDown";
import { alertErrorMessage } from "../../customComponent/CustomAlertMessage";

const CaptionShipDtails = () => {

    const { state: state } = useLocation();
    const [captionName, setCaptionName] = useState(state?.captionName || []);
    const [viceCaptionName, setViceCaptionName] = useState(state?.viceCaptionName || []);
    const selectedPlayers = state?.selectedPlayers
    const navigate = useNavigate();
    const handleBack = () => {
        navigate('/create_team', { state: state });
    }

    useEffect(() => {
        if (state?.editCreatedTeam === true) {
            const selectedPlayerWithVCaption = state?.selectedPlayers.find((item) => item.vice_caption === true);
            if (selectedPlayerWithVCaption) {
                setViceCaptionName([selectedPlayerWithVCaption]);
            } else {
                // setViceCaptionName([state?.captionName]);
            }
        }
    }, [state?.selectedPlayers]);



    useEffect(() => {
        if (state?.editCreatedTeam === true) {
            const selectedPlayerWithCaption = state?.selectedPlayers.find((item) => item.caption === true);
            if (selectedPlayerWithCaption) {
                setCaptionName([selectedPlayerWithCaption]);
            } else {
                // setCaptionName([state?.viceCaptionName]);
            }
        }
    }, [state?.selectedPlayers]);

    const handleMultiplierChange = (player, type) => {
        if (type === "Caption") {
            const index = captionName.findIndex((item) => item.pid === player.pid);
            if (index > -1) {
                setCaptionName([]);
            } else {
                const viceIndex = viceCaptionName.findIndex((item) => item.pid === player.pid);
                if (viceIndex > -1) {
                    setViceCaptionName([]);
                }
                setCaptionName([player]);
            }
        } else if (type === "Vice Caption") {
            const index = viceCaptionName.findIndex((item) => item.pid === player.pid);
            if (index > -1) {
                setViceCaptionName([]);
            } else {
                const captionIndex = captionName.findIndex((item) => item.pid === player.pid);
                if (captionIndex > -1) {
                    setCaptionName([]);
                }
                setViceCaptionName([player]);
            }
        }
    };

    const combinedState = {
        ...state,
        viceCaptionName: [...viceCaptionName],
        captionName: [...captionName],
    };

    const handlePreviewTeam = () => {
        if (!captionName[0]?.pid) {
            alertErrorMessage('Please Select Caption')
        } else if (!viceCaptionName[0]?.pid) {
            alertErrorMessage('Please Select ViceCaption')
        } else {
            navigate('/preview_team', { state: combinedState });
        }
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg_img">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span className="hh_icon cursor-pointer" onClick={handleBack} ><i className="ri-arrow-left-s-line ri-xl"></i> </span>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className=""><Countdown targetTime={state?.StartDateTime} /></h4>
                            </div>
                        </div>
                    </div>
                    <div className="main_panel match_details px-0">
                        <div className="play_card">
                            <div className="leauge_name"> <span>{state?.SeriesName}</span> </div>
                            <div className="play_card_center"><span className="matcs_status">VS</span> </div>
                            <span className="row g-0 team_row">
                                <div className="col-6">
                                    <div className="team_card team_left team_left_mi">
                                        <h4>{state?.TeamsShortNames[0]}</h4>
                                        <div className="team_tcd "><span>{state?.TeamA}</span>
                                            <img src={state?.TeamAlogo} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="team_card team_right team_right_SRH">
                                        <h4>{state?.TeamsShortNames[1]}</h4>
                                        <div className="team_tcd "><span>{state?.TeamB}</span>
                                            <img src={state?.TeamBlogo} />
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <div className="detals_wrapper">
                            <div className="container">
                                <div className="cc_vc_title">
                                    <h4>Choose Captain & Vice Captain</h4>
                                    <p>C will get 2x points & VC will get 1.5x points</p>
                                </div>
                            </div>
                            <div className="team_info">
                                <div className="container">
                                    <div className="row gx-1">
                                        <div className="col-3">
                                            <span></span>
                                        </div>
                                        <div className="col-3 text-start">
                                            <span>TYPE</span>
                                        </div>
                                        <div className="col-2">
                                            <span>POINTS</span>
                                        </div>
                                        <div className="col-2">
                                            <span>CAP</span>
                                        </div>
                                        <div className="col-2">
                                            <span>V. CAP.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="player_info_row ct_scroll">
                                {selectedPlayers.length > 0 ? (
                                    selectedPlayers.map((item, index) => (
                                        <div className="player_info" key={index}>
                                            <div className="container">
                                                <div className="row align-items-center">
                                                    <div className="col-3">
                                                        <img
                                                            className="player_img w-100"
                                                            height="80"
                                                            src="assets/images/player_1.png"
                                                            width="65"
                                                        />
                                                    </div>
                                                    <div className="col-3">
                                                        <span className="player_in_title">
                                                            {item.title}
                                                            <br />
                                                            <em className="text-primary">
                                                                {/* {item.primary_team.join(" | ")} */}
                                                            </em>
                                                            {item.playing_role}
                                                        </span>
                                                    </div>
                                                    <div className="col-2">
                                                        <h5 className="mb-0">{item.fantasy_player_rating}</h5>
                                                    </div>
                                                    <div className="col-2 text-center">
                                                        <button className={`btn-outline-primary btn-icon ms-auto me-2 ${captionName.some((p) => p.pid === item.pid) ? "active" : ""}`} onClick={() => handleMultiplierChange(item, "Caption")}  >
                                                            {captionName.some((p) => p.pid === item.pid) ? "2x" : "C"}
                                                        </button>
                                                    </div>
                                                    <div className="col-1 text-start">
                                                        <button className={`btn-outline-primary btn-icon ms-auto me-2 ${viceCaptionName.some((p) => p.pid === item.pid) ? "active" : ""}`} onClick={() => handleMultiplierChange(item, "Vice Caption")}
                                                        >
                                                             {viceCaptionName.some((p) => p.pid === item.pid) ? "1.5x" : "VC"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- navigations --> */}

                <div className="nav_footer nav_footer_transperant">
                    <div className="container">
                        <div className="row gx-2 justify-content-center">
                            <div className="col-6" >
                                <button type="button" className="custom-btn btn-border-gradient  btn-block w-100 text-uppercase" onClick={handlePreviewTeam}>
                                    <span>team preview</span>
                                </button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase" onClick={handlePreviewTeam}>
                                    <span> Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default CaptionShipDtails

