
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import { alertErrorMessage, alertSuccessMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import AuthService from "../../AuthService";
import { validatePanCard } from "../../utils/Validation";
import moment from "moment";
import { StateList } from "../../utils/StateList";

const VerifyPanCard = () => {

    const userId = localStorage.getItem('_id');

    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [panNumber, setPanNumber] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [selectState, setSelectState] = useState("");
    const [imagePanCard, setImagePanCard] = useState("");
    const [localPanImage, setLocalPanImage] = useState("");


    const handleBackKycDetails = () => {
        navigate("/kyc_details");
    }

    const handleVerifyPanCard = async (name, panNumber, birthDate, selectState, imagePanCard) => {
        if (!name) {
            alertErrorMessage('Please Enter Your Name')
        } else if (!panNumber) {
            alertErrorMessage('Please Enter Your Pan Card Number')
        } else if (!panNumber || validatePanCard(panNumber)) {
            alertErrorMessage('Please Enter Valid Pan Card Number')
        } else if (!birthDate) {
            alertErrorMessage('Please Enter Your Birth Date')
        } else if (!selectState) {
            alertErrorMessage('Please Select Your State')
        } else if (!imagePanCard) {
            alertErrorMessage('Please Upload your PAN Card Image')
        }
        else {
            LoaderHelper.loaderStatus(true);
            await AuthService.verifyPanKyc(name, panNumber, birthDate, selectState, localPanImage, userId).then(async result => {
                if (result?.success) {
                    LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result?.message);
                    navigate("/kyc_details");
                } else {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result?.message);
                }
            });
        }
    }

    const handleChangePanImage = async (event) => {
        event.preventDefault();
        const fileUploaded = event.target.files[0];
        const imgata = URL.createObjectURL(fileUploaded);
        setImagePanCard(imgata);
        handleUpdatePanCardImage(fileUploaded);
    }


    const handleUpdatePanCardImage = async (imagePanCard) => {
        LoaderHelper.loaderStatus(true);
        var formData = new FormData();
        formData.append('file', imagePanCard);
        await AuthService.updateLogoImages(formData).then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    // alertSuccessMessage(result.message);
                    setLocalPanImage(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage('Pan Card Not Upload');
            }
        });
    }

    const getEighteenYearsAgoDate = () => {
        let eighteenYearsAgo = new Date();
        eighteenYearsAgo = eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
        eighteenYearsAgo = moment(eighteenYearsAgo).format('YYYY-MM-DD');
        return eighteenYearsAgo;
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg-gradient preview_wrapper edit_profile">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span className="hh_icon cursor-pointer" onClick={handleBackKycDetails}><i className="ri-arrow-left-s-line ri-xl"></i></span>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className="">Verify PAN Card</h4>
                            </div>
                            <div className="col-3  text-end">
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0 home_scroll">
                        <div className="container">
                            <form className="">
                                <div className="pay_card mb-3 ">
                                    <div className="pay_header">
                                        <div className="h6">Enter Your PAN Card Details</div>
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>Name</label>
                                        <input type="text" className="form-control" placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>PAN Number</label>
                                        <input type="text" className="form-control" placeholder="Enter your PAN Number" name="panCard" value={panNumber} onChange={(event) => setPanNumber(event.target.value.toUpperCase())} />
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>DOB</label>
                                        <input type="date" max={getEighteenYearsAgoDate()} className="form-control" value={birthDate} onChange={(e) => setBirthDate(e.target.value)} />
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>State</label>
                                        <select className="form-select" aria-label="Default select example" value={selectState} onChange={(e) => setSelectState(e.target.value)}>
                                            <option value="" disabled defaultValue>Please Choose Your State..</option>
                                            {StateList?.list?.map((item, index) => {
                                                return (
                                                    <option key={index}>{item}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>Upload your PAN Card</label>
                                        <input type="file" className="form-control" onChange={handleChangePanImage} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="nav_footer nav_footer_transperant">
                    <div className="container">
                        <div className="row gx-2 justify-content-center">
                            <div className="col-12">

                                <button className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase" onClick={() => handleVerifyPanCard(name, panNumber, birthDate, selectState, imagePanCard)}>
                                    <span>VERIFY</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default VerifyPanCard

