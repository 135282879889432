
import React, { useEffect, useState } from "react";
import RightViewContent from "../../customComponent/RightViewContent";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { alertErrorMessage, alertSuccessMessage } from "../../customComponent/CustomAlertMessage";
import AuthService from "../../AuthService";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";

const DepositIbat = (props) => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [iBatBalance, setIBatBalance] = useState([]);
    const [bnbBalance, setBnbBalance] = useState([]);

    useEffect(() => {
        if (props?.userDetails?.wallet_address) {
            handleIBatBalance(props?.userDetails?.wallet_address);
            handleBnbBalance(props?.userDetails?.wallet_address);
        }
    }, []);

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(props?.userDetails?.wallet_address);
            alertSuccessMessage('Copied..!'); // Display a success message
        } catch (err) {
            console.error('Error copying to clipboard: ', err);
        }
    };

    const handleIBatBalance = async (walletAddress) => {
        await AuthService.getIBatBalanceProfile(walletAddress).then(async result => {
            if (result.success) {
                try {
                    setIBatBalance(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    }

    const handleBnbBalance = async (walletAddress) => {
        await AuthService.getBnbBalance(walletAddress).then(async result => {
            if (result.success) {
                try {
                    setBnbBalance(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg-gradient preview_wrapper">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <Link to={state === 'paywalletScreen' ? '/pay_wallet' : "/my_balance"} className="hh_icon cursor-pointer"><i className="ri-arrow-left-s-line ri-xl  "></i> </Link>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className="">Deposit IBAT & BNB</h4>
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0 ">
                        <div className="container">
                            <div className="po_row">
                                <div className="qr_img">
                                    <img src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${props?.userDetails?.wallet_address}&choe=UTF-8`} />
                                </div>
                                <div className="field-box mb-5">
                                    <div className="text">BNB Balance: {bnbBalance}</div>
                                    <div className="text">IBAT Balance: {iBatBalance}</div>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" value={props?.userDetails?.wallet_address} readOnly style={{ fontSize: '15px' }} onClick={copyToClipboard} />
                                        <button className="btn btn-secondary px-3" onClick={copyToClipboard}>
                                            <i className="ri-file-copy-line "></i>
                                        </button>
                                    </div>
                                    <small className="text-secondary d-block text-center">Click above to copy the code</small>
                                </div>
                                <div className="pay_card   pointer">
                                    <div className="sc_title mb-0">
                                        <div>
                                            <h5 className="text-primary">Disclaimer:</h5>
                                            <ul className="">
                                                <li>
                                                    • Minimum deposit of 0.250 IBAT, deposit below that cannot be recovered.
                                                </li>
                                                <li>
                                                    • Please deposit only IBAT on this address. If you deposit any other coin, it will be lost
                                                    forever.
                                                </li>
                                                <li>• This is IBAT deposit address type. Transferring to an unsupported network could result in
                                                    loss of deposit.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div >
    )
}

export default DepositIbat

