const appUrl = 'https://api.ibatfantasysports.com';
// const appUrl = 'http://localhost:2024';

export const ApiConfig = {
  // =========EndPoints==========

  register: "signup",
  registerGoogle: "sign-with-google",
  loginRegisterTelegram: "sign-with-telegram",
  getcodeSignup: 'verify-otp',
  login: 'login',
  tokenRefresh: 'refresh-token',
  otpForgot: 'forget-password',
  forgotPassword: 'reset-password',
  updateProfile: 'update-profile',
  uploads: 'upload',
  UserProfile: 'profile',
  walletDetails: 'user-wallet',
  withdraw_ibat: 'withdrawdatabase',
  referrals: 'referrals',
  CreateTeam: 'create-team',
  contestJoin: 'join-contest',
  iBatBalance: 'ibat-balance',
  bnbBalance: 'bnb-balance',
  kycDetail: 'kyc-details',
  matchReminder: 'save-match-reminders',
  playerProfile: 'player-profile',
  EditTeam: 'update-team',
  updateAddress: 'update-address',
  createWallet: 'wallet/create-wallet',
  sendOtpSignup: 'otp',
  playerDetail: 'player-details',
  deleteUser: 'delete',






  // ============URLs================

  baseUrl: `${appUrl}/`,
  baseUser: `${appUrl}/user/`,
  baseUrlMatch: `${appUrl}/match/`,
  baseUrlWallet: `${appUrl}/wallet/`,

};