
import React, { useEffect } from "react";
import RightViewContent from "../../customComponent/RightViewContent";
import { Link } from "react-router-dom";
import io from 'socket.io-client';

const TermsCondition = () => {

    // useEffect(() => {
    //     let socket;
      
    //     const storedSocket = sessionStorage.getItem('socket');
    //     if (storedSocket) {
    //       // If a socket connection is found in sessionStorage, restore it
    //       const { id } = JSON.parse(storedSocket);
    //       socket = io('ws://13.233.102.48:3000', {
    //         transports: ['websocket'],
    //         upgrade: false,
    //         rejectUnauthorized: false,
    //         reconnectionAttempts: 3,
    //         debug: true,
    //         query: { id }, // Pass the socket ID as a query parameter
    //       });
    //     } else {
    //       // Otherwise, create a new socket connection
    //       socket = io('ws://13.233.102.48:3000', {
    //         transports: ['websocket'],
    //         upgrade: false,
    //         rejectUnauthorized: false,
    //         reconnectionAttempts: 3,
    //         debug: true,
    //       });
    //     }
      
    //     const handleConnect = () => {
    //       console.log('Connected');
    //       socket.emit('message', 'market');
    //     };
      
    //     const handleMessage = (data) => {
    //       console.log('Received message:', data);
    //     };
      
    //     socket.on('connect', handleConnect);
    //     socket.on('message', handleMessage);
      
    //     // Save the necessary socket information in sessionStorage
    //     sessionStorage.setItem('socket', JSON.stringify({ id: socket.id }));
      
    //     // Clean up the socket connection when the component unmounts
    //     return () => {
    //       socket.disconnect();
    //     };
    //   }, []);
     

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg-gradient edit_profile all_scroll">
                    {/* <!-- header part --> */}
                    <div className="invite_wrapper">
                        <div className="header_wrapper">
                            <div className="row align-items-center">
                                <div className="col-3 text-start">
                                    <Link to="/settings" className="hh_icon"><i className="ri-arrow-left-s-line ri-xl  "></i> </Link>
                                </div>
                                <div className="col-6  text-center">
                                    <h4 className="">Terms And Condition</h4>
                                </div>
                                <div className="col-3  text-end">
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="invite_contr">
                                <p><>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>1. Introduction &nbsp;</span>
    </strong>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Welcome to IBAT &nbsp;Fantasy Sports! These terms and conditions govern
      your use of our fantasy sports platform and services. By accessing or
      using IBAT &nbsp;Fantasy Sports, you agree to comply with these terms and
      conditions. If you do not agree with any part of these terms, you may not
      access or use our platform.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>&nbsp; 2. Eligibility &nbsp;</span>
    </strong>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - You must be at least 18 years old or the legal age of majority in your
      jurisdiction to participate in IBAT &nbsp;Fantasy Sports.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - Employees, affiliates, and immediate family members of IBAT
      &nbsp;Fantasy Sports are not eligible to participate in contests offered
      on the platform.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>
        &nbsp; 3. Account Registration &nbsp;
      </span>
    </strong>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - You must create an account to participate in contests offered on IBAT
      &nbsp;Fantasy Sports.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - You agree to provide accurate and complete information during the
      registration process.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - You are responsible for maintaining the confidentiality of your account
      credentials and for all activities that occur under your account.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>User Account</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      To access our services, you must register on our platform and create an
      account ("Account"). During registration or at any time while using our
      services, you may need to provide the following information:
    </span>
  </p>
  <ul>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p>
        <span style={{ fontSize: "11pt" }}>Full name</span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p>
        <span style={{ fontSize: "11pt" }}>Team name</span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p>
        <span style={{ fontSize: "11pt" }}>Mobile number</span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p>
        <span style={{ fontSize: "11pt" }}>Address and state of residence</span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p>
        <span style={{ fontSize: "11pt" }}>Gender</span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p>
        <span style={{ fontSize: "11pt" }}>Date of birth</span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p>
        <span style={{ fontSize: "11pt" }}>Aadhar number</span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p>
        <span style={{ fontSize: "11pt" }}>Any other valid identification</span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p>
        <span style={{ fontSize: "11pt" }}>Bank account details</span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p>
        <span style={{ fontSize: "11pt" }}>UPI ID</span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p>
        <span style={{ fontSize: "11pt" }}>Permanent Account Number (PAN)</span>
      </p>
    </li>
  </ul>
  <p>
    <br />
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>&nbsp; 4. User Conduct &nbsp;</span>
    </strong>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - You agree to use IBAT &nbsp;Fantasy Sports for lawful purposes only.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - You must not engage in any conduct that violates the rights of others or
      inhibits their use and enjoyment of the platform.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - You must not use automated means, including bots or scripts, to access
      or interact with IBAT &nbsp;Fantasy Sports.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>
        &nbsp; 5. Contest Participation &nbsp;
      </span>
    </strong>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - IBAT &nbsp;Fantasy Sports offers various contests involving fantasy
      sports leagues.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - By participating in contests, you agree to abide by the rules and
      scoring criteria specified for each contest.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - IBAT &nbsp;Fantasy Sports reserves the right to cancel, suspend, or
      modify contests at any time without prior notice.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>
        &nbsp; 6. Payments and Prizes&nbsp;
      </span>
    </strong>
    <span style={{ fontSize: "11pt" }}>&nbsp;</span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - Participation in certain contests may require payment of an entry fee.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - IBAT &nbsp;Fantasy Sports will specify the entry fee, prize structure,
      and distribution for each contest.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - Prizes will be awarded as specified in the contest rules and will be
      credited to the winners' accounts.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>
        &nbsp; 7. Intellectual Property &nbsp;
      </span>
    </strong>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - All content and materials on IBAT &nbsp;Fantasy Sports, including logos,
      trademarks, and software, are the property of IBAT &nbsp;Fantasy Sports or
      its licensors.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - You may not reproduce, distribute, or modify any content from IBAT
      &nbsp;Fantasy Sports without prior written consent.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>
        &nbsp; 8. Limitation of Liability &nbsp;
      </span>
    </strong>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - IBAT &nbsp;Fantasy Sports is not liable for any damages arising out of
      or related to your use of the platform, including but not limited to lost
      profits, data loss, or consequential damages.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - In no event shall IBAT &nbsp;Fantasy Sports' total liability exceed the
      amount paid by you to participate in the contest giving rise to the claim.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>&nbsp; 9. Indemnification &nbsp;</span>
    </strong>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - You agree to indemnify and hold IBAT &nbsp;Fantasy Sports harmless from
      any claims, losses, damages, liabilities, and expenses arising out of your
      use of the platform or your violation of these terms and conditions.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>&nbsp; 10. Amendments &nbsp;</span>
    </strong>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - IBAT &nbsp;Fantasy Sports reserves the right to modify or update these
      terms and conditions at any time.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - Changes to the terms and conditions will be effective upon posting on
      the platform.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      - Your continued use of IBAT &nbsp;Fantasy Sports after any such changes
      constitutes acceptance of the updated terms and conditions.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>&nbsp; 11. Contact Us &nbsp;</span>
    </strong>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      If you have any questions or concerns about these terms and conditions,
      please contact us at&nbsp;
    </span>
    <strong>
      <span style={{ fontSize: "11pt" }}>XXXXXXXXXXX</span>
    </strong>
  </p>
  <p>
    <br />
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>12. Disclaimers &nbsp;</span>
    </strong>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      1. &nbsp; Unforeseen Events: &nbsp; We shall not be held liable for any
      delays or failures in rendering our services due to unforeseeable events
      beyond our control.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      2. &nbsp; Use at Your Own Risk: &nbsp; Your usage of our platform is at
      your own risk. We disclaim any responsibility for any loss or damage,
      including personal injury or property damage, incurred while using our
      platform.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      3. &nbsp; Third-Party Content: &nbsp; Our platform may feature third-party
      advertisements and links to external websites or services. We do not
      endorse or guarantee the accuracy of such content, and reliance on it is
      at your own discretion.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      4. &nbsp; Information Management: &nbsp; We do not assume responsibility
      for issues related to the storage, deletion, delivery, or accessibility of
      information on our platform.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      5. &nbsp; Content Warranty: &nbsp; All content provided on our platform is
      offered on an "as is, as available" basis, without any warranty or
      guarantee.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      6. &nbsp; Team Line-Up Accuracy: &nbsp; While we strive for accuracy, we
      cannot guarantee the precision of team starting line-up data sourced from
      external providers.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      7. &nbsp; Fantasy Points Calculation: &nbsp; We are not liable for any
      errors in calculating fantasy points or determining winners due to
      inaccuracies in information provided by third-party service providers.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      8. &nbsp; Prize Quality: &nbsp; We do not provide warranties regarding the
      quality or suitability of prizes offered, and we disclaim any liability
      related to prize-related issues.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      9. &nbsp; Platform Accessibility: &nbsp; We shall not be held liable for
      your inability to access our platform due to circumstances beyond our
      control, such as network outages or internet disruptions.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      10. &nbsp; Communication Errors: &nbsp; We are not responsible for any
      errors or omissions in communication that may affect the transmission of
      results or notifications.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      11. &nbsp; Confidential Information: &nbsp; We disclaim liability for any
      consequences arising from the sharing of confidential information, such as
      OTP, with others.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <br />
  </p>
</>
</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default TermsCondition

