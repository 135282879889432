
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import { alertErrorMessage } from "../../customComponent/CustomAlertMessage";

const RefrelPage = () => {

    const [refrelCode, setRefrelCode] = useState('')

    const navigate = useNavigate()

    const handleRefrelCode = (refrelCode) => {
        if (!refrelCode) {
            alertErrorMessage('Please Fill Refreel Code')
        }
        else {
            navigate('/register', { state: refrelCode })
        }
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper login_wrapper login_bg_2">
                    <div className="container">
                        <div className="login_page ">
                            <Link to="/" className="btn-back hh_icon"><i className="ri-arrow-left-s-line"></i></Link>
                            <img src="assets/images/logo_login.svg" className="logo_img" />
                            <div className="login_row login_scroll">
                                <h2>Referral Code</h2>
                                <p>Enter Referral Code</p>
                                <form action="#" className="login_form">
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            <div className="field-box">
                                                <label htmlFor="Code" className="form-label">Referral Code</label>
                                                <input className="form-control" id="text" name="signId" type="text" placeholder="Enter Referral Code"
                                                    onChange={(e) => setRefrelCode(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <div className="field-box">
                                                <button className="custom-btn btn-border-gradient btn-gradient btn-block w-100 mb-3" type="button" onClick={() => handleRefrelCode(refrelCode)}>
                                                    <span>SUBMIT</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="row justify-content-center text-center text-white">
                                <div className="col-lg-12 have_referal">Don't have an account.?&nbsp;
                                    <Link className="color-primary" to="/register">Register</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default RefrelPage

