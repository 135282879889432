import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import NavigationFooter from "../../customComponent/NavigationFooter";
import useWebSocket from "react-use-websocket";
import Countdown from '../../customComponent/CountDown';
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import { alertwWarningMessage } from "../../customComponent/CustomAlertMessage";
import { ApiConfig } from "../../utils/apiConfig/apiConfig";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import 'swiper/css';
import { Pagination } from 'swiper/modules';
const HomePage = (props) => {

    const useId = localStorage.getItem("_id");
    const [upcomgMatchList, setUpcomgMatchList] = useState([]);
    const [myMatchesList, setMyMatchesList] = useState([]);
    const navigate = useNavigate();

    let { lastJsonMessage } = useWebSocket(`wss://api.ibatfantasysports.com/upcoming-matches?&skip=0&userid=${useId}`, {
        shouldReconnect: (closeEvent) => true,
    });

    useEffect(() => {
        if (lastJsonMessage) {
            LoaderHelper.loaderStatus(false);
            setUpcomgMatchList(lastJsonMessage?.upcoming || []);
            setMyMatchesList(lastJsonMessage?.mymatches || []);
        } else {
            LoaderHelper.loaderStatus(false);
        }
    }, [lastJsonMessage]);

    const handleMatchContest = (matchId, item) => {
        if (!matchId) {
            alertwWarningMessage('There Are No Contest For This Match')
        } else {
            navigate('/match_single', { state: item });
        }
    }

    const myContestDetails = (item) => {
        if (item?.Status === "Scheduled") {
            handleMatchContest(item?._id, item)
        } else {
            navigate('/contest_details', { state: item });
        }
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg_img">
                    {/* header part */}
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3">
                                <span className="user_icon">
                                    {props?.userDetails?.logo ?
                                        <img src={`${ApiConfig.baseUrl + props?.userDetails?.logo}`} />
                                        :
                                        <img src='assets/images/user.png' />
                                    }
                                </span>
                            </div>
                            <div className="col-6  text-center">
                                <img src="assets/images/logo_white.svg" className="main_logo" />
                            </div>
                            <div className="col-3 text-end">
                                <div className="hh_row">
                                    <Link to="/my_balance" className="hh_icon"> <i className="ri-wallet-3-fill"></i> </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* header part */}
                    <div className="main_panel home_scroll">
                        <ul className="main_tab nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="btn nav-link active" id="cricket-tab" data-bs-toggle="tab" data-bs-target="#cricket" type="button" role="tab" aria-controls="cricket" aria-selected="true">
                                    <img src="assets/images/icon_cricket.png" width="20" height="20" />
                                    <span>Cricket</span>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="btn nav-link" id="football-tab" data-bs-toggle="tab" data-bs-target="#football" type="button" role="tab" aria-controls="football" aria-selected="false">
                                    <img src="assets/images/icon_football.png" width="20" height="20" />
                                    <span>Football</span>
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="cricket" role="tabpanel" aria-labelledby="cricket-tab">
                                <div className="sc_title">
                                    <h4>My matches</h4>
                                    <Link to='/contest' className="btn btn-sm btn-w-border">View all<i className="ms-1 ri-arrow-right-s-line"></i></Link>
                                </div>
                                <div className="swiper teamSwiper">
                                    {myMatchesList.length > 0 && (
                                        <Swiper
                                            spaceBetween={10}
                                            pagination={{
                                                dynamicBullets: true,
                                            }}
                                            slidesPerView={1}
                                            className="mySwiper"
                                            modules={[Pagination]}
                                        >
                                            {myMatchesList.map((item, index) => (
                                                <SwiperSlide key={index}>
                                                    <div className="play_card">
                                                        <div className="leauge_name">
                                                            <span>{item?.SeriesName}</span>
                                                        </div>
                                                        <div className="play_card_center">
                                                            <span className="matcs_status">{item?.Status}  </span>
                                                        </div>
                                                        <span className="row g-0 team_row cursor-pointer" onClick={() => myContestDetails(item)}>
                                                            <div className="col-6">
                                                                <div className="team_card team_left team_left_mi">
                                                                    <div className="team_tcd">
                                                                        <span>{item?.TeamA}</span>
                                                                        <img src={item?.TeamAlogo} alt={item?.TeamA} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="team_card team_right team_right_SRH">
                                                                    <div className="team_tcd">
                                                                        <span>{item?.TeamB}</span>
                                                                        <img src={item?.TeamBlogo} alt={item?.TeamB} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="play_footer d-flex align-items-center justify-content-between">
                                                                <div><span>2 Team</span> &nbsp; &nbsp; <span>3 Contests</span> </div>
                                                            </div>
                                                        </span>
                                                    </div>

                                                </SwiperSlide>

                                            ))}
                                        </Swiper>

                                    )}
                                </div>
                                <div className="sc_title">
                                    <h4>Upcoming Matches</h4>
                                </div>
                                {upcomgMatchList.length > 0
                                    ? upcomgMatchList.map((item, index) => {
                                        const megaValue = item?.contest_details[0]?.data;
                                        const highestWinningAmount = megaValue && megaValue.length > 0 ? megaValue.reduce((max, contest) => {
                                            return (Number(contest.winning_amount) > Number(max.winning_amount)) ? contest : max;
                                        }, megaValue[0]) : null;
                                        return (
                                            <div className="play_card" key={index}>
                                                <div className="leauge_name"> <span>{item?.SeriesName}</span> </div>
                                                <div className="play_card_center">
                                                    <span className="matcs_status"> <Countdown targetTime={item.StartDateTime} />
                                                    </span>
                                                </div>
                                                <span className="row g-0 team_row" onClick={() => handleMatchContest(item?.contest_details[0]?.match_id, item)}>
                                                    <div className="col-6">
                                                        <div className="team_card team_left team_left_GT cursor-pointer">

                                                            <div className="team_tcd "><span>{item?.TeamA}</span> <img src={item?.TeamAlogo} /> </div>
                                                            <h4>{item?.TeamsShortNames[0]}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="team_card team_right team_right_SRH cursor-pointer">
                                                            <h4>{item?.TeamsShortNames[1]}</h4>
                                                            <div className="team_tcd "><span>{item?.TeamB}</span> <img src={item?.TeamBlogo} /> </div>
                                                        </div>
                                                    </div>
                                                </span>
                                                {highestWinningAmount?.winning_amount ?
                                                    <div className="play_footer d-flex align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            {highestWinningAmount ?
                                                                <span className="badge badge-success me-2">
                                                                    {highestWinningAmount && <span>{highestWinningAmount.contest_type}</span>}
                                                                </span>
                                                                :
                                                                ''
                                                            }
                                                            {highestWinningAmount && <span>{highestWinningAmount.winning_amount}</span>}
                                                        </div>
                                                        <span className="text-success d-flex align-items-center">

                                                            {item?.line_up_out == true ?
                                                                <small><i className="ri-checkbox-blank-circle-fill me-1"></i> LINEUPS OUT</small> : ''}
                                                        </span>
                                                    </div>
                                                    : ''
                                                }
                                            </div>
                                        )
                                    }) : null}
                            </div>
                            <div className="tab-pane fade" id="football" role="tabpanel" aria-labelledby="football-tab">
                                Football Section
                            </div>
                        </div>
                    </div>
                </div>

                {/* welcome screens modal */}
                <div className="modal fade welcome_modal " id="welcomeModal" tabIndex="-1" data-bs-backdrop="static"
                    aria-labelledby="welcomeModallLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        {/*  <button type="button" className="btn-close mm_close" data-bs-dismiss="modal" aria-label="Close"></button>  */}
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="swiper welcomeSwiper">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="cards-content">
                                                <div className="cards-header">
                                                    <h5 className="cards-title" id="welcomeModalLabel">Build your team</h5>
                                                </div>
                                                <div className="cards-body">
                                                    <p className=" text-white">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                                        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                                                        galley of type and scrambled it to make a type specimen book.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="cards-content">
                                                <div className="cards-header">
                                                    <h5 className="cards-title" id="welcomeModalLabel">Build your team</h5>
                                                </div>
                                                <div className="cards-body">
                                                    <p className=" text-white">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                                        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                                                        galley of type and scrambled it to make a type specimen book.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="cards-content">
                                                <div className="cards-header">
                                                    <h5 className="cards-title" id="welcomeModalLabel">Build your team</h5>
                                                </div>
                                                <div className="cards-body">
                                                    <p className=" text-white">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                                        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                                                        galley of type and scrambled it to make a type specimen book.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide_control">
                                        <div className="custom-btn btn-sm  btn-white-smoke swiper-button-prev"> Prev. </div>
                                        <div className="swiper-pagination"></div>
                                        <div className="cn_close">
                                            <div className="custom-btn btn-sm btn-primary swiper-button-next"> Next</div>
                                            <div className="custom-btn btn-sm btn-primary  cn_close_btn" data-bs-dismiss="modal" aria-label="Close">
                                                Next</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NavigationFooter />
            </div>
            <RightViewContent />
        </div>
    )
}

export default HomePage

