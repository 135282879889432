import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import AuthService from "../../AuthService";
import { alertErrorMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import Countdown from "../../customComponent/CountDown";
import SelectPlayers from "../SelectPlayers";

const CreateTeam = () => {

    const { state } = useLocation();
    const navigate = useNavigate();
    const [allPlayrsData, setAllPlayrsData] = useState([]);
    const [selectedPlayers, setSelectedPlayers] = useState(state?.selectedPlayers || []);
    const [selectedWkCount, setSelectedWkCount] = useState(0);
    const [selectedBOWLCount, setSelectedBOWLCount] = useState(0);
    const [selectedBATCount, setSelectedBATCount] = useState(0);
    const [selectedARCount, setSelectedARCount] = useState(0);
    const [creditLimit, setCreditLimit] = useState(100);
    const [activeTab, setActiveTab] = useState('wk');
    const [minPlayerlLimit, setMinPlayerlLimit] = useState(4);

    console.log(state,'====');

    const totalRatingCount = Math.abs(selectedPlayers.reduce((acc, curr) => acc + curr.fantasy_player_rating, 0));

    const handleBack = () => {
        navigate('/match_single', { state: state });
    }

    useEffect(() => {
        handleAllPlayrsList();
    }, []);

    useEffect(() => {
        if (state?.teamDetails?.[0]?.players) {
            setSelectedPlayers([...state?.teamDetails?.[0]?.players]);
        }
    }, []);


    const handleAllPlayrsList = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getAllPlayrsList(state?._id,state?.SeriesId).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setAllPlayrsData(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    }


    useEffect(() => {
        const initialWkCount = selectedPlayers.filter((p) => p.playing_role === 'wk').length;
        setSelectedWkCount(initialWkCount);

        const initialBATCount = selectedPlayers.filter((p) => p.playing_role === 'bat').length;
        setSelectedBATCount(initialBATCount);

        const initialARCount = selectedPlayers.filter((p) => p.playing_role === 'all').length;
        setSelectedARCount(initialARCount);

        const initialBOWLCount = selectedPlayers.filter((p) => p.playing_role === 'bowl').length;
        setSelectedBOWLCount(initialBOWLCount);
    }, [selectedPlayers]);

    useEffect(() => {
        setCreditLimit(100 - totalRatingCount)
    }, [totalRatingCount]);


    const getTeamPlayers = (player) => {
        const teamPlayers = selectedPlayers.filter((item) => item.title === player.title);
        return teamPlayers?.length + 1;
    }

    const handleAddPlayer = (player, title, position) => {
        player.title = title;
        let wkPlayer = selectedPlayers.filter(
            item => item?.playing_role === 'wk'
        );
        let batPlayer = selectedPlayers.filter(
            item => item?.playing_role === 'bat',
        );
        let arPlayer = selectedPlayers.filter(
            item => item?.playing_role === 'all',
        );
        let bowlPlayer = selectedPlayers.filter(
            item => item?.playing_role === 'bowl',
        );
        const index = selectedPlayers.findIndex(
            x => x?.pid === player?.pid,
        );
        if (index > -1) {
            const updatedPlayers = [...selectedPlayers];
            updatedPlayers.splice(index, 1);
            setSelectedPlayers(updatedPlayers);
            if (
                (wkPlayer.length - 1 < 1 && activeTab === 'wk') ||
                (batPlayer.length - 1 < 1 && activeTab === 'bat') ||
                (arPlayer.length - 1 < 1 && activeTab === 'all') ||
                (bowlPlayer.length - 1 < 1 && activeTab === 'bowl')
            ) {
                if (minPlayerlLimit < 8) {
                    let min = minPlayerlLimit + 1;
                    setMinPlayerlLimit(min);
                }
            }
        } else {
            if (creditLimit >= player?.fantasy_player_rating) {
                if (selectedPlayers.length < 11) {
                    const getTeamPlayersLength = getTeamPlayers(player);

                    if (getTeamPlayersLength <= 7) {
                        if (wkPlayer.length + 1 > 8 && activeTab === 'wk') {
                            alertErrorMessage(
                                `You can select only 8 Wicket-Keepers.`,
                            );
                            return;
                        } else if (
                            batPlayer.length + 1 > 8 &&
                            activeTab === 'bat'
                        ) {
                            alertErrorMessage(
                                `You can select only 8 Batter.`,
                            );
                            return;
                        } else if (
                            arPlayer.length + 1 > 8 &&
                            activeTab === 'all'
                        ) {
                            alertErrorMessage(
                                `You can select only 8 All-rounders.`,
                            );
                            return;
                        } else if (
                            bowlPlayer.length + 1 > 8 &&
                            activeTab === 'bowl'
                        ) {
                            alertErrorMessage(
                                `You can select only 8 Bowlers.`,
                            );
                            return;
                        } else {

                            //Wk Tab Logic
                            if (activeTab === 'wk') {
                                if (wkPlayer.length < 1) {
                                    setSelectedPlayers((prevSelectedPlayers) => {
                                        return [...prevSelectedPlayers, player];
                                    });
                                    if (wkPlayer.length < 1) {
                                        let min = minPlayerlLimit - 1;
                                        setMinPlayerlLimit(min);
                                    }
                                }
                                else {
                                    if (
                                        selectedPlayers.length > 0 &&
                                        selectedPlayers.length + 1 > 11 - minPlayerlLimit &&
                                        batPlayer.length < 1
                                    ) {
                                        alertErrorMessage(
                                            `You must select atleast 1 Batter.`,
                                        );
                                    } else if (
                                        selectedPlayers.length > 0 &&
                                        selectedPlayers.length + 1 > 11 - minPlayerlLimit &&
                                        arPlayer.length < 1
                                    ) {
                                        alertErrorMessage(
                                            `You must select atleast 1 All-rounders.`,
                                        );
                                    } else if (
                                        selectedPlayers.length > 0 &&
                                        selectedPlayers.length + 1 > 11 - minPlayerlLimit &&
                                        bowlPlayer.length < 1
                                    ) {
                                        alertErrorMessage(
                                            `You must select atleast 1 Bowlers.`,
                                        );
                                    } else {

                                        setSelectedPlayers((prevSelectedPlayers) => {
                                            return [...prevSelectedPlayers, player];
                                        });
                                        if (wkPlayer.length < 1) {
                                            let min = minPlayerlLimit - 1;
                                            setMinPlayerlLimit(min);
                                        }
                                    }
                                }
                            }

                            //Bat Tab Logic
                            if (activeTab === 'bat') {
                                if (batPlayer.length < 1) {
                                    setSelectedPlayers((prevSelectedPlayers) => {
                                        return [...prevSelectedPlayers, player];
                                    });
                                    if (batPlayer.length < 1) {
                                        let min = minPlayerlLimit - 1;
                                        setMinPlayerlLimit(min);
                                    }
                                } else {
                                    if (
                                        selectedPlayers.length > 0 &&
                                        selectedPlayers.length + 1 > 11 - minPlayerlLimit &&
                                        wkPlayer?.length < 1
                                    ) {
                                        alertErrorMessage(
                                            `You must select atleast 1 Wicket-Keepers.`,
                                        );
                                    } else if (
                                        selectedPlayers.length > 0 &&
                                        selectedPlayers.length + 1 > 11 - minPlayerlLimit &&
                                        arPlayer.length < 1
                                    ) {
                                        alertErrorMessage(
                                            `You must select atleast 1 All-rounders.`,
                                        );
                                        return;
                                    } else if (
                                        selectedPlayers.length > 0 &&
                                        selectedPlayers.length + 1 > 11 - minPlayerlLimit &&
                                        bowlPlayer.length < 1
                                    ) {
                                        alertErrorMessage(
                                            `You must select atleast 1 Bowlers.`,
                                        );
                                        return;
                                    } else {
                                        setSelectedPlayers((prevSelectedPlayers) => {
                                            return [...prevSelectedPlayers, player];
                                        });
                                        if (batPlayer.length < 1) {
                                            let min = minPlayerlLimit - 1;
                                            setMinPlayerlLimit(min);
                                        }
                                    }
                                }
                            }

                            //Ar Tab Logic
                            if (activeTab === 'all') {
                                if (arPlayer.length < 1) {
                                    setSelectedPlayers((prevSelectedPlayers) => {
                                        return [...prevSelectedPlayers, player];
                                    });
                                    selectedPlayers.push(player);
                                    if (arPlayer.length < 1) {
                                        let min = minPlayerlLimit - 1;
                                        setMinPlayerlLimit(min);
                                    }
                                } else {
                                    if (
                                        selectedPlayers.length > 0 &&
                                        selectedPlayers.length + 1 > 11 - minPlayerlLimit &&
                                        wkPlayer.length < 1
                                    ) {
                                        alertErrorMessage(
                                            `You must select atleast 1 Wicket-Keepers.`,
                                        );
                                    } else if (
                                        selectedPlayers.length > 0 &&
                                        selectedPlayers.length + 1 > 11 - minPlayerlLimit &&
                                        batPlayer.length < 1
                                    ) {
                                        alertErrorMessage(
                                            `You must select atleast 1 Batter.`,
                                        );
                                    } else if (
                                        selectedPlayers.length > 0 &&
                                        selectedPlayers.length + 1 > 11 - minPlayerlLimit &&
                                        bowlPlayer.length < 1
                                    ) {
                                        alertErrorMessage(
                                            `You must select atleast 1 Bowlers.`,
                                        );
                                        return;
                                    } else {
                                        setSelectedPlayers((prevSelectedPlayers) => {
                                            return [...prevSelectedPlayers, player];
                                        });
                                        if (arPlayer.length < 1) {
                                            let min = minPlayerlLimit - 1;
                                            setMinPlayerlLimit(min);
                                        }
                                    }
                                }
                            }

                            //Bowl Tab Logic
                            if (activeTab === 'bowl') {
                                if (bowlPlayer.length < 1) {
                                    setSelectedPlayers((prevSelectedPlayers) => {
                                        return [...prevSelectedPlayers, player];
                                    });
                                    if (bowlPlayer.length < 1) {
                                        let min = minPlayerlLimit - 1;
                                        setMinPlayerlLimit(min);
                                    }
                                } else {
                                    if (
                                        selectedPlayers.length > 0 &&
                                        selectedPlayers.length + 1 > 11 - minPlayerlLimit &&
                                        wkPlayer.length < 1 &&
                                        activeTab !== 'wk'
                                    ) {
                                        alertErrorMessage(
                                            `You must select atleast 1 Wicket-Keepers.`,
                                        );
                                    } else if (
                                        selectedPlayers.length > 0 &&
                                        selectedPlayers.length + 1 > 11 - minPlayerlLimit &&
                                        batPlayer.length < 1 &&
                                        activeTab !== 'bat'
                                    ) {
                                        alertErrorMessage(
                                            `You must select atleast 1 Batter.`,
                                        );
                                    } else if (
                                        selectedPlayers.length > 0 &&
                                        selectedPlayers.length + 1 > 11 - minPlayerlLimit &&
                                        arPlayer.length < 1 &&
                                        activeTab !== 'all'
                                    ) {
                                        alertErrorMessage(
                                            `You must select atleast 1 All-rounders.`,
                                        );
                                        return;
                                    } else {
                                        setSelectedPlayers((prevSelectedPlayers) => {
                                            return [...prevSelectedPlayers, player];
                                        });
                                        if (bowlPlayer.length < 1) {
                                            let min = minPlayerlLimit - 1;
                                            setMinPlayerlLimit(min);
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        alertErrorMessage(`You can select only 7 player ${player.title}`);
                    }
                }
                else {
                    alertErrorMessage('11 players selected, tap continue.');
                }
            } else {
                alertErrorMessage('Not enough credits to select this player.');
            }
        }
    }


    const handleCCc = () => {
        const combinedData = {
            ...state,
            selectedPlayers: [...selectedPlayers]
        };
        if (selectedPlayers.length < 11) {
            alertErrorMessage('Please Select Minimum 11th Player')
        } else {
            navigate('/cc_vc', { state: combinedData });
        }
    }

    const handleSelectPlayers = () => {
        const combinedData = {
            ...state,
            selectedPlayers: [...selectedPlayers]
        };
        navigate('/select_players', { state: combinedData });
    }


    const handlePlayeProfile = (playerData, title) => {
        const combinedData = {
            ...state,
            playerProfile: [playerData],
            teamName: title,
            activeTab
        };
        navigate('/player_profile', { state: combinedData });
    }


    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg_img">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span className="hh_icon" onClick={handleBack}><i className="ri-arrow-left-s-line ri-xl cursor-pointer"></i> </span>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className=""><Countdown targetTime={state?.StartDateTime} /></h4>
                            </div>
                        </div>
                    </div>
                    <div className="main_panel match_details px-0">
                        <div className="play_card wbab_card">
                            <div className="wbab_title"> <small>Max 7 player from a team</small> </div>
                            <div className="play_card_center">VS</div>
                            <span className="row g-0 team_row">
                                <div className="col-6">
                                    <div className="team_card team_left team_left_DC">
                                        <h4>{state?.TeamsShortNames[0]}</h4>
                                        <div className="team_tcd "><span>{state?.TeamA}</span>
                                            <img src={state?.TeamAlogo} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="team_card team_right team_right_GT">
                                        <h4>{state?.TeamsShortNames[1]}</h4>
                                        <div className="team_tcd "><span>{state?.TeamB}</span>
                                            <img src={state?.TeamBlogo} />
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <div className="play_footer wbab_footer">
                                <span className="text-start">
                                    <em>{selectedPlayers?.length}/11</em>
                                    <small>Selection</small>
                                </span>
                                <div className="team_badgs text-center">
                                    {[...Array(11)].map((_, index) => (
                                        <span key={index}>
                                            {selectedPlayers.length > index && (
                                                <>
                                                    {selectedPlayers[index].playing_role === "bowl" && (
                                                        <img className="active" src="assets/images/bowl.png" alt="" />
                                                    )}
                                                    {selectedPlayers[index].playing_role === "wk" && (
                                                        <img className="active" src="assets/images/wk.png" alt="" />
                                                    )}
                                                    {selectedPlayers[index].playing_role === "bat" && (
                                                        <img className="active" src="assets/images/bat.png" alt="" />
                                                    )}
                                                    {selectedPlayers[index].playing_role === "all" && (
                                                        <img className="active" src="assets/images/AR.png" alt="" />
                                                    )}
                                                </>
                                            )}
                                        </span>
                                    ))}
                                </div>
                                <span className="text-end">
                                    <em>{creditLimit}</em>
                                    <small>Credit</small>
                                </span>
                            </div>
                        </div>
                        <div className="detals_wrapper">
                            <div className="container">
                                <ul className="details_tab dgetails_4_items btn-gradient border-gradient nav nav-tabs" id="myTab"
                                    role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="btn nav-link active" id="wk-tab" data-bs-toggle="tab" data-bs-target="#wk"
                                            type="button" role="tab" aria-controls="wk" aria-selected="true" onClick={() => setActiveTab('wk')}><span>WK ({selectedWkCount})</span> </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="btn nav-link" id="BAT-tab" data-bs-toggle="tab" data-bs-target="#BAT" type="button"
                                            role="tab" aria-controls="BAT" aria-selected="false" onClick={() => setActiveTab('bat')}> <span>BAT ({selectedBATCount})</span> </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="btn nav-link" id="AR-tab" data-bs-toggle="tab" data-bs-target="#AR" type="button"
                                            role="tab" aria-controls="AR" aria-selected="false" onClick={() => setActiveTab('all')}> <span> AR ({selectedARCount})</span> </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="btn nav-link" id="BOWL-tab" data-bs-toggle="tab" data-bs-target="#BOWL" type="button"
                                            role="tab" aria-controls="BOWL" aria-selected="false" onClick={() => setActiveTab('bowl')}> <span> BOWL ({selectedBOWLCount})</span> </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="team_info">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-3">
                                            <span>INFO</span>
                                        </div>
                                        <div className="col-4 text-start">
                                            <span>PLAYERS</span>
                                        </div>
                                        <div className="col-2">
                                            <span>POINTS</span>
                                        </div>
                                        <div className="col-3">
                                            <span>CREDITS</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content ct_scroll" id="myTabContent">
                                <div className="tab-pane fade show active" id="wk" role="tabpanel" aria-labelledby="wk-tab">
                                    {allPlayrsData.length > 0 &&
                                        allPlayrsData.map((item, index) => {
                                            return item.players.map((player, playerIndex) => {
                                                if (player.playing_role === "wk") {
                                                    return (
                                                        <div className={`player_info ${selectedPlayers.find(p => p.pid === player.pid) ? 'active' : ''}`} key={playerIndex}>
                                                            <div className="container">
                                                                <div className="row align-items-center">
                                                                    <div className="col-3">
                                                                        <span className="cursor-pointer" onClick={() => handlePlayeProfile(player, item.title)}>
                                                                            <img src="assets/images/wicket_keeper.png" width="65" height="80" className="player_img w-100" />
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <span className="player_in_title">
                                                                            <span className="cursor-pointer" onClick={() => handlePlayeProfile(player, item.title)}>{player.short_name}</span>
                                                                            <br />
                                                                            <em className="text-primary cursor-pointer" onClick={() => handlePlayeProfile(player, item.title)}>{item.title}</em>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <h5 className="mb-0">{player.fantasy_player_rating}</h5>
                                                                    </div>
                                                                    <div className="col-1 text-center">
                                                                        <h5 className="mb-0">{player.fantasy_player_rating}</h5>
                                                                    </div>
                                                                    <div className="col-2 text-end">
                                                                        <button
                                                                            className={`btn_add btn-icon ms-auto me-2 ${selectedPlayers.find(p => p.pid === player.pid) ? 'btn-danger' : 'btn-success'}`}
                                                                            onClick={() => { handleAddPlayer(player, item.title, 'wk'); }}
                                                                        >
                                                                            {selectedPlayers.find(p => p.pid === player.pid) ? '-' : '+'}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            });
                                        })}
                                </div>

                                <div className="tab-pane fade" id="BAT" role="tabpanel" aria-labelledby="BAT-tab">
                                    <div className="player_info_row">
                                        {allPlayrsData.length > 0 &&
                                            allPlayrsData.map((item, index) => {
                                                return item.players.map((player, playerIndex) => {
                                                    if (player.playing_role === "bat") {
                                                        return (
                                                            <div className={`player_info ${selectedPlayers.find(p => p.pid === player.pid) ? 'active' : ''}`} key={playerIndex}>
                                                                <div className="container">
                                                                    <div className="row align-items-center" key={playerIndex}>
                                                                        <div className="col-3">
                                                                            <span className="cursor-pointer" onClick={() => handlePlayeProfile(player, item.title)}>
                                                                                <img src="assets/images/batsman.png" width="65" height="80" className="player_img w-100" />
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <span className="player_in_title">
                                                                                <span className="cursor-pointer" onClick={() => handlePlayeProfile(player, item.title)}>{player.short_name}</span>
                                                                                <br />
                                                                                <em className="text-primary cursor-pointer" onClick={() => handlePlayeProfile(player, item.title)}>{item.title}</em>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <h5 className="mb-0">{player?.fantasy_player_rating}</h5>
                                                                        </div>
                                                                        <div className="col-1 text-center">
                                                                            <h5 className="mb-0">{player?.fantasy_player_rating}</h5>
                                                                        </div>
                                                                        <div className="col-2 text-end">
                                                                            <button
                                                                                className={`btn_add btn-icon ms-auto me-2 ${selectedPlayers.find(p => p.pid === player.pid) ? 'btn-danger' : 'btn-success'}`}
                                                                                onClick={() => { handleAddPlayer(player, item.title, 'bat'); }}
                                                                            >
                                                                                {selectedPlayers.find(p => p.pid === player.pid) ? '-' : '+'}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                });
                                            })}
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="AR" role="tabpanel" aria-labelledby="AR-tab">
                                    <div className="player_info_row">
                                        {allPlayrsData.length > 0 &&
                                            allPlayrsData.map((item, index) => {
                                                return item.players.map((player, playerIndex) => {
                                                    if (player.playing_role === "all") {
                                                        return (
                                                            <div className={`player_info ${selectedPlayers.find(p => p.pid === player.pid) ? 'active' : ''}`} key={playerIndex}>
                                                                <div className="container">
                                                                    <div className="row align-items-center" key={playerIndex}>
                                                                        <div className="col-3">
                                                                            <span className="cursor-pointer" onClick={() => handlePlayeProfile(player, item.title)}>
                                                                                <img src="assets/images/all_rounder.png" width="65" height="80" className="player_img w-100" />
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <span className="player_in_title">
                                                                                <span className="cursor-pointer" onClick={() => handlePlayeProfile(player, item.title)}>{player.short_name}</span>
                                                                                <br />
                                                                                <em className="text-primary cursor-pointer" onClick={() => handlePlayeProfile(player, item.title)}>{item.title}</em>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <h5 className="mb-0">{player?.fantasy_player_rating}</h5>
                                                                        </div>
                                                                        <div className="col-1 text-center">
                                                                            <h5 className="mb-0">{player?.fantasy_player_rating}</h5>
                                                                        </div>
                                                                        <div className="col-2 text-end">
                                                                            <button
                                                                                className={`btn_add btn-icon ms-auto me-2 ${selectedPlayers.find(p => p.pid === player.pid) ? 'btn-danger' : 'btn-success'}`}
                                                                                onClick={() => { handleAddPlayer(player, item.title, 'all'); }}
                                                                            >
                                                                                {selectedPlayers.find(p => p.pid === player.pid) ? '-' : '+'}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                });
                                            })}
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="BOWL" role="tabpanel" aria-labelledby="BOWL-tab">
                                    <div className="player_info_row">
                                        {allPlayrsData.length > 0 &&
                                            allPlayrsData.map((item, index) => {
                                                return item.players.map((player, playerIndex) => {
                                                    if (player.playing_role === "bowl") {
                                                        return (
                                                            <div className={`player_info ${selectedPlayers.find(p => p.pid === player.pid) ? 'active' : ''}`} key={playerIndex}>
                                                                <div className="container">
                                                                    <div className="row align-items-center" key={playerIndex}>
                                                                        <div className="col-3">
                                                                            <span className="cursor-pointer" onClick={() => handlePlayeProfile(player, item.title)}>
                                                                                <img src="assets/images/bowler.png" width="65" height="80" className="player_img w-100" />
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <span className="player_in_title">
                                                                                <span className="cursor-pointer" onClick={() => handlePlayeProfile(player, item.title)}>{player.short_name}</span>
                                                                                <br />
                                                                                <em className="text-primary cursor-pointer" onClick={() => handlePlayeProfile(player, item.title)}>{item.title}</em>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <h5 className="mb-0">{player?.fantasy_player_rating}</h5>
                                                                        </div>
                                                                        <div className="col-1 text-center">
                                                                            <h5 className="mb-0">{player?.fantasy_player_rating}</h5>
                                                                        </div>
                                                                        <div className="col-2 text-end">
                                                                            <button
                                                                                className={`btn_add btn-icon ms-auto me-2 ${selectedPlayers.find(p => p.pid === player.pid) ? 'btn-danger' : 'btn-success'}`}
                                                                                onClick={() => { handleAddPlayer(player, item.title, 'bowl'); }}
                                                                            >
                                                                                {selectedPlayers.find(p => p.pid === player.pid) ? '-' : '+'}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                });
                                            })}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- navigations --> */}
                <div className="nav_footer nav_footer_transperant">
                    <div className="container">
                        <div className="row gx-2">
                            <div className="col-6">
                                <button className="custom-btn btn-border-gradient  btn-block w-100 text-uppercase" onClick={handleSelectPlayers}>
                                    <span>Preview</span>
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase" onClick={handleCCc}>
                                    <span> Continue</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <!-- filter modal --> */}
                <div className="modal filter_modal" id="filterModal" tabIndex="-1" aria-labelledby="filterModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="filterModalLabel">Filter</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body p-0">
                                <div className="filter_row">
                                    <em>Entry</em>
                                    <button>$1 - $50</button>
                                    <button className="active">$51 - $100</button>
                                    <button>$101 - $1000</button>
                                    <button>$101 - $1000</button>
                                </div>
                                <div className="filter_row">
                                    <em>Number of Teams</em>
                                    <button>1</button>
                                    <button className="active">3 - 10</button>
                                    <button>11 - 100</button>
                                    <button>101 - 1,000</button>
                                    <button>$1001 & above</button>
                                </div>
                                <div className="filter_row">
                                    <em>Prize Pool</em>
                                    <button>$1 - $50</button>
                                    <button className="active">$51 - $100</button>
                                    <button>$101 - $1000</button>
                                    <button>$101 - $1000</button>
                                </div>
                                <div className="filter_row">
                                    <em>Contest Type</em>
                                    <button>Single Entry</button>
                                    <button className="active">Single Winner</button>
                                    <button>Multi Entry</button>
                                    <button>Multi Winner</button>
                                    <button>Guranteed</button>
                                    <button>Flexible</button>
                                </div>
                            </div>
                            <div className="modal-footer d-block">
                                <div className="row gx-2">
                                    <div className="col-6">
                                        <button type="button" className="custom-btn btn-border-gradient  btn-block w-100"
                                            data-bs-dismiss="modal">Clear</button>
                                    </div>
                                    <div className="col-6">
                                        <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-block w-100"> Apply
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- modal filter end --> */}


                {/*  <!-- modal notifications --> */}
                <div className="modal filter_modal" id="notificationsModal" tabIndex="-1" aria-labelledby="notificationsModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="notificationsModalLabel"> <i className="ri-notification-4-fill me-2"></i> Set
                                    Match Reminder</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body p-0">

                                <div className="filter_row  d-flex justify-content-between align-items-center">
                                    <div className="pe-2">
                                        <em>Match - GT vs DC</em>
                                        <p>will send reminder when lineup announced</p>
                                    </div>
                                    <label className="switch" htmlFor="check_1"><input type="checkbox" id="check_1" value="true" /><span
                                        className="slider"></span></label>
                                </div>
                                <div className="filter_row  d-flex justify-content-between align-items-center">
                                    <div className="pe-2">
                                        <em>Indian Premier League</em>
                                        <p>will send reminder when lineup announced in every match</p>
                                    </div>
                                    <label className="switch" htmlFor="check_2"><input type="checkbox" id="check_2" value="true" /><span
                                        className="slider"></span></label>
                                </div>
                            </div>
                            <div className="modal-footer d-block">
                                <div className="row gx-2">
                                    <div className="col-6">
                                        <button type="button" className="custom-btn btn-border-gradient  btn-block w-100"
                                            data-bs-dismiss="modal">Clear</button>
                                    </div>
                                    <div className="col-6">
                                        <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-block w-100"> Apply
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- right view --> */}
            <RightViewContent />
            {/*  <!-- right view end --> */}
        </div>
    )
}

export default CreateTeam

