import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import { $ } from "react-jquery-plugin";
import { alertErrorMessage, alertSuccessMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import AuthService from "../../AuthService";
import Countdown from "../../customComponent/CountDown";

const SingleMatch = (prop) => {

    const [contestDetails, setContestDetails] = useState([]);
    const [myTeamsDetails, setMyTeamsDetails] = useState([]);
    const [myContestDetails, setMyContestDetails] = useState([]);
    const [checkOne, setCheckOne] = useState(false);
    const [checkTwo, setCheckTwo] = useState(false);
    const [entryFee, setEntryFee] = useState([]);

    const [selectedTab, setSelectedTab] = useState(1); 
    const [sortBy, setSortBy] = useState(''); 
    const [sortDirection, setSortDirection] = useState('asc'); //asc, desc 

    const setSortingAndDirection = (e) => {
        if (e === 'change') {
            setSortBy('');
            setSortDirection('asc');
        } else {
            if (sortBy === e) {
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
            } else {
                setSortBy(e);
                setSortDirection('asc');
            }
        }
        
    }
    
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        if (state?._id) {
            handleContestDetails();
            handleMycontest();
            handleMyTeams();
        }
    }, [state]);

    useEffect(() => {
        if (sortBy && sortDirection) {
            // console.log(selectedTab, '=======', sortBy, sortDirection);
            if (selectedTab === 1) {
                handleContestDetails();
            } else if (selectedTab === 2) {
                handleMycontest();
            } else if (selectedTab === 3) {
                handleMyTeams();
            }
            
        }
    }, [sortBy, sortDirection]);

    const handleTabChange = (e) => {
        setSelectedTab(e);
        setSortingAndDirection('change');
    }

    const handleContestDetails = async () => {
        await AuthService.getContestDetails(state?._id, sortBy, sortDirection).then(async result => {
            if (result.success) {
                try {
                    setContestDetails(result?.data?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    }

    const handleMycontest = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getMyContestDetails(state?._id, sortBy, sortDirection).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setMyContestDetails(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }

    const handleMyTeams = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getMyTeamsDetails(state?._id, sortBy, sortDirection).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setMyTeamsDetails(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }


    const handleMatchReminder = async (checkOne, checkTwo) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.matchReminder(state?._id, state?.MatchId, state?.SeriesId, checkOne, checkTwo).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(result?.message)
                    $("#notificationsModal").modal('hide');
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }


    const handleFilterModel = () => {
        $("#filterModal").modal('hide');
    }

    const handleBack = () => {
        navigate('/home');
    }

    const handleCreateTeam = (item) => {
        setEntryFee(item)
        if (item?.EnteryFee > prop?.userDetails?.ibat_balance) {
            $("#confirmationModal").modal('show');
        } else {
            $("#confirmationModal").modal('hide');
            const combinedData = {
                state,
                winingItem: item
            };
            if (myTeamsDetails.length === 0) {
                navigate('/create_team', { state: state });
            } else if (myTeamsDetails.length > 0) {
                navigate('/create_multiple_team', { state: combinedData });
            } else {
                navigate('/winning_leaderboard', { state: combinedData });
            }
        }

    }

    const handleCreateCoustamTeam = () => {
        navigate('/create_team', { state: state });
    }

    const handleWinningLeaderBoard = (item) => {
        const combinedData = {
            state,
            winingItem: item
        };
        navigate('/winning_leaderboard', { state: combinedData });
    }

    const handleCreateContest = () => {
        navigate('/create_contest', { state: state });
    }

    const handleAddBalance = () => {
        const combinedData = {
            ...state,
            ...entryFee
        };
        $("#confirmationModal").modal('hide');
        navigate('/add_money', { state: combinedData });
    }

    const handleEditTeam = (item) => {
        const combinedData = {
            ...state,
            teamDetails: [item],
            editCreatedTeam: true,
        };
        navigate('/create_team', { state: combinedData });
    }

    const handleEditAndCreateTeam = (item) => {
        const combinedData = {
            ...state,
            teamDetails: [item],
            editCreatedTeam: false,
        };
        navigate('/create_team', { state: combinedData });
    }



    function handleShareWhatsApp() {
        const shareText = `Check out my code: ${prop?.userDetails?.refercode}!`;
        const shareUrl = `https://api.ibatfantasysports.com/share?code=${encodeURIComponent(prop?.userDetails?.refercode)}`;

        const encodedShareText = encodeURIComponent(shareText + ' ' + shareUrl);
        const shareLink = `https://web.whatsapp.com/send?text=${encodedShareText}`;

        const shareWindow = window.open(shareLink, '_blank');
        if (!shareWindow) {
            alert('Please disable your popup blocker to share the page.');
        }
    }

    console.log(myContestDetails, 'myContestDetailsmyContestDetails');

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg_img">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span className="hh_icon cursor-pointer" onClick={handleBack}><i className="ri-arrow-left-s-line ri-xl"></i> </span>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className=""><Countdown targetTime={state?.StartDateTime} /></h4>
                            </div>
                            <div className="col-3  text-end">
                                <div className="hh_row">
                                    <Link to="#" data-bs-toggle="modal" data-bs-target="#notificationsModal" className="hh_icon"> <i
                                        className="ri-notification-4-fill"></i> </Link>
                                    <Link to="/my_balance" className="hh_icon"> <i className="ri-wallet-3-fill"></i> </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main_panel match_details px-0">
                        <div className="play_card">
                            <div className="leauge_name"> <span>{state?.SeriesshortName}</span> </div>
                            <div className="play_card_center"><span className="matcs_status">VS</span> </div>
                            <span className="row g-0 team_row">
                                <div className="col-6">
                                    <div className="team_card team_left team_left_mi">
                                        <h4>{state?.TeamsShortNames?.[0]}</h4>
                                        <div className="team_tcd "><span>{state?.TeamA}</span>
                                            <img src={state?.TeamAlogo} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="team_card team_right team_right_SRH">
                                        <h4>{state?.TeamsShortNames?.[1]}</h4>
                                        <div className="team_tcd "><span>{state?.TeamB}</span>
                                            <img src={state?.TeamBlogo} />
                                        </div>
                                    </div>
                                </div>
                            </span>
                            {selectedTab !==3 && (<div className="filter_footer p-0 play_footer">
                                <div className="short_cc">
                                    Sort By:
                                    <div className="cc_scroll swiper filter_scroll">
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide ss_slide"><span onClick={()=>setSortingAndDirection('ENTRY')} className={sortBy === "ENTRY" ? "sorting_cus ss_cc cursor-pointer" : "ss_cc cursor-pointer"}>ENTRY</span></div>
                                            <div className="swiper-slide ss_slide"><span onClick={()=>setSortingAndDirection('SPOTS')} className={sortBy === "SPOTS" ? "sorting_cus ss_cc cursor-pointer" : "ss_cc cursor-pointer"}>SPOTS</span></div>
                                            <div className="swiper-slide ss_slide"><span onClick={()=>setSortingAndDirection('PRIZE')} className={sortBy === "PRIZE" ? "sorting_cus ss_cc cursor-pointer" : "ss_cc cursor-pointer"}>PRIZE POOL</span></div>
                                            <div className="swiper-slide ss_slide"><span onClick={()=>setSortingAndDirection('WINNERS')} className={sortBy === "WINNERS" ? "sorting_cus ss_cc cursor-pointer" : "ss_cc cursor-pointer"}>%WINNERS</span></div>
                                        </div>
                                    </div>
                                    {/*  <Link to="#" data-bs-toggle="modal" data-bs-target="#filterModal" className="play-filter text-white"><i
                                        className="ri-filter-fill"></i></Link> */}
                                </div>
                            </div>)}
                        </div>
                        <div className="detals_wrapper">
                            <div className="container">
                                <ul className="details_tab btn-gradient border-gradient nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="btn nav-link active" id="Contest-tab" data-bs-toggle="tab" onClick={()=>handleTabChange(1)} data-bs-target="#Contest"
                                            type="button" role="tab" aria-controls="Contest" aria-selected="true"><span>Contest</span> </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="btn nav-link" id="mycontest-tab" data-bs-toggle="tab" onClick={()=>handleTabChange(2)} data-bs-target="#mycontest"
                                            type="button" role="tab" aria-controls="mycontest" aria-selected="false"> <span>My Contest({myContestDetails.length})</span>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="btn nav-link" id="Team-tab" data-bs-toggle="tab" onClick={()=>handleTabChange(3)} data-bs-target="#Team" type="button"
                                            role="tab" aria-controls="Team" aria-selected="false"> <span>My Team({myTeamsDetails.length})</span> </button>
                                    </li>
                                </ul>

                                <div className="tab-content ms_scroll" id="myTabContent">
                                    <div className="tab-pane fade show active" id="Contest" role="tabpanel" aria-labelledby="Contest-tab">
                                        {contestDetails.length > 0
                                            ? contestDetails.map((item, index) => {
                                                return (
                                                    <span key={index}>
                                                        <div className="sc_title" > <h4>{item?.name}</h4> </div>
                                                        {item?.data
                                                            ? item?.data.map((contest, index) => (
                                                                <div className="mc_card" key={index}>
                                                                    <div className="mc_body row">
                                                                        <div className="mc_left col-8 cursor-pointer" onClick={() => handleWinningLeaderBoard(contest)}>
                                                                            <span>PRIZE POOL</span>
                                                                            <div className="d-flex mt-2 align-items-center">
                                                                                <h3 className="mb-0">{contest?.WinningAmount} IBAT</h3>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mc_right  col-4 text-end" onClick={() => handleCreateTeam(contest)}>
                                                                            {contest?.JoinWithMULT === true ? <span>Multiple Entries</span> : <span>Single Entries</span>}
                                                                            <span className="mc_rate btn btn-sm btn-success ms-auto cursor-pointer px-2" onClick={() => handleCreateTeam(contest)}>
                                                                                <img src="assets/images/favicon.ico"  height={20} className="me-2" style={{filter:'invert(1)'}}/>
                                                                                {contest?.EnteryFee}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mc_progressbar cursor-pointer">
                                                                        <div className="bar" style={{ width: '70%' }}></div>
                                                                    </div>
                                                                    <div className="progress_data cursor-pointer">
                                                                        <span>{contest?.Contestsize} spots</span>
                                                                        <span className="text-success">{contest?.Contestsize - contest?.joined} spots left</span>
                                                                    </div>
                                                                    <div className="mc_footer">
                                                                        <div className="d-flex align-items">
                                                                            <span className="ms_badges">
                                                                                <img src="assets/images/win.png" width="24" height="24" />
                                                                                IBAT {contest?.Rankdata[0]?.Price}
                                                                            </span>
                                                                            <span className="ms_badges">
                                                                                <img src="assets/images/trophy.png" width="24" height="24" />
                                                                                {contest?.Rankdata[0]?.PercentageEach}%
                                                                            </span>
                                                                            <span className="ms_badges">
                                                                                {
                                                                                    contest?.JoinWithMULT === true ?
                                                                                        <> <img src="assets/images/multigroup.png" width="24" height="24" />Upto 7 </>
                                                                                        :
                                                                                        <><img src="assets/images/single.png" width="24" height="24" />Single </>
                                                                                }
                                                                            </span>
                                                                        </div>

                                                                        <div className="d-flex align-items">
                                                                            <span className="ms_badges">
                                                                                <img src="assets/images/checkmark.png" width="24" height="24" />
                                                                                Guranteed
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                            : null}
                                                    </span>
                                                )
                                            }) : null}
                                    </div>

                                    <div className="tab-pane fade" id="mycontest" role="tabpanel" aria-labelledby="mycontest-tab">
                                        {myContestDetails.length > 0
                                            ? myContestDetails.map((item, index) => {
                                                return (
                                                    <div className="mc_card" key={index}>
                                                        <div className="mc_body row">
                                                            <div className="mc_left col-8 cursor-pointer" onClick={() => handleWinningLeaderBoard(item)}>
                                                                <span>PRIZE POOL</span>
                                                                <div className="d-flex mt-2 align-items-center">
                                                                    <h3 className="mb-0">IBAT {item?.contest_details?.winning_amount} </h3>
                                                                </div>
                                                            </div>
                                                            <div className="mc_right  col-4 text-end">
                                                                {item?.data?.JoinWithMULT == true ? <span>Multiple Entries</span> : <span>Single Entries</span>}
                                                                <span className="mc_rate btn btn-sm btn-success ms-auto cursor-pointer cursor-pointer" onClick={() => handleWinningLeaderBoard(item)}>
                                                                    IBAT {item?.data?.EnteryFee}
                                                                </span>

                                                            </div>
                                                        </div>
                                                        <div className="mc_progressbar">
                                                            <div className="bar" style={{ width: '40%' }}></div>
                                                        </div>
                                                        <div className="progress_data">
                                                            <span>{item?.data?.Contestsize} spots</span>
                                                            <span className="text-success">{item?.data?.Contestsize - item?.contest_details?.joined} spots left</span>
                                                        </div>
                                                        <div className="mc_footer">
                                                            <div className="d-flex align-items">
                                                                <span className="ms_badges"> JOINED WITH {item?.joined_with} TEAM</span>
                                                            </div>
                                                            <div className="d-flex align-items">
                                                                <span className="ms_badges ms_badges_jwt mx-1"> T{item?.joined_with}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) : <div className="no_data"><span>No Data Found</span></div>}
                                    </div>

                                    <div className="tab-pane fade" id="Team" role="tabpanel" aria-labelledby="Team-tab">
                                        {Object.keys(myTeamsDetails).length > 0 ? (
                                            myTeamsDetails.map((item, index) => {
                                                const TeamA = item.players.filter((player) => `${player?.primary_team?.abbr} ` === state?.TeamsShortNames?.[0]);
                                                const TeamB = item.players.filter((player) => player?.primary_team?.abbr === state?.TeamsShortNames?.[1]);
                                                return (
                                                    <div className="mc_card team_box" key={index}>
                                                        <div className="mc_body row align-items-center">
                                                            <div className="mc_left col-6">
                                                                <span className="text-white">{item?.name}</span>
                                                            </div>

                                                            <div className="mc_right  col-6 text-end">
                                                                <div className="team_edit">
                                                                    <button className="mc_icon hh_icon" type="button" onClick={() => handleEditTeam(item)}>
                                                                        <i className="ri-edit-2-line"></i>
                                                                    </button>
                                                                    <button className="mc_icon hh_icon" type="button" onClick={() => handleEditAndCreateTeam(item)}>
                                                                        <i className="ri-file-copy-line"></i>
                                                                    </button>
                                                                    <button className="mc_icon hh_icon" onClick={handleShareWhatsApp}>
                                                                        <i className="ri-share-line"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mc_team">
                                                            <div className="d-inline-flex">
                                                                <span> <h4>{TeamA?.length}</h4> {state?.TeamsShortNames?.[0]} </span>
                                                                <span> <h4>{TeamB?.length}</h4>{state?.TeamsShortNames?.[1]}</span>
                                                            </div>
                                                            <div className="team_bar d-flex">
                                                                {item?.players
                                                                    ? item.players
                                                                        .filter((contest) => contest.caption)
                                                                        .map((contest, index) => (
                                                                            <div className="player_ico" key={index}>
                                                                                <span className="player_badge">C</span>
                                                                                <img src="assets/images/player_1.png" width="80" height="98" />
                                                                                <div className="player_name">
                                                                                    {contest?.first_name.split(' ')[0].charAt(0)} {contest?.first_name.split(' ')[contest?.first_name.split(' ').length - 1]}
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    : null
                                                                }
                                                                {item?.players
                                                                    ? item.players
                                                                        .filter((contest) => contest.vice_caption)
                                                                        .map((contest, index) => (
                                                                            <div className="player_ico" key={index}>
                                                                                <span className="player_badge">VC</span>
                                                                                <img src="assets/images/player_1.png" width="80" height="98" />
                                                                                <div className="player_name">
                                                                                    {contest?.first_name.split(' ')[0].charAt(0)} {contest?.first_name.split(' ')[contest?.first_name.split(' ').length - 1]}
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    : null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="mc_footer">
                                                            {item?.players
                                                                ? (() => {
                                                                    const filteredArray = item.players.filter((player) => player?.playing_role === "wk");
                                                                    const length = filteredArray.length;
                                                                    return (
                                                                        <span className="ms_badges text-white">
                                                                            <small className="me-2">WK</small>({length})
                                                                        </span>

                                                                    );
                                                                })()
                                                                : null}

                                                            {item?.players
                                                                ? (() => {
                                                                    const filteredArray = item.players.filter((player) => player?.playing_role === "bat");
                                                                    const length = filteredArray.length;
                                                                    return (
                                                                        <span className="ms_badges text-white">
                                                                            <small className="me-2">BAT</small>({length})
                                                                        </span>

                                                                    );
                                                                })()
                                                                : null}

                                                            {item?.players
                                                                ? (() => {
                                                                    const filteredArray = item.players.filter((player) => player?.playing_role === "all");
                                                                    const length = filteredArray.length;
                                                                    return (
                                                                        <span className="ms_badges text-white">
                                                                            <small className="me-2">AR</small>({length})
                                                                        </span>

                                                                    );
                                                                })()
                                                                : null}

                                                            {item?.players
                                                                ? (() => {
                                                                    const filteredArray = item.players.filter((player) => player?.playing_role === "bowl");
                                                                    const length = filteredArray.length;
                                                                    return (
                                                                        <span className="ms_badges text-white">
                                                                            <small className="me-2">BOWL</small>({length})
                                                                        </span>

                                                                    );
                                                                })()
                                                                : null}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : <div className="no_data"><span>No Team Found</span></div>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- navigations --> */}
                <div className="nav_footer">
                    <div className="container">
                        <div className="row gx-2">
                            <div className="col-6">
                                <button className="custom-btn btn-border-gradient  btn-block w-100 text-uppercase" onClick={handleCreateContest}>
                                    <span>Create contest</span>
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase" onClick={handleCreateCoustamTeam}>
                                    <span>create team</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/*confirmationModal Model */}
                <div className="modal filter_modal" id="confirmationModal" tabIndex="-1" aria-labelledby="confirmationModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center" id="confirmationModalLabel"> Confirmation</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <ul className="pay_list">
                                    <li className="main_pay">
                                        <strong>Entry</strong>
                                        <span>{entryFee?.EnteryFee}</span>
                                    </li>
                                    <li className="">
                                        <p>Current IBAT Balance</p>
                                        <span>{prop?.userDetails?.ibat_balance}</span>
                                    </li>
                                    <li className="">
                                        <p>Usable Cash Bonus</p>
                                        <span>{prop?.userDetails?.cash_bonus}</span>
                                    </li>
                                    <li className="total_pay">
                                        <p>To Pay IBAT</p>
                                        <p>{entryFee?.EnteryFee}</p>
                                    </li>
                                </ul>

                            </div>
                            <div className="modal-footer d-block">
                                <div className="row gx-2 justify-content-center">
                                    <div className="col-6">
                                        <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-block w-100" onClick={handleAddBalance}>Add Money</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- filter modal --> */}
                <div className="modal filter_modal" id="filterModal" tabIndex="-1" aria-labelledby="filterModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="filterModalLabel">Filter</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body p-0">
                                <div className="filter_row">
                                    <em>Entry</em>
                                    <button>$1 - $50</button>
                                    <button className="active">$51 - $100</button>
                                    <button>$101 - $1000</button>
                                    <button>$101 - $1000</button>
                                </div>
                                <div className="filter_row">
                                    <em>Number of Teams</em>
                                    <button>1</button>
                                    <button className="active">3 - 10</button>
                                    <button>11 - 100</button>
                                    <button>101 - 1,000</button>
                                    <button>$1001 & above</button>
                                </div>
                                <div className="filter_row">
                                    <em>Prize Pool</em>
                                    <button>$1 - $50</button>
                                    <button className="active">$51 - $100</button>
                                    <button>$101 - $1000</button>
                                    <button>$101 - $1000</button>
                                </div>
                                <div className="filter_row">
                                    <em>Contest Type</em>
                                    <button>Single Entry</button>
                                    <button className="active">Single Winner</button>
                                    <button>Multi Entry</button>
                                    <button>Multi Winner</button>
                                    <button>Guranteed</button>
                                    <button>Flexible</button>
                                </div>
                            </div>
                            <div className="modal-footer d-block">
                                <div className="row gx-2">
                                    <div className="col-6">
                                        <button type="button" className="custom-btn btn-border-gradient  btn-block w-100"
                                            data-bs-dismiss="modal">Clear</button>
                                    </div>
                                    <div className="col-6">
                                        <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-block w-100" onClick={handleFilterModel}>
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*  <!-- modal notifications --> */}
                <div className="modal filter_modal" id="notificationsModal" tabIndex="-1" aria-labelledby="notificationsModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="notificationsModalLabel">
                                    <i className="ri-notification-4-fill me-2"></i>
                                    Set Match Reminder
                                </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body p-0">
                                <div className="filter_row d-flex justify-content-between align-items-center">
                                    <div className="pe-2">
                                        <em>Match - {state?.TeamsShortNames?.[0]} vs {state?.TeamsShortNames?.[1]}</em>
                                        <p>will send reminder when lineup announced</p>
                                    </div>
                                    <label className="switch" htmlFor="check_1">
                                        <input type="checkbox" id="check_1" checked={checkOne} onChange={(e) => setCheckOne(e.target.checked)} />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                                <div className="filter_row d-flex justify-content-between align-items-center">
                                    <div className="pe-2">
                                        <em>{state?.SeriesName}</em>
                                        <p>will send reminder when lineup announced in every match</p>
                                    </div>
                                    <label className="switch" htmlFor="check_2">
                                        <input type="checkbox" id="check_2" checked={checkTwo} onChange={(e) => setCheckTwo(e.target.checked)} />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="modal-footer d-block">
                                <div className="row gx-2">
                                    <div className="col-6">
                                        <button type="button" className="custom-btn btn-border-gradient  btn-block w-100"
                                            data-bs-dismiss="modal">Clear</button>
                                    </div>
                                    <div className="col-6">
                                        <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-block w-100" onClick={() => handleMatchReminder(checkOne, checkTwo)}>
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <RightViewContent />
        </div >
    )
}

export default SingleMatch

