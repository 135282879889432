
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import { alertErrorMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import AuthService from "../../AuthService";
import { ProfileContext } from "../../context/ProfileProvider";
const MyBalance = (props) => {

    const navigate = useNavigate();
    const [kycDetails, setKycDetails] = useState([]);
    const { handleUserProfile } = useContext(ProfileContext);

    let address =""

    useEffect(() => {
        if (address) {
            handleWalletAddress();
        }
        handlegetKycDetails();
    }, [address, props?.userDetails?.wallet_address]);

    const handleWalletAddress = async () => {
        await AuthService.sendWalletAddress(address).then(async result => {
            if (result.success) {
                try {
                    handleUserProfile();
                } catch (error) {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    }

    const handleBackProfile = () => {
        navigate('/profile');
    }

    const handlePaymentScreen = () => {
        if (props?.userDetails?.wallet_address) {
            navigate('/payment');
        } else {
            alertErrorMessage('Please Connect Your Wallet')
        }
    }

    const handleKycDetails = () => {
        navigate('/kyc_details', { state: kycDetails });
    }

    const handleTransactionHistory = () => {
        navigate('/transtion_history');
    }

    const handleDepositIbat = () => {
        navigate('/deposit');
    }

    const handleWithdrawIbat = () => {
        navigate('/withdraw_ibat');
    }

    const handleCreateWallet = () => {
        navigate('/create_wallet');
    }

    const handleWithdraw = () => {
        if (kycDetails?.bank_verified === 0 || kycDetails?.email_verified === 0 || kycDetails?.mobile_verified === 0 || kycDetails?.pan_verified === 0) {
            alertErrorMessage('Please Verify Your Kyc Details')
        } else {
            navigate('/withdraw', { state: kycDetails });
        }
    }

    const handlegetKycDetails = async () => {
        await AuthService.getKycDetails().then(async result => {
            if (result.success) {
                try {
                    setKycDetails(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg-gradient">
                    <div className="my_balance_wrapper mb-4">
                        <div className="header_wrapper">
                            <div className="row align-items-center">
                                <div className="col-3 text-start">
                                    <span onClick={handleBackProfile} className="hh_icon cursor-pointer"><i className="ri-arrow-left-s-line ri-xl"></i> </span>
                                </div>
                                <div className="col-6  text-center">
                                    <h4 className="">My Balance</h4>
                                </div>
                                <div className="col-3  text-end">
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-4">
                                <div className="">
                                    <span>Your Total Balance</span>
                                    <div className="h3 mb-0">${props?.userDetails?.total_balance ? props?.userDetails?.total_balance.toFixed(2): 0}</div>
                                </div>
                                {/* <div className="hh_row">
                                    <Link to="/payment" className="p-2 px-3 btn-sm btn-gradient text-white text-uppercase border">add cash</Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0 bal_scroll">
                        <div className="container">
                            <div className="po_row">
                                <div className="pay_card mb-3">
                                    {props?.userDetails?.wallet_address && (
                                        <>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items">
                                                    <div className="po_icon">
                                                        <img src="assets/images/ibat_img.png" alt="IBAT" />
                                                    </div>
                                                    <div className="po_txt ps-3">
                                                        <h5 className="text-white">Deposit And Withdrawal</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-3 gx-2">
                                                <div className="col-6">
                                                    <button
                                                        className="custom-btn btn-mini btn-border-gradient btn-block w-100 text-uppercase"
                                                        onClick={handleDepositIbat}
                                                    >
                                                        <span>Deposit</span>
                                                    </button>
                                                </div>
                                                <div className="col-6">
                                                    <button
                                                        className="custom-btn btn-mini btn-border-gradient btn-gradient btn-block w-100 text-uppercase"
                                                        onClick={handleWithdrawIbat}
                                                    >
                                                        <span>Withdraw</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {/* Wallet Connection and Create Wallet section */}
                                    <div className="row mt-3 gx-2">
                                        {/* Conditional rendering based on centralized.type */}
                                       {/*  {props?.userDetails?.type !== 'centralized' && (
                                            <div className="col">
                                                <span className="custom-btn btn-mini cc_wallet btn-border-gradient btn-block w-100 text-uppercase">
                                                   CONNECT WALLET
                                                </span>
                                            </div>
                                        )} */}

                                        {/* Conditional rendering based on Dcentralized.type */}
                                        {!props?.userDetails?.wallet_address && (
                                            <div className="col">
                                                <button className="custom-btn btn-mini btn-border-gradient btn-gradient btn-block w-100 text-uppercase" onClick={handleCreateWallet}>
                                                    <span>Create Wallet</span>
                                                </button>
                                            </div>
                                        )}

                                    </div>
                                </div>
                                <div className="pay_card mb-2 p-0 ">
                                    <ul className="pay_list">
                                        <li className=" text-white">
                                            <div>
                                                <span>Total IBAT Balance</span>
                                                <br />
                                                <strong className="h4">{props?.userDetails?.ibat_balance ? props?.userDetails?.ibat_balance.toFixed(2): 0}</strong>
                                            </div>
                                        </li>
                                        <li className=" text-white">
                                            <div>
                                                <span>Winnings</span>
                                                <br />
                                                <strong className="h4">{props?.userDetails?.winning_amount}</strong>
                                            </div>
                                            {/* {kycDetails?.bank_verified === 1 && kycDetails?.email_verified === 1 && kycDetails?.mobile_verified === 1 && kycDetails?.pan_verified === 1 ? 
                                                <button
                                                    className="custom-btn btn-mini btn-border-gradient text-uppercase"
                                                    onClick={handleWithdrawIbat}
                                                >
                                                    <span>Withdraw</span>
                                                </button> : <button
                                                    className="custom-btn btn-mini btn-border-gradient text-uppercase"
                                                    onClick={handleKycDetails}
                                                >
                                                    <span>Verify KYC</span>
                                                </button>
                                            } */}
                                            {/* <button className="custom-btn btn-mini btn-border-gradient text-uppercase" onClick={handleWithdrawIbat}  >
                                                <span> Withdraw</span>
                                            </button> */}
                                        </li>
                                        <li className=" text-white ">
                                            <div>
                                                <span>Cash Bonus</span>
                                                <br />
                                                <strong className="h4">{props?.userDetails?.cash_bonus}</strong>
                                            </div>
                                            {/* {kycDetails?.bank_verified === 1 && kycDetails?.email_verified === 1 && kycDetails?.mobile_verified === 1 && kycDetails?.pan_verified === 1 ? 
                                                <button
                                                    className="custom-btn btn-mini btn-border-gradient text-uppercase"
                                                    onClick={handleWithdrawIbat}
                                                >
                                                    <span>Withdraw</span>
                                                </button> : <button
                                                    className="custom-btn btn-mini btn-border-gradient text-uppercase"
                                                    onClick={handleKycDetails}
                                                >
                                                    <span>Verify KYC</span>
                                                </button>
                                            } */}
                                            {/* <button className="custom-btn btn-mini btn-border-gradient text-uppercase" onClick={handleWithdrawIbat}  >
                                                <span> Withdraw</span>
                                            </button> */}
                                        </li>
                                    </ul>
                                </div>
                                <span className="po_card pointer mb-2" onClick={handleTransactionHistory}>
                                    <div className="d-flex align-items">
                                        <div className="po_icon">
                                            <img src="assets/images/transtion_history.png" />
                                        </div>
                                        <div className="po_txt ps-3">
                                            <h5 className="text-white"><small>Transaction History </small></h5>
                                        </div>
                                    </div>
                                </span>
                                <span className="po_card pointer mb-2 cursor-pointer" onClick={handleKycDetails}>
                                    <div className="d-flex align-items">
                                        <div className="po_icon">
                                            <img src="assets/images/kyc_details.png" />
                                        </div>
                                        <div className="po_txt ps-3">
                                            <h5 className="text-white"><small>KYC Details </small></h5>
                                        </div>
                                    </div>
                                </span>
                                <Link to="/manage_payments" className="po_card pointer  mb-2">
                                    <div className="d-flex align-items">
                                        <div className="po_icon">
                                            <img src="assets/images/manage_payments.png" />
                                        </div>
                                        <div className="po_txt ps-3">
                                            <h5 className="text-white"><small>Manage Payments</small></h5>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div >
    )
}

export default MyBalance

