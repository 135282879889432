
import React, { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import { alertErrorMessage, alertSuccessMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import AuthService from "../../AuthService";

const VerifyMobile = () => {

    const userId = localStorage.getItem('_id');

    const navigate = useNavigate();
    const [mobileNumber, setMobileNumber] = useState("");
    const [otp, setOtp] = useState("");


    const handleBackKycDetails = () => {
        navigate("/kyc_details");
    }

    const handleGetVerifyOtp = async (mobileNumber) => {
        if (!mobileNumber) {
            alertErrorMessage('Please Enter Your Mobile Number')
        }
        else {
            LoaderHelper.loaderStatus(true);
            await AuthService.getOtpVerifyEmail(mobileNumber, userId).then(async result => {
                if (result.success) {
                    LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result.message)
                } else {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            });
        }
    }

    const handleVerifyMobile = async (mobileNumber, otp) => {
        if (!mobileNumber) {
            alertErrorMessage('Please Enter Your Mobile Number')
        } else if (!otp) {
            alertErrorMessage('Please Enter Your OTP')
        }
        else {
            LoaderHelper.loaderStatus(true);
            await AuthService.verifyMobileKyc(mobileNumber, otp, userId).then(async result => {
                if (result.success) {
                    LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result.message);
                    navigate("/kyc_details");
                } else {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            });
        }
    }


    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg-gradient preview_wrapper edit_profile">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span className="hh_icon cursor-pointer" onClick={handleBackKycDetails}><i className="ri-arrow-left-s-line ri-xl"></i> </span>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className="">Verify Mobile</h4>
                            </div>
                            <div className="col-3  text-end">
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0 home_scroll">
                        <div className="container">
                            <form className="">
                                <div className="pay_card mb-3 ">
                                    <div className="pay_header">
                                        <div className="h6">Get Verified your Mobile</div>
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>Mobile Number</label>
                                        <div className="vew_pss otp_btn">
                                            <input type="text" className="form-control" placeholder="Enter Mobile Number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                                            <button type="button" className="btn_view btn-icon" onClick={() => handleGetVerifyOtp(mobileNumber)}> GET OTP </button>
                                        </div>
                                        <small className="text-disable d-block mt-1">Send One Time Password(OTP) to your mobile number.</small>
                                    </div>
                                    <div className="field-box mb-3">
                                        <label>Enter OTP</label>
                                        <input type="text" className="form-control" placeholder="Enter OTP" value={otp} onChange={(e) => setOtp(e.target.value)} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="nav_footer nav_footer_transperant">
                    <div className="container">
                        <div className="row gx-2 justify-content-center">

                            <div className="col-12">
                                <button className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase" type="button" onClick={() => handleVerifyMobile(mobileNumber, otp)}>
                                    <span> VERIFY</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default VerifyMobile

