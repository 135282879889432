
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import { $ } from "react-jquery-plugin";
import { alertErrorMessage, alertwWarningMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import AuthService from "../../AuthService";
import Countdown from "../../customComponent/CountDown";
import { ApiConfig } from "../../utils/apiConfig/apiConfig";
import NavigationFooter from "../../customComponent/NavigationFooter";

const Contest = (props) => {

    const navigate = useNavigate();
    const [upComingList, setUpComingList] = useState([]);
    const [completedMatch, setCompletedMatch] = useState([]);
    const [liveMatch, setLiveMatch] = useState([]);


    const handleMyBalance = () => {
        navigate('/my_balance');
    }

    useEffect(() => {
        handleUpComingMatch();
        handleCompleteMatch();
        handleLiveMatch();
    }, []);

    const handleUpComingMatch = async () => {
        await AuthService.getUpComingDetailsDetails().then(async result => {
            if (result.success) {
                try {
                    setUpComingList(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    }

    const handleCompleteMatch = async () => {
        await AuthService.getCompleteMatchDetails().then(async result => {
            if (result.success) {
                try {
                    setCompletedMatch(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    }

    const handleLiveMatch = async () => {
        await AuthService.getLiveMatchDetails().then(async result => {
            if (result.success) {
                try {
                    setLiveMatch(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    }

    const handleMatchContest = (matchId, item) => {
        if (!matchId) {
            alertwWarningMessage('There Are No Contest For This Match')
        } else {
            navigate('/match_single', { state: item });
        }
    }

    const handleMatchComplete = (item) => {
        navigate('/contest_details', { state: item });
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg_img">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3">
                                <span className="user_icon">
                                    {props?.userDetails?.logo ?
                                        <img src={`${ApiConfig.baseUrl + props?.userDetails?.logo}`} />
                                        :
                                        <img src='assets/images/user.png' />
                                    }
                                </span>
                            </div>
                            <div className="col-6  text-center">
                                <img src="assets/images/logo_white.svg" className="main_logo" />
                            </div>
                            <div className="col-3 text-end">
                                <div className="hh_row">
                                    <span className="hh_icon cursor-pointer" onClick={handleMyBalance}> <i className="ri-wallet-3-fill"></i> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="detals_wrapper home_scroll">
                        <div className="container">
                            <ul className="details_tab btn-gradient border-gradient nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="btn nav-link active" id="Upcoming-tab" data-bs-toggle="tab" data-bs-target="#Upcoming"
                                        type="button" role="tab" aria-controls="Upcoming" aria-selected="true"><span>Upcoming</span> </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="btn nav-link" id="Live-tab" data-bs-toggle="tab" data-bs-target="#Live" type="button"
                                        role="tab" aria-controls="Live" aria-selected="false"> <span>Live</span> </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="btn nav-link" id="Completed-tab" data-bs-toggle="tab" data-bs-target="#Completed"
                                        type="button" role="tab" aria-controls="Completed" aria-selected="false"> <span>Completed</span>
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="Upcoming" role="tabpanel" aria-labelledby="Upcoming-tab">
                                    {upComingList.length > 0
                                        ? upComingList.map((item, index) => {
                                            const megaValue = item?.contest_details[0]?.data;
                                            const highestWinningAmount = megaValue && megaValue.length > 0 ? megaValue.reduce((max, contest) => {
                                                return (Number(contest.winning_amount) > Number(max.winning_amount)) ? contest : max;
                                            }, megaValue[0]) : null;
                                            return (
                                                <div className="play_card" key={index}>
                                                    <div className="leauge_name"> <span>{item?.SeriesName}</span> </div>
                                                    <div className="play_card_center">
                                                        <span className="matcs_status"><Countdown targetTime={item.StartDateTime} /></span>
                                                    </div>
                                                    <span className="row g-0 team_row cursor-pointer" onClick={() => handleMatchContest(item?.contest_details[0]?.match_id, item)}>
                                                        <div className="col-6">
                                                            <div className="team_card team_left team_left_mi">
                                                                <h4>{item?.TeamsShortNames[0]}</h4>
                                                                <div className="team_tcd ">
                                                                    <span>{item?.TeamA}</span>
                                                                    <img src={item?.TeamAlogo} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="team_card team_right team_right_SRH">
                                                                <h4>{item?.TeamsShortNames[1]}</h4>
                                                                <div className="team_tcd ">
                                                                    <span>{item?.TeamB}</span>
                                                                    <img src={item?.TeamBlogo} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                    <div className="play_footer d-flex align-items-center justify-content-between">
                                                        {highestWinningAmount ?
                                                            <div>
                                                                <span className="badge badge-success me-2">
                                                                    {highestWinningAmount && <span>{highestWinningAmount.contest_type}</span>}
                                                                </span>
                                                                <span>
                                                                    ${highestWinningAmount && <span>{highestWinningAmount.winning_amount}</span>}
                                                                </span>
                                                            </div>
                                                            :
                                                            ''
                                                        }
                                                        <span className="text-success d-flex align-items-center">
                                                            <small> <i className="ri-checkbox-blank-circle-fill me-1"></i></small>
                                                            {item?.PreSquad == 'true' ? 'LINEUP OUT' : 'LINE UP'}
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        }) : <div className="no_data"><span>No Team Found</span></div>}
                                </div>

                                <div className="tab-pane fade" id="Live" role="tabpanel" aria-labelledby="Live-tab">
                                    {liveMatch.length > 0
                                        ? liveMatch.map((item, index) => {
                                            return (
                                                <div className="play_card" key={index}>
                                                    <div className="leauge_name"><span>{item?.SeriesName}</span></div>
                                                    <div className="play_card_center"><span className="matcs_status">{item?.Status}</span> </div>
                                                    <span className="row g-0 team_row cursor-pointer" onClick={() => handleMatchContest(item?.contest_details[0]?.match_id, item)}>
                                                        <div className="col-6">
                                                            <div className="team_card team_left team_left_mi">
                                                                <h4>{item?.TeamsShortNames[0]}</h4>
                                                                <div className="team_tcd ">
                                                                    <span>{item?.TeamA}</span>
                                                                    <img src={item?.TeamAlogo} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="team_card team_right team_right_SRH">
                                                                <h4>{item?.TeamsShortNames[1]}</h4>
                                                                <div className="team_tcd ">
                                                                    <span>{item?.TeamB}</span>
                                                                    <img src={item?.TeamBlogo} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                    <div className="play_footer d-flex align-items-center justify-content-between">
                                                        <div><span>2 Team</span> &nbsp; &nbsp; <span>3 Contests</span> </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : <div className="no_data"><span>No Matches Found</span></div>}
                                </div>

                                <div className="tab-pane fade" id="Completed" role="tabpanel" aria-labelledby="Completed-tab">
                                    {completedMatch.length > 0
                                        ? completedMatch.map((item, index) => {
                                            return (
                                                <div className="play_card" key={index}>
                                                    <div className="leauge_name"> <span>{item?.SeriesName}</span> </div>
                                                    <div className="play_card_center"><span className="matcs_status">{item?.Status}</span> </div>
                                                    <span className="row g-0 team_row cursor-pointer" onClick={() => handleMatchComplete(item)}>
                                                        <div className="col-6">
                                                            <div className="team_card team_left team_left_mi">
                                                                <h4>{item?.TeamsShortNames[0]}</h4>
                                                                <div className="team_tcd ">
                                                                    <span>{item?.TeamA}</span>
                                                                    <img src={item?.TeamAlogo} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="team_card team_right team_right_SRH">
                                                                <h4>{item?.TeamsShortNames[1]}</h4>
                                                                <div className="team_tcd ">
                                                                    <span>{item?.TeamB}</span>
                                                                    <img src={item?.TeamBlogo} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                    <div className="play_footer d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <span>{item?.usercreatedteam?.length ? item?.usercreatedteam?.length : 0} Team</span>
                                                            &nbsp; &nbsp;
                                                            <span>{item?.contest_details?.length ? item?.contest_details?.length : 0} Contests</span>
                                                        </div>
                                                        <span className="text-success d-flex align-items-center"> You’ve earned $50 </span>
                                                    </div>
                                                </div>
                                            )
                                        }) : <div className="no_data"><span>No Team Found</span></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NavigationFooter />
            </div>
            <RightViewContent />
        </div>
    )
}

export default Contest

