
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import CommonCases from "../../Cases/CommonCases";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import AuthService from "../../AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../customComponent/CustomAlertMessage";

const Register = () => {

    const { state } = useLocation();
    const { handleRegister, handleChangeInput, formData } = CommonCases();
    const [showPassword, setShowPassword] = useState(false);
    const [seconds, setSeconds] = useState(0);

    const handleGetVerifyOtp = async (resend) => {
        if (!formData?.signId) {
            alertErrorMessage('Please Enter Email or MobileNumber');
        } else {
            LoaderHelper.loaderStatus(true);
            await AuthService.sendOtp(formData?.signId, resend).then(async result => {
                if (result?.success) {
                    LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result?.message);
                    timeRemainingStart();
                    setSeconds(60)
                } else {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result?.message);
                }
            });
        }
    }

    const timeRemainingStart = () => {
        const countdown = setInterval(() => {
            setSeconds((prevSeconds) => Math.max(prevSeconds - 1, 0));
          }, 1000);
          return () => clearInterval(countdown);
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper login_wrapper login_bg_2">
                    <div className="container">
                        <div className="login_page ">
                            <Link to="/" className="btn-back hh_icon"><i className="ri-arrow-left-s-line"></i></Link>
                            <img src="assets/images/logo_login.svg" className="logo_img" />
                            <div className="login_row login_scroll">
                                <h2>Register</h2>
                                <p>Enter your details for Register</p>
                                <form className="login_form">
                                    <div className="row">
                                        <div className="col-12 mb-4">
                                            <div className="field-box">
                                                <label htmlFor="Code" className="form-label">Email or Mobile Number</label>
                                                <div className="vew_pss otp_btn">
                                                    <input className="form-control" id="text" type="text" placeholder="Enter Email" name="signId"
                                                        onChange={handleChangeInput} />
                                                    <button
                                                        type="button"
                                                        className={`btn_view btn-icon ${seconds === 0 ? '' : 'disable'}`}
                                                        onClick={() => handleGetVerifyOtp(seconds === 0)}
                                                        disabled={seconds !== 0}
                                                    >
                                                        {seconds === 0 ? 'GET OTP' : `0:${seconds}`}
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-4">
                                            <div className="field-box">
                                                <label htmlFor="Code" className="form-label">Verification Code</label>
                                                <input className="form-control" id="otp" type="text" placeholder="Enter Verification Code" name="otp" onChange={handleChangeInput} />
                                            </div>
                                        </div>

                                        <div className="col-12 mb-4">
                                            <div className="field-box">
                                                <label htmlFor="Code" className="form-label">Username</label>
                                                <input className="form-control" id="username" type="text" placeholder="Enter Username" name="userName" onChange={handleChangeInput} />
                                            </div>
                                        </div>
                                        <div className="col-12 mb-4">
                                            <div className="field-box">
                                                <label htmlFor="Code" className="form-label">First Name</label>
                                                <input className="form-control" id="f-name" type="text" placeholder="First Name" name="firstName" onChange={handleChangeInput} />
                                            </div>
                                        </div>
                                        <div className="col-12 mb-4">
                                            <div className="field-box">
                                                <label htmlFor="Code" className="form-label"> Last Name</label>
                                                <input className="form-control" id="l-name" type="text" placeholder="Last Name" name="lastName" onChange={handleChangeInput} />
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-4">
                                            <div className="field-box"><label htmlFor="password" className="form-label">Password</label>
                                                <div className="vew_pss">
                                                    <input className="form-control" type={showPassword ? "text" : "password"} placeholder="Password" name="password" onChange={handleChangeInput} />

                                                    <button className="btn_view btn-icon" id="cNewPassword" type="button" onClick={() => setShowPassword(!showPassword)}>
                                                    <i className={`ri-${showPassword ? 'eye' : 'eye-close'}-line`}></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-4">
                                            <div className="field-box">
                                                <label htmlFor="Code" className="form-label">Referral Code (Optional)</label>
                                                <input className="form-control" id="refercode" type="text" placeholder="Enter Referral Code" name="refercode" value={state} onChange={handleChangeInput} disabled={state} />
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-4 ">
                                            <div className="form-check policy_check" >
                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" name="checkButton" onClick={handleChangeInput} />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    I have read and agree to Battle Infinit’s Terms of Service andPrivacy Policy.
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-4">
                                            <button className="custom-btn btn-border-gradient btn-gradient btn-block w-100 mb-3"
                                                type="button" onClick={handleRegister}><span>REGISTER</span></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="row justify-content-center text-center text-white">
                            <div className="col-lg-12  have_referal">Already have an account?
                                <Link className="color-primary" to="/login">Login</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>

    )
}

export default Register

