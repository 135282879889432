import React, { useState, useContext } from "react";
import AuthService from "../../AuthService/index";
import { alertErrorMessage, alertSuccessMessage, alertwWarningMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import { useNavigate, useLocation } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ProfileContext } from "../../context/ProfileProvider";

const CommonCases = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { setLoginDetails } = useContext(ProfileContext);
    const [formData, setFormData] = useState({
        signId: "",
        userName: "",
        firstName: "",
        lastName: "",
        password: "",
        otp: '',
        newPassword: '',
        cNewPassword: '',
        verifyOtp: '',
        refercode: '',
        checkButton: false,
    });
    const handleChangeInput = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const handleRegister = async () => {
        if (!formData?.signId) {
            alertErrorMessage('Please Enter Email or Mobile Number')
        } else if (!formData?.otp) {
            alertErrorMessage('Please Enter Verification Code')
        } else if (!formData?.userName) {
            alertErrorMessage('Please Enter Username')
        } else if (!formData?.firstName) {
            alertErrorMessage('Please Enter First Name')
        } else if (!formData?.lastName) {
            alertErrorMessage('Please Enter Last Name')
        } else if (!formData?.password) {
            alertErrorMessage('Please Enter Password')
        } else if (formData?.checkButton === false) {
            alertwWarningMessage('Your Terms and Conditions is Incomplete')
        }
        else {
            LoaderHelper.loaderStatus(true);
            await AuthService.Register(formData).then(async result => {
                if (result?.success) {
                    LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result?.message);
                    localStorage.setItem("accessToken", result.data.accessToken);
                    localStorage.setItem("refreshToken", result.data.refreshToken);
                    localStorage.setItem("userName", result.data.username);
                    localStorage.setItem("email_or_phone", result.data.email_or_phone);
                    localStorage.setItem("wallet_address", result.data.wallet_address);
                    localStorage.setItem("_id", result.data._id);
                    setLoginDetails(result?.data);
                    navigate("/home");
                } else {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result?.message);
                }
            });
        }
    }

    const handleLoginRegisterWithTelegram = async (payload) => {
        if (!payload?.userName) {
            alertErrorMessage('Please Enter Username')
        } else if (!payload?.firstName) {
            alertErrorMessage('Please Enter First Name')
        } else if (payload?.checkButton === false) {
            alertwWarningMessage('Your Terms and Conditions is Incomplete')
        }
        else {
            LoaderHelper.loaderStatus(true);
            await AuthService.LoginRegisterTelegram(payload).then(async result => {
                if (result?.success) {
                    LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result?.message);
                    localStorage.setItem("accessToken", result.data.accessToken);
                    localStorage.setItem("refreshToken", result.data.refreshToken);
                    localStorage.setItem("userName", result.data.username);
                    localStorage.setItem("email_or_phone", result.data.email_or_phone);
                    localStorage.setItem("wallet_address", result.data.wallet_address);
                    localStorage.setItem("_id", result.data._id);
                    setLoginDetails(result?.data);
                    // console.log(window.location);
                    // const redirect_url = window.location.protocol+"/"+window.location.host+"/#home";
                    window.location.href = '/#home';
                    // navigate(redirect_url, { replace: true });
                } else {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result?.message);
                }
            });
        }
    }

    const handleLoginRegisterWithGoogle = async (payload) => {
        if (!payload?.signId) {
            alertErrorMessage('Please Enter Email or Mobile Number')
        } else if (!payload?.userName) {
            alertErrorMessage('Please Enter Username')
        } else if (!payload?.firstName) {
            alertErrorMessage('Please Enter First Name')
        } else if (!payload?.lastName) {
            alertErrorMessage('Please Enter Last Name')
        } else if (!payload?.password) {
            alertErrorMessage('Please Enter Password')
        } else if (payload?.checkButton === false) {
            alertwWarningMessage('Your Terms and Conditions is Incomplete')
        }
        else {
            LoaderHelper.loaderStatus(true);
            await AuthService.LoginRegisterGoogle(payload).then(async result => {
                if (result?.success) {
                    LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result?.message);
                    localStorage.setItem("accessToken", result.data.accessToken);
                    localStorage.setItem("refreshToken", result.data.refreshToken);
                    localStorage.setItem("userName", result.data.username);
                    localStorage.setItem("email_or_phone", result.data.email_or_phone);
                    localStorage.setItem("wallet_address", result.data.wallet_address);
                    localStorage.setItem("_id", result.data._id);
                    setLoginDetails(result?.data);
                    navigate("/home");
                } else {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result?.message);
                }
            });
        }
    }


    const handleGetVerifyOtp = async () => {
        if (!formData?.verifyOtp) {
            alertErrorMessage('Please Enter Your OTP')
        }
        else {
            LoaderHelper.loaderStatus(true);
            await AuthService.getverifyOtp(formData, state).then(async result => {
                if (result?.success) {
                    LoaderHelper.loaderStatus(false);
                    alertSuccessMessage('Regestration Successfully..!');
                    localStorage.clear();
                    navigate("/login");
                } else {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result?.message);
                }
            });
        }
    }

    const handleLogin = async () => {
        if (!formData?.signId) {
            alertErrorMessage('Please Enter Email or Mobile Number')
        }
        else if (!formData?.password) {
            alertErrorMessage('Please Enter Password')
        }
        else {
            LoaderHelper.loaderStatus(true);
            await AuthService?.login(formData).then(async result => {
                if (result?.success) {
                    alertSuccessMessage(result?.message);
                    localStorage.setItem("accessToken", result.data.accessToken);
                    localStorage.setItem("refreshToken", result.data.refreshToken);
                    localStorage.setItem("userName", result.data.username);
                    localStorage.setItem("email_or_phone", result.data.email_or_phone);
                    localStorage.setItem("wallet_address", result.data.wallet_address);
                    localStorage.setItem("_id", result.data._id);
                    setLoginDetails(result?.data);
                    navigate("/home");
                    LoaderHelper.loaderStatus(false);
                } else {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result?.message);
                }
            });
        }
    }

    const handleForgotPassword = async () => {
        if (!formData?.signId) {
            alertErrorMessage('Please Enter Email or Mobile Number')
        } else if (!formData?.otp) {
            alertErrorMessage('Please Enter OTP')
        } else if (!formData?.newPassword) {
            alertErrorMessage('Please Enter New Password')
        } else if (!formData?.cNewPassword) {
            alertErrorMessage('Please Enter Confirm Password')
        }
        else {
            LoaderHelper.loaderStatus(true);
            await AuthService.forgotPassword(formData).then(async result => {
                if (result.success) {
                    LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result.message);
                    navigate("/login");
                } else {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            });
        }
    }

    return { handleRegister, handleLoginRegisterWithGoogle, handleLoginRegisterWithTelegram, handleChangeInput, handleLogin, handleForgotPassword, handleGetVerifyOtp, formData };
}

export default CommonCases;