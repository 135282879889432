
import React, { useEffect, useState } from "react";
import { $ } from "react-jquery-plugin";
import { Link } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import NavigationFooter from "../../customComponent/NavigationFooter";
import { useNavigate } from "react-router-dom";
import {deviceDetect, isAndroid } from "react-device-detect";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import AuthService from "../../AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../customComponent/CustomAlertMessage";


const Settings = (props) => {

    const navigate = useNavigate();
    const [isAndoirdDevice, setAndroifDevice] = useState(false);

    const handleLogOut = () => {
        localStorage.clear();
        window.location.reload();
        window.location.href = "/";

    }

    useEffect(() => {
        const device = deviceDetect();
        console.log(device, "devicedata browserName", isAndroid);

        if (isAndroid) {
            setAndroifDevice(true);
        }
    
    },[])

    const handleDeleteConfimation = (item) => {
        $("#confirmationModal").modal('show');

    }

    const handleCloseConfimation = (item) => {
        $("#confirmationModal").modal('hide');

    }

    const handleDeleteAccount = async () => {
        handleCloseConfimation();
        LoaderHelper.loaderStatus(true);
        const payload = {
            email_or_phone: props?.userDetails?.email_or_phone
        };
        console.log("Delete Account Confim Clicked", payload);

        await AuthService.delterAccount(payload).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage(result?.message);
                handleLogOut();
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message);
            }
        });
    }


    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                {/* <!-- main screen --> */}
                <div className="main_wrapper bg-gradient preview_wrapper edit_profile">
                    {/* <!-- header part --> */}
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                {/* <!-- <Link to="/my_balance" className="hh_icon"><i className="ri-arrow-left-s-line ri-xl  "></i> </Link>--> */}
                            </div>
                            <div className="col-6  text-center">
                                <img src="assets/images/logo_white.svg" className="main_logo" />
                            </div>
                            <div className="col-3 text-end">
                                <div className="hh_row justify-content-end">
                                    {/* <Link to="#" className="hh_icon"> <i className="ri-notification-4-fill"></i> </Link> */}
                                    {/*  <!-- <Link to="#" className="hh_icon"> <i className="ri-wallet-3-fill"></i> </Link> --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0  home_scroll">
                        <div className="container">
                            <Link to="/my_balance" className="po_card pointer mb-2">
                                <div className="d-flex align-items">
                                    <div className="po_icon">
                                        <i className="ri-wallet-line ri-xl"></i>
                                    </div>
                                    <div className="po_txt ps-3">
                                        <h5 className="text-white"><small> My Balance </small></h5>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/my_nft" className="po_card pointer mb-2">
                                <div className="d-flex align-items">
                                    <div className="po_icon">
                                        <img src="assets/images/my_nft.png" />
                                    </div>
                                    <div className="po_txt ps-3">
                                        <h5 className="text-white"><small> My NFT’s </small></h5>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/invite" className="po_card pointer  mb-2">
                                <div className="d-flex align-items">
                                    <div className="po_icon">
                                        <img src="assets/images/manage_payments.png" />
                                    </div>
                                    <div className="po_txt ps-3">
                                        <h5 className="text-white"><small>Refer & Earns</small></h5>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/terms_condition" className="po_card pointer  mb-3">
                                <div className="d-flex align-items">
                                    <div className="po_icon">
                                        <img src="assets/images/manage_payments.png" />
                                    </div>
                                    <div className="po_txt ps-3">
                                        <h5 className="text-white"><small> Terms & Conditions </small></h5>
                                    </div>
                                </div>
                            </Link>  

                            <div className="download-btn-cus btn-border-gradient cursor-pointer btn-block w-100 mb-3" onClick={handleDeleteConfimation}>
                                Delete Account
                            </div>

                            {isAndoirdDevice &&
                            <Link target="_blank" to="https://play.google.com/store/apps/details?id=battleinfinity.fantasy.ibat.sports&pli=1" className="display-hide-cus download-btn-cus btn-border-gradient  btn-block w-100 mb-2">
                                Download Android App
                            </Link>
                            }
                            

                        </div>  
                                              
                    </div>
                    {!props?.userDetails?.telegram_login && 
                        <div className="container ">
                            <div className="row gx-2 justify-content-center mt-5">
                                <div className="col-12">
                                    <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase" onClick={handleLogOut}>
                                        <span> LOG OUT</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    
                </div>              
                <div className="modal filter_modal" id="confirmationModal" tabIndex="-1" aria-labelledby="confirmationModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center" id="confirmationModalLabel"> Confirmation</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <ul className="pay_list">
                                    <li className="">
                                        <p>Are you sure that you want to delete account?</p>
                                    </li>
                                </ul>

                            </div>
                            <div className="modal-footer d-block">
                                <div className="row gx-2 justify-content-center">
                                    <div className="col-6">
                                        <button type="button" className="download-btn-cus btn-border-gradient cursor-pointer btn-block w-100 mb-3" onClick={handleDeleteAccount}>Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
                <NavigationFooter />
            </div>
            <RightViewContent />
        </div>
    )
}

export default Settings

