

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import { alertErrorMessage, alertSuccessMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import AuthService from "../../AuthService";
import { $ } from "react-jquery-plugin";
import moment from "moment";

const TransactionHistory = () => {

    const navigate = useNavigate();
    const [buttonType, setbuttonType] = useState('deposit');
    const [transactionList, setTransactionList] = useState([]);

    const handleFilterModel = () => {
        $("#filterModal").modal('hide');
    }
    const handleBackMyBalance = () => {
        navigate("/my_balance");
    }

    const handlebuttonTypeDeposit = () => {
        setbuttonType('deposit')
    }

    const handlebuttonTypeContest = () => {
        setbuttonType('contests')
    }

    const handlebtnWithdwawal = () => {
        setbuttonType('withdrawl')
    }

    useEffect(() => {
        handleTransactionHistory();
    }, [buttonType])

    const handleTransactionHistory = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getTransactionHistory(buttonType).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setTransactionList(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg-gradient preview_wrapper edit_profile">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span className="hh_icon cursor-pointer" onClick={handleBackMyBalance}><i className="ri-arrow-left-s-line ri-xl  "></i> </span>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className="">Transtion History</h4>
                            </div>
                            <div className="col-3  text-end">
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0 ">
                        <div className="container">
                            <ul className="details_tab btn-gradient border-gradient nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="btn nav-link active" id="Deposits-tab" data-bs-toggle="tab" data-bs-target="#Deposits"
                                        type="button" role="tab" aria-controls="Deposits" aria-selected="true" onClick={handlebuttonTypeDeposit} ><span>Deposits </span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="btn nav-link" id="Contests-tab" data-bs-toggle="tab" data-bs-target="#Contests"
                                        type="button" role="tab" aria-controls="Contests" aria-selected="false" onClick={handlebuttonTypeContest}> <span>Contests</span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="btn nav-link" id="Withdrawals-tab" data-bs-toggle="tab" data-bs-target="#Withdrawals"
                                        type="button" role="tab" aria-controls="Withdrawals" aria-selected="false" onClick={handlebtnWithdwawal}> <span>Withdrawals</span>
                                    </button>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="Deposits" role="tabpanel" aria-labelledby="Deposits-tab">
                                <div className="txn_list ">
                                    <div className="team_info border-top">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-3 text-start">
                                                    <span><small className="">DATE & TIME</small></span>
                                                </div>
                                                <div className="col-6 text-center">
                                                    <span><small className="">TRANSACTION DETAILS</small></span>
                                                </div>
                                                <div className="col-3 text-end">
                                                    <span><small className="">AMOUNT</small></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="player_info_row list_scroll ">
                                        {transactionList.length > 0
                                            ? transactionList.map((item, index) => (
                                                <div className="player_info pb-2" key={index}>
                                                    <div className="container">
                                                        <div className="row align-items-center">
                                                            <div className="col-3 text-start">
                                                                <span className="player_in_title">
                                                                    {moment(item?.createdAt).format('MMMM Do')}
                                                                    <br />
                                                                    {moment(item?.createdAt).format('h:mm a')}
                                                                </span>
                                                            </div>
                                                            <div className="col-6 text-center">
                                                                <span className="player_in_title">
                                                                    <span className="text-success">{item?.description}</span>
                                                                    <br />
                                                                    Txn ID-{item?.transaction_id}
                                                                </span>
                                                            </div>
                                                            <div className="col-3 text-end">
                                                                <span className="player_in_title">
                                                                    <span className="text-success"> ${item?.amount}</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            : <div className="no_data" ><span>No Data Found</span></div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="Contests" role="tabpanel" aria-labelledby="Contests-tab">
                                <div className="txn_list ">
                                    <div className="team_info border-top">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-3 text-start">
                                                    <span><small className="text-primary">DATE & TIME</small></span>
                                                </div>
                                                <div className="col-6 text-center">
                                                    <span><small className="text-primary">TRANSACTION DETAILS</small></span>
                                                </div>
                                                <div className="col-3 text-end">
                                                    <span><small className="text-primary">AMOUNT</small></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="player_info_row list_scroll">
                                        {transactionList.length > 0
                                            ? transactionList.map((item, index) => (
                                                <div className="player_info pb-2" key={index}>
                                                    <div className="container">
                                                        <div className="row align-items-center">
                                                            <div className="col-3 text-start">
                                                                <span className="player_in_title">
                                                                    {moment(item?.createdAt).format('MMMM Do')}
                                                                    <br />
                                                                    {moment(item?.createdAt).format('h:mm a')}
                                                                </span>
                                                            </div>
                                                            <div className="col-6 text-center">
                                                                <span className="player_in_title">
                                                                    <span className="text-success"> {item?.description}</span>
                                                                    <br />
                                                                    Txn ID-{item?.transaction_id}
                                                                </span>
                                                            </div>
                                                            <div className="col-3 text-end">
                                                                <span className="player_in_title">
                                                                    <span className="text-success"> ${item?.amount}</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            : <div className="no_data" ><span>No Data Found</span></div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="Withdrawals" role="tabpanel" aria-labelledby="Withdrawals-tab">
                                <div className="txn_list ">
                                    <div className="team_info border-top">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-3 text-start">
                                                    <span><small className="text-primary">DATE & TIME</small></span>
                                                </div>
                                                <div className="col-6 text-center">
                                                    <span><small className="text-primary">TRANSACTION DETAILS</small></span>
                                                </div>
                                                <div className="col-3 text-end">
                                                    <span><small className="text-primary">AMOUNT</small></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="player_info_row list_scroll">
                                        {transactionList.length > 0
                                            ? transactionList.map((item, index) => (
                                                <div className="player_info pb-2" key={index}>
                                                    <div className="container">
                                                        <div className="row align-items-center">
                                                            <div className="col-3 text-start">
                                                                <span className="player_in_title">
                                                                    {moment(item?.createdAt).format('MMMM Do')}
                                                                    <br />
                                                                    {moment(item?.createdAt).format('h:mm a')}
                                                                </span>
                                                            </div>
                                                            <div className="col-6 text-center">
                                                                <span className="player_in_title">
                                                                    <span className="text-success"> {item?.description}</span>
                                                                    <br />
                                                                    Txn ID-{item?.transaction_id}
                                                                </span>
                                                            </div>
                                                            <div className="col-3 text-end">
                                                                <span className="player_in_title">
                                                                    <span className="text-success"> ${item?.amount}</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            : <div className="no_data" ><span>No Data Found</span></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />

            <div className="modal filter_modal" id="filterModal" tabIndex="-1" aria-labelledby="filterModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="filterModalLabel">Filter</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="filter_row">
                                <em>Entry</em>
                                <button>$1 - $50</button>
                                <button className="active">$51 - $100</button>
                                <button>$101 - $1000</button>
                                <button>$101 - $1000</button>
                            </div>
                            <div className="filter_row">
                                <em>Number of Teams</em>
                                <button>1</button>
                                <button className="active">3 - 10</button>
                                <button>11 - 100</button>
                                <button>101 - 1,000</button>
                                <button>$1001 & above</button>
                            </div>
                            <div className="filter_row">
                                <em>Prize Pool</em>
                                <button>$1 - $50</button>
                                <button className="active">$51 - $100</button>
                                <button>$101 - $1000</button>
                                <button>$101 - $1000</button>
                            </div>
                            <div className="filter_row">
                                <em>Contest Type</em>
                                <button>Single Entry</button>
                                <button className="active">Single Winner</button>
                                <button>Multi Entry</button>
                                <button>Multi Winner</button>
                                <button>Guranteed</button>
                                <button>Flexible</button>
                            </div>
                        </div>
                        <div className="modal-footer d-block">
                            <div className="row gx-2">
                                <div className="col-6">
                                    <button type="button" className="custom-btn btn-border-gradient  btn-block w-100"
                                        data-bs-dismiss="modal">Clear</button>
                                </div>
                                <div className="col-6">
                                    <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-block w-100" onClick={handleFilterModel} >
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default TransactionHistory

