import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import AuthService from "../../AuthService";
import { alertErrorMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import Countdown from "../../customComponent/CountDown";
import moment from "moment";


const PlayerProfile = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [playerDetails, setPlayerDetails] = useState([]);
    const [selectedPlayers, setSelectedPlayers] = useState(state?.selectedPlayers || []);
    const [minPlayerlLimit, setMinPlayerlLimit] = useState(4);
    const [creditLimit, setCreditLimit] = useState(100);

    const player = state?.playerProfile?.[0];

  
    const handleBack = () => {
        navigate('/create_team', { state: state });
    }


    useEffect(() => {
        // handleAllPlayrsList();
        getPlayerDetail();

    }, []);

    const handleAllPlayrsList = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.playerDetils(state?.MatchId, state?.playerProfile?.[0]?.pid).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setPlayerDetails(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    }

    const getPlayerDetail = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.playerInfo(state?.playerProfile?.[0]?.pid, state.Type).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    if (result.data && result.data.data) {
                        const dt = result.data;
                        dt.data.forEach((element, index) => {
                            if(index === 0) {
                                element['clas'] = 'team_left_DC';
                            } else if(index === 1) {
                                element['clas'] = 'team_left_CSK';
                            } else if(index === 2) {
                                element['clas'] = 'team_left_RR';
                            } else if(index === 3) {
                                element['clas'] = 'team_left_LSG';
                            } else {
                                element['clas'] = 'team_left_mi';
                            }
                            element['date_st'] = moment(element.date_start_ist).format('MMM DD, YYYY');
                        });
                        setPlayerDetails(dt);
                    } else {
                        setPlayerDetails(result?.data);
                    }
                    
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    }


    // const handleAddPlayers = () => {
    //     setSelectedPlayers((prevSelectedPlayers) => {
    //         const index = prevSelectedPlayers.findIndex((item) => item.pid === player.pid);
    //         if (index > -1) {
    //             // Player is already selected, so remove it from the list
    //             return prevSelectedPlayers.filter((item) => item.pid !== player.pid);
    //         } else {
    //             // Player is not selected, so add it to the list
    //             return [...prevSelectedPlayers, player];
    //         }
    //     });
    // };

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg_img preview_wrapper">
                    <div className="header_wrapper">
                        <div className="row align-items-center justify-content-end">
                            <div className="col-3 text-start text-end">
                                <span className="hh_icon cursor-pointer" onClick={handleBack}> <i className="ri-close-line ri-xl"></i> </span>
                            </div>

                        </div>
                    </div>
                    <div className="main_panel match_details px-0">
                        <div className="pp_row">
                            <div className="row">
                                <div className="col-3">
                                    <img src="assets/images/player_1.png" alt="" />
                                </div>
                                <div className="col-6">
                                    <div className="player_txt">
                                        <h5>{state?.playerProfile?.[0]?.first_name}</h5>
                                        <span className="player_in_title">
                                            <em className="text-primary"> {state?.playerProfile?.[0]?.playing_role} | </em> {state?.teamName}
                                        </span>
                                        <div className="row">
                                            <div className="col-6">
                                                <span className="pp_points">
                                                    POINTS
                                                    <em>{state?.playerProfile?.[0]?.fantasy_player_rating} </em>
                                                </span>
                                            </div>
                                            <div className="col-6">
                                                <span className="pp_points">
                                                    MATCHES
                                                    <em>{playerDetails?.profile?.matches}</em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-mini" /* onClick={handleAddPlayers} */>
                                        {selectedPlayers.some((item) => item.pid === player.pid) ? 'REMOVE' : 'ADD'}
                                    </button>

                                </div>
                            </div>
                        </div>

                        <div className="scroll_card bg-dark pb-0">
                            <div className="container">
                                <div className="sc_title">
                                    <h4>Recent Matches Status</h4>
                                </div>
                                <div className="rms_row">
                                    {playerDetails&&playerDetails.data&&playerDetails.data.length>0 &&(
                                        <>
                                            {playerDetails?.data.map(_ => (
                                                <div className={"rms_card " + _?.clas}>
                                                    <span>{_?.date_st}</span>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <span>VS.</span>
                                                            <h5>{_?.vs ? _?.vs : '-'}</h5>
                                                        </div>
                                                        <div className="col-3">
                                                            <span>SEL BY</span>
                                                            <h5>{_?.selBy}%</h5>
                                                        </div>
                                                        <div className="col-2">
                                                            <span>POINTS</span>
                                                            <h5>{_?.fantasy_player_rating}</h5>
                                                        </div>
                                                        <div className="col-2">
                                                            <span>CREDIT</span>
                                                            <h5>{_?.credit}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                    
                                    
                                    {/* <div className="rms_card team_left_CSK">
                                        <span>NOV 06, 2022</span>
                                        <div className="row">
                                            <div className="col-5">
                                                <span>VS.</span>
                                                <h5>MI</h5>
                                            </div>
                                            <div className="col-3">
                                                <span>SEL BY</span>
                                                <h5>20.50%</h5>
                                            </div>
                                            <div className="col-2">
                                                <span>POINTS</span>
                                                <h5>325</h5>
                                            </div>
                                            <div className="col-2">
                                                <span>CREDIT</span>
                                                <h5>10.0</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rms_card team_left_RR">
                                        <span>NOV 06, 2022</span>
                                        <div className="row">
                                            <div className="col-5">
                                                <span>VS.</span>
                                                <h5>MI</h5>
                                            </div>
                                            <div className="col-3">
                                                <span>SEL BY</span>
                                                <h5>20.50%</h5>
                                            </div>
                                            <div className="col-2">
                                                <span>POINTS</span>
                                                <h5>325</h5>
                                            </div>
                                            <div className="col-2">
                                                <span>CREDIT</span>
                                                <h5>10.0</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rms_card team_left_LSG">
                                        <span>NOV 06, 2022</span>
                                        <div className="row">
                                            <div className="col-5">
                                                <span>VS.</span>
                                                <h5>MI</h5>
                                            </div>
                                            <div className="col-3">
                                                <span>SEL BY</span>
                                                <h5>20.50%</h5>
                                            </div>
                                            <div className="col-2">
                                                <span>POINTS</span>
                                                <h5>325</h5>
                                            </div>
                                            <div className="col-2">
                                                <span>CREDIT</span>
                                                <h5>10.0</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rms_card team_left_mi">
                                        <span>NOV 06, 2022</span>
                                        <div className="row">
                                            <div className="col-5">
                                                <span>VS.</span>
                                                <h5>MI</h5>
                                            </div>
                                            <div className="col-3">
                                                <span>SEL BY</span>
                                                <h5>20.50%</h5>
                                            </div>
                                            <div className="col-2">
                                                <span>POINTS</span>
                                                <h5>325</h5>
                                            </div>
                                            <div className="col-2">
                                                <span>CREDIT</span>
                                                <h5>10.0</h5>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default PlayerProfile

