import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";

const AddMoney = (prop) => {

    const { state } = useLocation();
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/match_single', { state: state });
    }

    const handleAddMoneyContinue = () => {
        navigate('/my_balance', { state: state });
    }

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg-gradient preview_wrapper">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span className="hh_icon cursor-pointer" onClick={handleBack}><i className="ri-arrow-left-s-line ri-xl"></i></span>
                            </div>
                            <div className="col-6 text-center">
                                <h4 className="">Add Money</h4>
                            </div>
                            <div className="col-3  text-end">
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0 home_scroll">
                        <div className="container">
                            <div className="pay_card mb-3 ">
                                <ul className="pay_list">
                                    <li className="pt-0">
                                        <p>Current IBAT Balance</p>
                                        <span>${prop?.userDetails?.ibat_balance}</span>
                                    </li>
                                    <li className="">
                                        <p>Entre Fee</p>
                                        <span>${state?.EnteryFee}</span>
                                    </li>
                                    <li className="total_pay text-white pb-0">
                                        <p>Join this contest by adding</p>
                                        <p>${state?.EnteryFee}</p>
                                    </li>
                                </ul>
                            </div>
                            {/* <div className="pay_card ">
                                <p className="d-block text-center">Add cash to your account</p>
                                <div className="field-box mb-2">
                                    <input type="text" className="form-control" placeholder="Enter Amount" />
                                </div>
                                <div className="row btn-group-mini gx-2 ">
                                    <div className="col-3">
                                        <button type="btn" className="btn btn-outline-primary btn-block w-100">$50</button>
                                    </div>
                                    <div className="col-3">
                                        <button type="btn" className="btn btn-outline-primary btn-block w-100 active">$100</button>
                                    </div>
                                    <div className="col-3">
                                        <button type="btn" className="btn btn-outline-primary btn-block w-100">$150</button>
                                    </div>
                                    <div className="col-3">
                                        <button type="btn" className="btn btn-outline-primary btn-block w-100">$200</button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="nav_footer nav_footer_transperant">
                    <div className="container">
                        <div className="row gx-2 justify-content-center">
                            <div className="col-12">
                                <button type="button" onClick={handleAddMoneyContinue} className="custom-btn btn-border-gradient btn-gradient btn-block w-100 text-uppercase">
                                    <span> Continue</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default AddMoney

