
import React, { useEffect, useState } from "react";
import RightViewContent from "../../customComponent/RightViewContent";
import { Link, useLocation } from "react-router-dom";
import AuthService from "../../AuthService";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import { alertErrorMessage } from "../../customComponent/CustomAlertMessage";
import { ApiConfig } from "../../utils/apiConfig/apiConfig";
import moment from "moment";
const MyNfts = () => {

    const { state } = useLocation();
    const [nftDetailsList, setNftDetailsList] = useState([]);
    const [limitNftList, setLimitNftList] = useState(4);


    useEffect(() => {
        handleMyNftDetails();
    }, [])

    const handleMyNftDetails = async (status) => {
        LoaderHelper.loaderStatus(true);
        await AuthService?.getNftList(limitNftList, status).then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setNftDetailsList(result?.data);
                    setLimitNftList(limitNftList + 4);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message);
            }
        });
    }


    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg-gradient preview_wrapper">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <Link to={state === 'paymentscreen' ? '/payment_options' : "/settings"} className="hh_icon"><i className="ri-arrow-left-s-line ri-xl  "></i> </Link>
                            </div>
                            <div className="col-5  text-center">
                                <h4 className="">My NFT's</h4>
                            </div>
                            <div className="col-4  text-end">
                                <Link to="#" className="custom-btn btn-border-gradient btn-gradient btn-mini text-uppercase px-2">
                                    <span className="d-block"> buy more</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main_panel px-0 pb-3 ">
                        <div className="container">
                            <ul className="details_tab btn-gradient border-gradient nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item nav-item-half" role="presentation">
                                    <button className="btn nav-link active" id="Purchased-tab" data-bs-toggle="tab" data-bs-target="#Purchased"
                                        type="button" role="tab" aria-controls="Purchased" aria-selected="true" onClick={() => handleMyNftDetails()}><span>Purchased</span>
                                    </button>
                                </li>
                                <li className="nav-item nav-item-half" role="presentation">
                                    <button className="btn nav-link" id="Rented-tab" data-bs-toggle="tab" data-bs-target="#Rented" type="button"
                                        role="tab" aria-controls="Rented" aria-selected="false" onClick={() => handleMyNftDetails('take_rent')}> <span>Rented</span> </button>
                                </li>
                            </ul>
                            <div className="tab-content home_scroll" id="myTabContent">
                                <div className="tab-pane show active" id="Purchased" role="tabpanel" aria-labelledby="Purchased-tab">
                                    <div className="row g-2">
                                        {
                                            nftDetailsList?.data?.length > 0
                                                ? nftDetailsList?.data?.map((item, index) => (
                                                    <div className="col-6" key={index}>
                                                        <div className="nft-card pay_card ">
                                                            <div className="ratio ratio-1x1">
                                                                <img src={`${ApiConfig.baseUrl + item?.file}`} alt="nft img" onError={(e) => { e.target.src = '/assets/images/nft_thumb.png'; }} />

                                                            </div>
                                                            <div className="nft-footer">
                                                                <div className="nft-ttpp">
                                                                    <h5>{item?.name}</h5>
                                                                    <span>{item?.price}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                : null
                                        }
                                    </div>
                                    {
                                        nftDetailsList?.data?.length > 0 ?
                                            <>
                                                {
                                                    nftDetailsList?.data?.length == nftDetailsList?.filter?.count ?
                                                        <div className="text-center mt-2">No More Data Available </div>
                                                        :
                                                        <div className="row">
                                                            <div className="col-12 text-center mt-4">
                                                                <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-mini text-uppercase px-2" onClick={() => handleMyNftDetails()}>
                                                                    <span className="d-block" ><i className="ri-loader-4-fill"></i> Load More</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                }
                                            </>
                                            :
                                            <div className="text-center mt-2">No Data Available</div>
                                    }
                                </div>
                                <div className="tab-pane" id="Rented" role="tabpanel" aria-labelledby="Rented-tab">
                                    <ul className=" tabintab  nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item nav-item-half" role="presentation">
                                            <button className="btn nav-link active" id="Rentin-tab" data-bs-toggle="tab" data-bs-target="#Rentin"
                                                type="button" role="tab" aria-controls="Rentin" aria-selected="true" onClick={() => handleMyNftDetails('take_rent')}><span>Rent In</span>
                                            </button>
                                        </li>
                                        <li className="nav-item nav-item-half" role="presentation">
                                            <button className="btn nav-link" id="Rentout-tab" data-bs-toggle="tab" data-bs-target="#Rentout"
                                                type="button" role="tab" aria-controls="Rentout" aria-selected="false" onClick={() => handleMyNftDetails('on_rent')}> <span>Rent Out</span>
                                            </button>
                                        </li>
                                    </ul>

                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane show active" id="Rentin" role="tabpanel" aria-labelledby="Rentin-tab">
                                            <div className="row g-2">
                                                {
                                                    nftDetailsList?.data?.length > 0
                                                        ? nftDetailsList?.data?.map((item, index) => (
                                                            <div className="col-6" key={index}>
                                                                <div className="nft-card pay_card ">
                                                                    <div className="ratio ratio-1x1">
                                                                        <img src={`${ApiConfig.baseUrl + item?.file}`} alt="nft img" onError={(e) => { e.target.src = '/assets/images/nft_thumb.png'; }} />
                                                                    </div>
                                                                    <div className="nft-footer">
                                                                        <div className="nft-ttpp">
                                                                            <h5>{item?.name}</h5>
                                                                            <span>{item?.price}</span>
                                                                        </div>
                                                                        <div className="text-secondary">{moment(item?.createdAt).startOf('day').fromNow()}</div>
                                                                        <div className="hr_gradient my-2"></div>
                                                                        <div className="nft_comm">
                                                                            <span>Commission</span>
                                                                            <div className="comm_list"> Owner<strong>{item?.royality}%</strong></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                        : null
                                                }

                                                {
                                                    nftDetailsList?.data?.length > 0 ?
                                                        <>
                                                            {
                                                                nftDetailsList?.data?.length == nftDetailsList?.filter?.count ?
                                                                    <div className="text-center mt-2">No More Data Available </div>
                                                                    :
                                                                    <div className="row">
                                                                        <div className="col-12 text-center mt-4">
                                                                            <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-mini text-uppercase px-2" onClick={() => handleMyNftDetails('on_rent')}>
                                                                                <span className="d-block" ><i className="ri-loader-4-fill"></i> Load More</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </>
                                                        :
                                                        <div className="text-center mt-2">No Data Available</div>
                                                }

                                            </div>
                                        </div>

                                        <div className="tab-pane" id="Rentout" role="tabpanel" aria-labelledby="Rentout-tab">
                                            <div className="row g-2">
                                                {
                                                    nftDetailsList?.data?.length > 0
                                                        ? nftDetailsList?.data?.map((item, index) => (
                                                            <div className="col-6" key={index}>
                                                                <div className="nft-card pay_card ">
                                                                    <div className="ratio ratio-1x1">
                                                                        <img src={`${ApiConfig.baseUrl + item?.file}`} alt="nft img" onError={(e) => { e.target.src = '/assets/images/nft_thumb.png'; }} />
                                                                    </div>
                                                                    <div className="nft-footer">
                                                                        <div className="nft-ttpp">
                                                                            <h5>{item?.name}</h5>
                                                                            <span>{item?.price}</span>
                                                                        </div>
                                                                        <div className="text-secondary">{moment(item?.createdAt).startOf('day').fromNow()}</div>
                                                                        <div className="hr_gradient my-2"></div>
                                                                        <div className="nft_comm">
                                                                            <span>Commission</span>
                                                                            <div className="comm_list"> Owner<strong>{item?.royality}%</strong></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                        : null
                                                }

                                                {
                                                    nftDetailsList?.data?.length > 0 ?
                                                        <>
                                                            {
                                                                nftDetailsList?.data?.length == nftDetailsList?.filter?.count ?
                                                                    <div className="text-center mt-2">No More Data Available </div>
                                                                    :
                                                                    <div className="row">
                                                                        <div className="col-12 text-center mt-4">
                                                                            <button type="button" className="custom-btn btn-border-gradient btn-gradient btn-mini text-uppercase px-2" onClick={() => handleMyNftDetails('on_rent')}>
                                                                                <span className="d-block" ><i className="ri-loader-4-fill"></i> Load More</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </>
                                                        :
                                                        <div className="text-center mt-2">No Data Available</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default MyNfts

