
import React from "react";
import { useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";


const LeaderBoardTeam = () => {
    const { state } = useLocation();

    const countRoles = state?.team_details?.players?.reduce((acc, cur) => {
        acc[cur.playing_role] = (acc[cur.playing_role] || 0) + 1;
        return acc;
    }, {});

    const handleBack = () => {
        window.history.back();
    };

    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg_img preview_wrapper">

                    <div className="main_panel match_details px-0">
                        <div className="team_preview my_team_preview  ">
                            <div className="header_wrapper">
                                <div className="row align-items-center">
                                    <div className="col-3 text-start">
                                        <span className="hh_icon cursor-pointer" onClick={handleBack} ><i className="ri-arrow-left-s-line ri-xl"></i> </span>
                                    </div>
                                    <div className="col-6 text-center" >
                                        <small> {state?.username}<span>({state?.team_details?.name})</span> </small>
                                        <p className="mb-0" >{state?.team_details?.total_points}pts</p>
                                    </div>
                                </div>
                            </div>
                            <div className="players_row">
                                <h5>WICKET KEEPERS ({countRoles?.wk})</h5>
                                <div className="team_bar">
                                    {state?.team_details?.players?.length > 0 &&
                                        state?.team_details?.players?.map((item, index) => {
                                            return item.playing_role === "wk" ? (
                                                <div className="player_ico" key={index}>

                                                    <span className={`${item?.caption == true || item?.vice_caption == true ? 'player_badge' : ''}`}>
                                                        {item?.caption == true ? 'C' : item?.vice_caption == true ? 'VC' : ''}
                                                    </span>
                                                    <img src="assets/images/player_1.png" width="55" height="67" />
                                                    <div className="player_name">
                                                        {item.short_name.split(' ')[0].charAt(0)} {item.short_name.split(' ')[item.short_name.split(' ').length - 1]}
                                                        <em className={`ms-1 pp_status me-0 ${item.playing11 === 'true' ? 'tm_green' : 'tm_red'}`}></em>
                                                    </div>
                                                    <span>{item?.points} PTS</span>
                                                </div>
                                            ) : null;
                                        })
                                    }
                                </div>
                            </div>
                            <div className="players_row">
                                <h5>BATSMEN ({countRoles?.bat})</h5>
                                <div className="team_bar">
                                    {state?.team_details?.players?.length > 0 &&
                                        state?.team_details?.players?.map((item, index) => {
                                            return item.playing_role === "bat" ? (
                                                <div className="player_ico" key={index}>
                                                    <span className={`${item?.caption == true || item?.vice_caption == true ? 'player_badge' : ''}`}>
                                                        {item?.caption == true ? 'C' : item?.vice_caption == true ? 'VC' : ''}
                                                    </span>
                                                    <img src="assets/images/player_1.png" width="55" height="67" />
                                                    <div className="player_name">
                                                        {item.short_name.split(' ')[0].charAt(0)} {item.short_name.split(' ')[item.short_name.split(' ').length - 1]}
                                                        <em className={`ms-1 pp_status me-0 ${item.playing11 === 'true' ? 'tm_green' : 'tm_red'}`}></em>
                                                    </div>
                                                    <span>{item?.points} PTS</span>
                                                </div>
                                            ) : null;
                                        })
                                    }
                                </div>
                            </div>
                            <div className="players_row">
                                <h5>ALL ROUNDERS ({countRoles?.all})</h5>
                                <div className="team_bar">
                                    {state?.team_details?.players?.length > 0 &&
                                        state?.team_details?.players?.map((item, index) => {
                                            return item.playing_role === "all" ? (
                                                <div className="player_ico" key={index}>
                                                    <span className={`${item?.caption == true || item?.vice_caption == true ? 'player_badge' : ''}`}>
                                                        {item?.caption == true ? 'C' : item?.vice_caption == true ? 'VC' : ''}
                                                    </span>
                                                    <img src="assets/images/player_1.png" width="55" height="67" />
                                                    <div className="player_name">
                                                        {item.short_name.split(' ')[0].charAt(0)} {item.short_name.split(' ')[item.short_name.split(' ').length - 1]}
                                                        <em className={`ms-1 pp_status me-0 ${item.playing11 === 'true' ? 'tm_green' : 'tm_red'}`}></em>
                                                    </div>
                                                    <span>{item?.points} PTS</span>
                                                </div>
                                            ) : null;
                                        })
                                    }
                                </div>
                            </div>
                            <div className="players_row">
                                <h5>BOWLERS ({countRoles?.bowl})</h5>
                                <div className="team_bar">
                                    {state?.team_details?.players?.length > 0 &&
                                        state?.team_details?.players?.map((item, index) => {
                                            return item.playing_role === "bowl" ? (
                                                <div className="player_ico" key={index}>
                                                    <span className={`${item?.caption == true || item?.vice_caption == true ? 'player_badge' : ''}`}>
                                                        {item?.caption == true ? 'C' : item?.vice_caption == true ? 'VC' : ''}
                                                    </span>
                                                    <img src="assets/images/player_1.png" width="55" height="67" />
                                                    <div className="player_name">
                                                        {item.short_name.split(' ')[0].charAt(0)} {item.short_name.split(' ')[item.short_name.split(' ').length - 1]}
                                                        <em className={`ms-1 pp_status me-0 ${item.playing11 === 'true' ? 'tm_green' : 'tm_red'}`}></em>
                                                    </div>
                                                    <span>{item?.points} PTS</span>
                                                </div>
                                            ) : null;
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="nav_footer nav_footer_transperant pv_footer">
                    <div className="container">
                        <div className="pv_sign" >
                            {/* <div className="pv_left" >
                                <span> <em className="tm_1" ></em> Mi </span>
                                <span> <em className="tm_2" ></em> DC </span>
                            </div> */}
                            <div className="pv_left" >
                                <span> <em className="tm_green" ></em> Announced </span>
                                <span> <em className="tm_red" ></em> Unannounced </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default LeaderBoardTeam

