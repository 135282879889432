
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RightViewContent from "../../customComponent/RightViewContent";
import AuthService from "../../AuthService";
import { alertErrorMessage } from "../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../customComponent/Loading/LoaderHelper";
import useWebSocket from "react-use-websocket";
import { ApiConfig } from "../../utils/apiConfig/apiConfig";

const CompleteLeaderBoard = (props) => {

    const navigate = useNavigate();
    const { state } = useLocation();
    const [winningDetails, setWinnignDetails] = useState([]);
    const [leaderBoardList, setLeaderBoardList] = useState([]);

    console.log(state,'state');
    const handleBack = () => {       
        navigate('/home');
    }

    useEffect(() => {
        handleWinningsDetails();
    }, []);

    const handleWinningsDetails = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getWinningDetails(state?.winingItem?.contest_category_id).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setWinnignDetails(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }

    const { lastJsonMessage } = useWebSocket(
        `wss://api.ibatfantasysports.com/leader-board?limit=10&skip=0&matchid=${state?.MatchId}&contest_category_id=${state?.winingItem?.contest_category_id}&user_id=${props?.userDetails?._id}`,
        {
            shouldReconnect: (closeEvent) => true,
        }
    );


    useEffect(() => {
        if (lastJsonMessage) {
            LoaderHelper.loaderStatus(false);
            setLeaderBoardList(lastJsonMessage);
        } else {
            LoaderHelper.loaderStatus(true);
        }
    }, [lastJsonMessage]);


    const handlePreviewTeam = (item) => {
        navigate('/leaderboard_team', { state: item });
    }


    return (
        <div className="main_view hight_full">
            <div className="left_view hight_full border-gradient">
                <div className="main_wrapper bg_img">
                    <div className="header_wrapper">
                        <div className="row align-items-center">
                            <div className="col-3 text-start">
                                <span className="hh_icon cursor-pointer" onClick={() => handleBack()}><i className="ri-arrow-left-s-line ri-xl  "></i> </span>
                            </div>
                            <div className="col-6  text-center">
                                <h4 className="text-success d-flex align-items-center justify-content-center"> <i className="ri-check-line ri-xl me-2"></i> {state?.Status}</h4>
                                <small>Max 7 player from a team</small>
                            </div>
                        </div>
                    </div>
                    <div className="main_panel match_details winning_leaderboard px-0">
                        <div className="play_card ">
                            <span className="row g-0 team_row">
                                <div className="col-6">
                                    <div className="team_card team_left team_left_DC">
                                        <div className="team_tcd "><span>{state?.TeamA}</span>
                                            <img src={state?.TeamAlogo} /> </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="team_card team_right team_right_GT">
                                        <div className="team_tcd "><span>{state?.TeamB}</span>
                                            <img src={state?.TeamBlogo} /></div>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <div className="detals_wrapper">
                            <div className="container">
                                <ul className="details_tab btn-gradient border-gradient nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item  " role="presentation">
                                        <button className="btn nav-link active" id="Winnings-tab" data-bs-toggle="tab" data-bs-target="#Winnings"
                                            type="button" role="tab" aria-controls="Winnings" aria-selected="true"><span>Winnings</span>
                                        </button>
                                    </li>
                                    <li className="nav-item  " role="presentation">
                                        <button className="btn nav-link" id="Leaderboard-tab" data-bs-toggle="tab" data-bs-target="#Leaderboard"
                                            type="button" role="tab" aria-controls="Leaderboard" aria-selected="false"> <span>Leaderboard</span>
                                        </button>
                                    </li>
                                    <li className="nav-item  " role="presentation">
                                        <button className="btn nav-link" id="Score-tab" data-bs-toggle="tab" data-bs-target="#Score"
                                            type="button" role="tab" aria-controls="Score" aria-selected="false"> <span>Score</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>

                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane show active" id="Winnings" role="tabpanel" aria-labelledby="Winnings-tab">
                                    <div className="team_info">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-6 text-start">
                                                    <span>RANK</span>
                                                </div>
                                                <div className="col-6 text-end">
                                                    <span>WINNINGS</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="rank_list rank_scroll">
                                        {winningDetails.length > 0 ?
                                            winningDetails.map((item, index) => (
                                                <li key={index}>
                                                    <h5 className="rank-item">
                                                        {item?.StartRank}-{item?.EndRank}
                                                    </h5>
                                                    <span>${item?.Price}</span>
                                                </li>
                                            )) : <div className="no_data"><span>No Data Found</span></div>}
                                    </ul>
                                </div>
                                <div className="tab-pane" id="Leaderboard" role="tabpanel" aria-labelledby="Leaderboard-tab">
                                    <div className="team_info">
                                        <div className="container">
                                            <div className="row gx-1">
                                                <div className="col-7 text-start">
                                                    <span>ALL TEAMS <span className="text-white"> ({leaderBoardList?.data?.length})</span></span>
                                                </div>
                                                <div className="col-3 text-center">
                                                    <span>POINTS</span>
                                                </div>
                                                <div className="col-2 text-end">
                                                    <span>RANK</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rank_list rank_scroll">
                                        {leaderBoardList?.data?.length > 0 ?
                                            leaderBoardList?.data?.map((item, index) => (
                                                <div className="player_info" key={index}>
                                                    <div className="container" >
                                                        <div className="row gx-1 align-items-center pb-1">
                                                            <div className="col-7">
                                                                {/* ALL TEAMS */}
                                                                <div className="ply_new_r" >
                                                                    <img src={`${ApiConfig.baseUrl + item?.logo}`} onError={(e) => { e.target.src = '/assets/images/user.png'; }} className="player_img w-100 rounded_img" />

                                                                    <div className="cursor-pointer" onClick={() => handlePreviewTeam(item)}>
                                                                        <span className="player_in_title"> <h6 className="mb-0" ><small>{item?.username} <small>({item?.team_details?.name})</small> </small></h6>
                                                                            <em className="text-success"> <small>{!item?.winningZone ? `You won Rs.${item?.winnings}` : item?.winningZone}</small> </em> </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-3 text-center">
                                                                {/* POINTS */}
                                                                <h5 className="mb-0"><small>{item?.team_details?.total_points}</small></h5>
                                                            </div>
                                                            <div className="col-2 text-end">
                                                                {/* RANK */}
                                                                <h5 className="mb-0"><small>{item?.rank}</small></h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : <div className="no_data"><span>No Data Found</span></div>}
                                    </div>
                                </div>
                                <div className="tab-pane" id="Score" role="tabpanel" aria-labelledby="Score-tab">
                                    <div className="container team_score " >
                                        <div class="accordion score_list_scroll " id="accordionExample1">

                                            {leaderBoardList?.score?.length > 0 ? (
                                                leaderBoardList.score.map((item, index) => (
                                                    item?.innings
                                                        ? item.innings.map((inningsitem, index) => (
                                                            <div class="accordion-item" key={index}>
                                                                <h2 class="accordion-header">
                                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${inningsitem?.name.replace(/\s/g, '_')}`} aria-expanded="true" aria-controls={inningsitem?.name.replace(/\s/g, '_')}>
                                                                        <div className="head_team_score" >
                                                                            {inningsitem?.name} <small>{inningsitem?.scores_full} </small>
                                                                        </div>
                                                                    </button>
                                                                </h2>
                                                                <div id={inningsitem?.name.replace(/\s/g, '_')} class="accordion-collapse collapse" data-bs-parent="#accordionExample1" >

                                                                    {/* Batsmen */}
                                                                    <div class="accordion-body p-0 score_body">
                                                                        <div className="score_head" >
                                                                            <div className="row gx-0" >
                                                                                <div className="col-6  text-start" >
                                                                                    <strong>Batsman</strong>
                                                                                </div>
                                                                                <div className="col-1 text-center" >
                                                                                    <strong> R </strong>
                                                                                </div>
                                                                                <div className="col-1 text-center" >
                                                                                    <strong> B </strong>
                                                                                </div>
                                                                                <div className="col-1 text-center" >
                                                                                    <strong> 4s </strong>
                                                                                </div>
                                                                                <div className="col-1 text-center" >
                                                                                    <strong> 6s </strong>
                                                                                </div>
                                                                                <div className="col-2 text-end " >
                                                                                    <strong> SR </strong>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="score_body" >
                                                                            {inningsitem?.batsmen
                                                                                ? inningsitem.batsmen.map((batsmanitem, index) => (
                                                                                    <div className="score_list" >
                                                                                        <div className="row gx-0" >
                                                                                            <div className="col-6  text-start" >
                                                                                                <span> {batsmanitem?.name} {batsmanitem?.batting == 'true' ? '*' : ''} <small className="text-muted" > {batsmanitem?.how_out} </small> </span>
                                                                                            </div>
                                                                                            <div className="col-1 text-center" >
                                                                                                <span> {batsmanitem?.runs} </span>
                                                                                            </div>
                                                                                            <div className="col-1 text-center" >
                                                                                                <span className="text-muted" > {batsmanitem?.balls_faced} </span>
                                                                                            </div>
                                                                                            <div className="col-1 text-center" >
                                                                                                <span className="text-muted" >  {batsmanitem?.fours}  </span>
                                                                                            </div>
                                                                                            <div className="col-1 text-center" >
                                                                                                <span className="text-muted" >  {batsmanitem?.sixes}  </span>
                                                                                            </div>
                                                                                            <div className="col-2 text-end " >
                                                                                                <span className="text-muted" >  {batsmanitem?.strike_rate}  </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                                : null
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    {/* Batsmen */}
                                                                    <div class="accordion-body p-0 score_body">
                                                                        <div className="score_head" >
                                                                            <div className="row gx-0" >
                                                                                <div className="col-6  text-start" >
                                                                                    <strong>Bowler</strong>
                                                                                </div>
                                                                                <div className="col-1 text-center" >
                                                                                    <strong> O </strong>
                                                                                </div>
                                                                                <div className="col-1 text-center" >
                                                                                    <strong> M </strong>
                                                                                </div>
                                                                                <div className="col-1 text-center" >
                                                                                    <strong> R </strong>
                                                                                </div>
                                                                                <div className="col-1 text-center" >
                                                                                    <strong> W </strong>
                                                                                </div>
                                                                                <div className="col-2 text-end " >
                                                                                    <strong> ER </strong>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="score_body" >
                                                                            {inningsitem?.bowlers
                                                                                ? inningsitem.bowlers.map((bowleritem, index) => (
                                                                                    <div className="score_list" >
                                                                                        <div className="row gx-0" >
                                                                                            <div className="col-6  text-start" >
                                                                                                <span> {bowleritem?.name}</span>
                                                                                            </div>
                                                                                            <div className="col-1 text-center" >
                                                                                                <span> {bowleritem?.overs} </span>
                                                                                            </div>
                                                                                            <div className="col-1 text-center" >
                                                                                                <span className="text-muted" > {bowleritem?.maidens} </span>
                                                                                            </div>
                                                                                            <div className="col-1 text-center" >
                                                                                                <span className="text-muted" >  {bowleritem?.runs_conceded}  </span>
                                                                                            </div>
                                                                                            <div className="col-1 text-center" >
                                                                                                <span className="text-muted" >  {bowleritem?.wickets}  </span>
                                                                                            </div>
                                                                                            <div className="col-2 text-end " >
                                                                                                <span className="text-muted" >  {bowleritem?.econ}  </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                                : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        ))
                                                        : null
                                                ))
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RightViewContent />
        </div>
    )
}

export default CompleteLeaderBoard

